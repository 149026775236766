import { connect, ConnectedProps } from 'react-redux';

import AcceptOffer from 'components/sections/auctionItem/operations/acceptOffer/acceptOffer';
import Arbitrate from 'components/sections/auctionItem/operations/requestArbitration/requestArbitration';
import Archive from 'components/sections/auctionItem/operations/archive/archive';
import Arrived from 'components/sections/auctionItem/operations/arrived/arrived';
import BaseClass from 'components/ui/shared/base';
import BuyNow, { BuyNowVariant } from 'components/sections/auctionItem/operations/buyNow/buyNow';
import Cancel from 'components/sections/auctionItem/operations/cancel/cancel';
import CaptureCompleted from 'components/sections/inventoryItem/operations/captureCompleted/captureCompleted';
import ChangeBuyer from 'components/sections/auctionItem/operations/changeBuyer/changeBuyer';
import ChangeConsigner from 'components/sections/auctionItem/operations/changeSeller/changeSeller';
import Checkout from 'components/sections/auctionItem/operations/checkout/checkout';
import CounterOffer from 'components/sections/auctionItem/operations/counterOffer/counterOffer';
import InactiveOperationButton from 'components/sections/inventoryItem/operations/inactiveOperationButton';
import MakeOffer, { MakeOfferVariant } from 'components/sections/auctionItem/operations/makeOffer/makeOffer';
import MissingItems from './missingItems/missingItems';
import ModifyPricing from 'components/sections/auctionItem/operations/modifyPricing/modifyPricing';
import OperationButton from './operationButton';
import PullFromAuction from 'components/sections/auctionItem/operations/pullFromAuction/pullFromAuction';
import RemoveAutobid from 'components/sections/auctionItem/operations/removeAutobid/removeAutobid';
import SellDirect from './sellDirect/sellDirect';
import SellInternally from 'components/sections/inventoryItem/operations/sellInternally/sellInternally';
import SellerIntent from 'components/sections/auctionItem/operations/sellerIntent/sellerIntent';
import SetAsIs from 'components/sections/auctionItem/operations/setAsIs/setAsIs';
import SetAutobid from 'components/sections/auctionItem/operations/setAutobid/setAutobid';
import Share from 'components/sections/auctionItem/operations/share/share';
import SubmitBid from 'components/sections/auctionItem/operations/submitBid/submitBid';
import SubmitToAuction from 'components/sections/auctionItem/operations/submitToAuction/submitToAuction';
import SubmitToAuctionCaptureNotCompleted from 'components/sections/inventoryItem/operations/captureCompleted/submitToAuctionCaptureNotCompleted';
import TransferRunningItem from 'components/sections/auctionItem/operations/transferRunningItem/transferRunningItem';
import TransferSale from 'components/sections/auctionItem/operations/transferSale/transferSale';
import { AppDispatch, AppState } from 'store/configureStore';
import {
  AuctionItemFormat,
  AuctionItemStatus,
  AuctionLaneControlType,
  AuctionTimeSlotStatus,
  ReleaseStatus,
  TransactionType,
  VehicleStatus,
} from 'store/shared/api/graph/interfaces/types';
import { FeatureFlag } from 'constants/featureFlags';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { STICKIED_TOP_CONTENT_ID } from 'components/ui/shared/tabs/anchorTabs';
import { UserAction } from 'logging/analytics/events/userActions';
import { getEnabledAuctionRelationships } from 'utils/companyUtils';
import {
  getEnabledCompanyIds,
  getEnabledCompanyRelationships,
  hasAuctionPermission,
  hasPermission,
  hasSystemPermission,
  isAuctionStaff,
  isGroupManagerRole,
} from 'utils/userUtils';
import { getField } from 'utils/objectUtils';
import { getGraphBaseURL, getRequestData } from 'utils/apiUtils';
import { getUrlParams } from 'utils/urlUtils';
import { hasFeatureFlag } from 'utils/featureFlagUtils';
import { isAuctionFormat } from 'utils/auctionItemUtils';
import { joinStrings } from 'utils/stringUtils';
import { processGetAuctionItemDetails } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { processGetInventoryItemDetails } from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import { t } from 'utils/intlUtils';
import {
  trackUserActionWithAuctionItemAttributes,
  trackUserActionWithInventoryItemAttributes,
} from 'utils/analyticsUtils';

import style from './operations.scss';

const stateConnect = (state: AppState) => {
  const auctionItem = state.app.auctionItemDetails.details?.toJS();
  const user = state.app.user;

  return {
    /** The auction item details. */
    auctionItem,
    /** The auction item details. */
    inventoryItem: state.app.inventoryItemDetails.details,
    /** TODO: True if logged-in user has multiple company auction relationships */
    isGroupManager: isGroupManagerRole(state.app.user),
    /** True if the logged-in user represents auction staff */
    isStaffUser: isAuctionStaff(state.app.user),
    /** Current logged-in user. */
    user,
  };
};

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Dispatch function to get auction item. */
  getAuctionItem: (options: { auctionItemId: string }) => processGetAuctionItemDetails(options, dispatch),
  /** Dispatch function to get inventory item. */
  getInventoryItemDetails: (options: { inventoryItemId: string | undefined | null }) =>
    processGetInventoryItemDetails(options, dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends RouterProps, ConnectedProps<typeof connector> {
  /** Function invoked to refresh the list. */
  onRefreshList?: () => void;
}

class Operations extends BaseClass<Props> {
  static defaultProps = {
    onRefreshList: () => {},
  };

  renderSellerIntents() {
    const { auctionItem, user } = this.props;
    const isMatchingFormat = auctionItem?.auctionTimeSlotLane?.controlType === AuctionLaneControlType.MANUAL;
    const isSeller = getEnabledCompanyIds(user)?.includes(auctionItem?.inventoryItem?.company?.id);

    if (auctionItem && isMatchingFormat && isSeller) {
      return (
        <SellerIntent auctionItemId={auctionItem.id} sellerIntentStatus={auctionItem?.sellerIntent?.status || null} />
      );
    }
    return null;
  }

  renderBuyNow() {
    const { auctionItem } = this.props;
    return <BuyNow auctionItem={auctionItem} variant={BuyNowVariant.DETAILS} />;
  }

  renderMakeOffer() {
    const { auctionItem } = this.props;
    return <MakeOffer auctionItem={auctionItem} variant={MakeOfferVariant.DETAILS} />;
  }

  renderSubmitBid() {
    const { auctionItem, isStaffUser } = this.props;

    if (auctionItem) {
      const { isMyItem, status, format } = auctionItem;
      const isAuthorized = isStaffUser || !isMyItem;
      const isMatchingStatus = [AuctionItemStatus.LIVE].includes(status);
      const isMatchingFormat = [
        AuctionItemFormat.APPRAISAL,
        AuctionItemFormat.GROUNDED,
        AuctionItemFormat.AUCTION,
        AuctionItemFormat.AUCTION_PHYSICAL,
      ].includes(format);

      if (isAuthorized && isMatchingStatus && isMatchingFormat) {
        return <SubmitBid details={auctionItem} />;
      }
    }
    return null;
  }

  renderAutobid() {
    const { auctionItem, isStaffUser } = this.props;
    const isMatchingFormat = auctionItem?.format !== AuctionItemFormat.TIMED_OFFER;

    if (auctionItem && isMatchingFormat) {
      const { isMyItem, autoBids, status } = auctionItem;
      const hasAutobid = autoBids?.myAutoBid;
      const isMatchingStatus = [AuctionItemStatus.UPCOMING, AuctionItemStatus.LIVE].includes(status);

      if (!hasAutobid && isMatchingStatus) {
        if (isStaffUser || !isMyItem) {
          return <SetAutobid auctionItem={auctionItem} />;
        }
      }
    }
    return null;
  }

  renderRemoveAutobid() {
    const { auctionItem } = this.props;

    if (auctionItem) {
      const { isMyItem, autoBids, status } = auctionItem;
      const hasAutobid = autoBids && autoBids.myAutoBid;
      const isMatchingStatus = [AuctionItemStatus.UPCOMING, AuctionItemStatus.LIVE].includes(status);

      if (hasAutobid && !isMyItem && isMatchingStatus) {
        return <RemoveAutobid auctionItem={auctionItem} />;
      }
    }
    return null;
  }

  renderPullFromAuction() {
    const { auctionItem, isStaffUser } = this.props;

    if (auctionItem) {
      const { id, isMyItem, status, format } = auctionItem;
      const timeSlotStatus = getField(auctionItem, 'auctionTimeSlot.status');
      const isAuthorized = isStaffUser || isMyItem;
      const isUpcoming =
        status === AuctionItemStatus.UPCOMING &&
        isAuctionFormat(format) &&
        timeSlotStatus === AuctionTimeSlotStatus.WAITING;
      const isBuyNow = status === AuctionItemStatus.LIVE && format === AuctionItemFormat.TIMED_OFFER;
      const isTimedFormat = [AuctionItemFormat.GROUNDED, AuctionItemFormat.APPRAISAL].includes(format);
      const isTimedAuction = isTimedFormat && [AuctionItemStatus.UPCOMING, AuctionItemStatus.LIVE].includes(status);

      if (isAuthorized && (isUpcoming || isBuyNow || isTimedAuction)) {
        return <PullFromAuction auctionItemId={id} trackUserAction={this.trackUserAction} />;
      }
    }
    return null;
  }

  renderSubmitToAuction() {
    const { inventoryItem, auctionItem, location, isStaffUser } = this.props;

    if (inventoryItem) {
      const hasIncompleteFields = !!inventoryItem.incompleteFieldMessages;
      const isCaptureConfirmed =
        !hasFeatureFlag(FeatureFlag.CAPTURE_CONFIRMATION) || inventoryItem.status === VehicleStatus.CAPTURED;

      if (!hasIncompleteFields && isCaptureConfirmed) {
        return (
          <SubmitToAuction
            buttonText={t('submit_to_auction')}
            inventoryItemDetails={inventoryItem}
            location={location}
          />
        );
      }
    } else if (auctionItem) {
      const { isMyItem, status } = auctionItem;
      const isAuthorized = isStaffUser || isMyItem;
      const isMatchingStatus = [AuctionItemStatus.NO_SALE, AuctionItemStatus.SALE_CANCELLED].includes(status);

      if (isAuthorized && isMatchingStatus) {
        return (
          <SubmitToAuction
            auctionItem={auctionItem}
            buttonText={t('run_again')}
            inventoryItemDetails={auctionItem.inventoryItem}
            location={location}
          />
        );
      }
    }
    return null;
  }

  renderChangeSeller() {
    const { inventoryItem, auctionItem, isGroupManager, isStaffUser, getInventoryItemDetails } = this.props;

    if (inventoryItem) {
      const isAuthorized = isStaffUser || isGroupManager;
      const inventoryItemId = inventoryItem.id;

      if (isAuthorized) {
        return (
          <ChangeConsigner
            inventoryItem={inventoryItem}
            onDone={(shouldUpdate) => shouldUpdate && getInventoryItemDetails({ inventoryItemId })}
          />
        );
      }
    } else if (auctionItem) {
      const { isMyItem, status } = auctionItem;
      const isAuthorized = isStaffUser || (isMyItem && isGroupManager);
      const isMatchingStatus = [AuctionItemStatus.NO_SALE].includes(status);

      if (isAuthorized && isMatchingStatus) {
        return <ChangeConsigner auctionItem={auctionItem} />;
      }
    }
    return null;
  }

  renderTransferRunningItem() {
    const { auctionItem, isStaffUser } = this.props;
    const isMatchingFormat = auctionItem?.format !== AuctionItemFormat.TIMED_OFFER;

    if (auctionItem && isMatchingFormat) {
      const { status } = auctionItem;
      const isAuthorized = isStaffUser;
      const isMatchingStatus = [
        AuctionItemStatus.IN_IF_BID,
        AuctionItemStatus.LIVE,
        AuctionItemStatus.PENDING_DELIVERY,
        AuctionItemStatus.UPCOMING,
      ].includes(status);

      if (isAuthorized && isMatchingStatus) {
        return <TransferRunningItem auctionItem={auctionItem} />;
      }
    }
    return null;
  }

  renderArchive() {
    const { inventoryItem, auctionItem, isStaffUser, onRefreshList } = this.props;

    if (inventoryItem) {
      return <Archive inventoryItem={inventoryItem} onRefreshList={onRefreshList} />;
    }
    if (auctionItem) {
      const { isMyItem, status } = auctionItem;
      const isAuthorized = isStaffUser || isMyItem;
      const isMatchingStatus = [AuctionItemStatus.NO_SALE, AuctionItemStatus.SALE_CANCELLED].includes(status);

      if (isAuthorized && isMatchingStatus) {
        return <Archive auctionItem={auctionItem} onRefreshList={onRefreshList} />;
      }
    }
    return null;
  }

  renderAcceptOrCounter() {
    const { auctionItem, getAuctionItem, isStaffUser } = this.props;

    if (auctionItem && auctionItem.ifBidTimeline) {
      const { ifBidTimeline, status } = auctionItem;
      const isMatchingStatus = [AuctionItemStatus.IN_IF_BID].includes(status);
      const isAuthorized = isStaffUser || ifBidTimeline.awaitingMyResponse;

      if (isMatchingStatus) {
        if (isAuthorized) {
          return (
            <>
              <AcceptOffer auctionItem={auctionItem} isAmountDisplayed />
              <CounterOffer
                auctionItem={auctionItem}
                onDone={(shouldUpdate) => {
                  if (shouldUpdate) {
                    getAuctionItem({ auctionItemId: getUrlParams()?.id });
                  }
                }}
              />
            </>
          );
        }
        if (!isStaffUser) {
          return (
            <InactiveOperationButton dataTestId="awaitingTheirResponseButton">
              {t('awaiting_their_response')}
            </InactiveOperationButton>
          );
        }
      }
    }
    return null;
  }

  renderCancel() {
    const { auctionItem, user } = this.props;

    if (auctionItem) {
      const { status } = auctionItem;
      const isMatchingStatus = [
        AuctionItemStatus.IN_IF_BID,
        AuctionItemStatus.PENDING_DELIVERY,
        AuctionItemStatus.SOLD,
        AuctionItemStatus.AWAITING_CHECKOUT,
      ].includes(status);
      const userHasPermission = hasAuctionPermission(user, auctionItem?.auction?.id, ['AUCTION_CANCEL_SALES']);

      if (isMatchingStatus && userHasPermission) {
        return <Cancel auctionItem={auctionItem} />;
      }
    }
    return null;
  }

  renderVehicleArrived() {
    const { auctionItem, isStaffUser } = this.props;

    if (auctionItem) {
      const { id, isMyItem, status } = auctionItem;
      const isAuthorized = isStaffUser || isMyItem;
      const isMatchingStatus = [AuctionItemStatus.PENDING_DELIVERY].includes(status);

      if (isMatchingStatus) {
        if (!isMyItem) {
          return (
            <InactiveOperationButton dataTestId="awaitingDelivery-button">
              {t('awaiting_delivery')}
            </InactiveOperationButton>
          );
        }
        if (isAuthorized) {
          return <Arrived auctionItemId={id} />;
        }
      }
    }
    return null;
  }

  renderViewBillOfSale() {
    const { auctionItem } = this.props;

    if (auctionItem && auctionItem.transactionDetails) {
      const {
        status,
        transactionType,
        transactionDetails: { buyBillOfSale, sellBillOfSale },
      } = auctionItem;
      const isSeller = [TransactionType.SELLING, TransactionType.ALL].includes(transactionType);
      const isMatchingStatus = [isSeller && AuctionItemStatus.AWAITING_CHECKOUT, AuctionItemStatus.SOLD].includes(
        status
      );
      const billOfSaleUrl = isSeller ? sellBillOfSale : buyBillOfSale;

      /**
       * Click handler for bill of sale button.
       */
      const onClick = () => {
        trackUserActionWithAuctionItemAttributes(UserAction.VDP_BILL_OF_SALE_CLICK, auctionItem);
        window.open(`${getGraphBaseURL()}${billOfSaleUrl}`);
      };

      if (isMatchingStatus) {
        return (
          <OperationButton dataTestId="bill-of-sale-button" onClick={onClick} theme="green-outline">
            {t('bill_of_sale')}
          </OperationButton>
        );
      }
    }
    return null;
  }

  renderViewDMVReport() {
    const { auctionItem } = this.props;

    if (auctionItem?.dmvReg398Url) {
      const { dmvReg398Url, status } = auctionItem;
      const isMatchingStatus = [AuctionItemStatus.SOLD].includes(status);

      if (isMatchingStatus) {
        return (
          <OperationButton
            dataTestId="reg-398-button"
            onClick={() =>
              getRequestData<string>({ url: dmvReg398Url })?.then((response) => {
                window.open(response);
              })
            }
            theme="green-outline"
          >
            {t('reg_398')}
          </OperationButton>
        );
      }
    }
    return null;
  }

  renderArbitrate() {
    const { auctionItem, user } = this.props;

    if (auctionItem) {
      const { auction, status, arbitrationRequest } = auctionItem;
      const isMatchingStatus = [AuctionItemStatus.SOLD].includes(status);
      const isArbitrationEnabled =
        !!auction?.auctionSettings?.dealerArbitrationEnabled || isAuctionStaff(user, auctionItem?.auction?.id);

      if (isArbitrationEnabled && isMatchingStatus && !arbitrationRequest) {
        return <Arbitrate auctionItem={auctionItem} />;
      }
    }
    return null;
  }

  renderTransferSale() {
    const { auctionItem, isStaffUser } = this.props;

    if (auctionItem) {
      const { status } = auctionItem;
      const isAuthorized = isStaffUser;
      const isMatchingStatus = [AuctionItemStatus.NO_SALE, AuctionItemStatus.SALE_CANCELLED].includes(status);

      if (isAuthorized && isMatchingStatus) {
        return <TransferSale auctionItem={auctionItem} />;
      }
    }
    return null;
  }

  renderModifyPricing() {
    const { auctionItem, isStaffUser } = this.props;

    if (auctionItem) {
      const { status, format, topOffer } = auctionItem;
      const timeSlotStatus = getField(auctionItem, 'auctionTimeSlot.status');
      const isAuthorized = isStaffUser;
      const isUpcoming =
        status === AuctionItemStatus.UPCOMING &&
        isAuctionFormat(format) &&
        timeSlotStatus === AuctionTimeSlotStatus.WAITING;
      const isTimedFormat =
        !topOffer &&
        [AuctionItemFormat.GROUNDED, AuctionItemFormat.APPRAISAL, AuctionItemFormat.TIMED_OFFER].includes(format);
      const isTimedStatus = isTimedFormat && [AuctionItemStatus.UPCOMING, AuctionItemStatus.LIVE].includes(status);

      if (isAuthorized && (isUpcoming || (isTimedFormat && isTimedStatus))) {
        return <ModifyPricing auctionItem={auctionItem} />;
      }
    }
    return null;
  }

  renderSetAsIs() {
    const { auctionItem, isStaffUser } = this.props;

    if (auctionItem) {
      const { status, format } = auctionItem;
      const timeSlotStatus = getField(auctionItem, 'auctionTimeSlot.status');
      const isAuthorized = isStaffUser;
      const isUpcoming =
        status === AuctionItemStatus.UPCOMING &&
        isAuctionFormat(format) &&
        timeSlotStatus === AuctionTimeSlotStatus.WAITING;
      const isTimedFormat = [AuctionItemFormat.GROUNDED, AuctionItemFormat.APPRAISAL].includes(format);
      const isTimedStatus = isTimedFormat && [AuctionItemStatus.UPCOMING, AuctionItemStatus.LIVE].includes(status);

      if (isAuthorized && (isUpcoming || (isTimedFormat && isTimedStatus))) {
        return <SetAsIs auctionItem={auctionItem} />;
      }
    }
    return null;
  }

  renderCaptureCompleted() {
    const { inventoryItem, user } = this.props;

    if (inventoryItem) {
      return <CaptureCompleted inventoryItem={inventoryItem} user={user} />;
    }
    return null;
  }

  renderSubmitToAuctionCaptureNotCompleted() {
    const { inventoryItem } = this.props;

    if (inventoryItem) {
      return <SubmitToAuctionCaptureNotCompleted inventoryItem={inventoryItem} />;
    }
    return null;
  }

  renderMissingItems() {
    const { inventoryItem } = this.props;

    if (inventoryItem) {
      const { incompleteFieldMessages } = inventoryItem;

      if (incompleteFieldMessages) {
        return <MissingItems count={incompleteFieldMessages.length} />;
      }
    }
    return null;
  }

  renderCheckout() {
    const { auctionItem, user } = this.props;

    if (auctionItem?.status === AuctionItemStatus.AWAITING_CHECKOUT) {
      const isAuthorized = hasAuctionPermission(user, auctionItem?.auction?.id, ['AUCTION_MANAGE_CHECKOUT']);

      if (!isAuthorized && getEnabledCompanyIds(user)?.includes(auctionItem?.inventoryItem?.company?.id)) {
        return <InactiveOperationButton>{t('awaiting_buyer_checkout')}</InactiveOperationButton>;
      }

      return <Checkout auctionItem={auctionItem} />;
    }
    return null;
  }

  renderChangeBuyer() {
    const { auctionItem, user, isStaffUser, isGroupManager } = this.props;

    if (hasFeatureFlag(FeatureFlag.CHANGE_BUYER) && auctionItem?.status === AuctionItemStatus.AWAITING_CHECKOUT) {
      const isUserWithTopOffer = user?.id === auctionItem?.buyer?.user?.id;
      const isAuthorized =
        (isStaffUser &&
          (hasAuctionPermission(user, auctionItem?.auction?.id, ['AUCTION_CHANGE_BUYER_AT_CHECKOUT']) ||
            hasSystemPermission(user, ['SYSTEM_CHANGE_BUYER_AT_CHECKOUT']))) ||
        (isGroupManager && isUserWithTopOffer);

      if (isAuthorized) {
        return <ChangeBuyer auctionItem={auctionItem} />;
      }
    }
    return null;
  }

  renderShare() {
    const { auctionItem, inventoryItem } = this.props;

    if (auctionItem || inventoryItem) {
      const auctionItemId = auctionItem?.id;
      const inventoryItemId = inventoryItem ? inventoryItem?.id : auctionItem?.inventoryItem?.id;
      const { year, make, model, trim } = inventoryItem || auctionItem.inventoryItem;
      const title = joinStrings([year, make, model, trim], ' ');

      return (
        <Share
          auctionItemId={auctionItemId}
          inventoryItemId={inventoryItemId}
          title={title}
          trackUserAction={this.trackUserAction}
        />
      );
    }
    return null;
  }

  renderSellDirect() {
    const { inventoryItem, user } = this.props;
    const isAuthorized =
      hasSystemPermission(user, ['SYSTEM_SELL_DIRECT']) || hasPermission(user, ['AUCTION_SELL_DIRECT']);

    if (isAuthorized && inventoryItem) {
      return <SellDirect inventoryItem={inventoryItem} />;
    }
    return null;
  }

  renderSellInternally() {
    const { inventoryItem, user } = this.props;

    /**
     * Display button when user has at least one company relationship with the seller, where
     * the company in that relationship has at least one auction relationship, where
     * the auction has internal sale enabled.
     */
    const companyRelationshipWithSeller = getEnabledCompanyRelationships(user)?.find(
      (relationship) => relationship?.company?.id === inventoryItem?.company?.id
    );
    const relationshipWithInternalSaleEnabled = getEnabledAuctionRelationships(
      companyRelationshipWithSeller?.company
    )?.filter((auctionRelationship) => auctionRelationship?.auction?.auctionSettings?.internalSaleEnabled);

    if (!!relationshipWithInternalSaleEnabled?.length && !!inventoryItem) {
      const auctions = relationshipWithInternalSaleEnabled.map((auctionRelationship) => auctionRelationship.auction);
      return <SellInternally auctions={auctions} inventoryItem={inventoryItem} />;
    }
    return null;
  }

  renderVehicleReleaseForm() {
    const { auctionItem } = this.props;
    if (auctionItem) {
      const { status, releaseStatus, vehicleReleaseForm } = auctionItem;
      if (status === AuctionItemStatus.SOLD && releaseStatus === ReleaseStatus.RELEASED && vehicleReleaseForm) {
        return (
          <OperationButton
            dataTestId="vehicle-release-form-button"
            onClick={() => {
              trackUserActionWithAuctionItemAttributes(UserAction.VEHICLE_RELEASE_FORM_CLICK, auctionItem);
              window.open(vehicleReleaseForm);
            }}
            theme="green"
          >
            {t('vehicle_release_form')}
          </OperationButton>
        );
      }
    }
    return null;
  }

  trackUserAction = (action: UserAction) => {
    const { auctionItem, inventoryItem } = this.props;
    if (auctionItem) {
      trackUserActionWithAuctionItemAttributes(action, auctionItem);
    } else {
      trackUserActionWithInventoryItemAttributes(action, inventoryItem);
    }
  };

  render() {
    return (
      <div className={style.container} id={STICKIED_TOP_CONTENT_ID}>
        <div className={style.containerInner}>
          {this.renderSellerIntents()}
          {this.renderBuyNow()}
          {this.renderMakeOffer()}
          {this.renderSubmitBid()}
          {this.renderAutobid()}
          {this.renderRemoveAutobid()}
          {this.renderPullFromAuction()}
          {this.renderCheckout()}
          {this.renderChangeBuyer()}
          {this.renderShare()}
          {this.renderSubmitToAuction()}
          {this.renderSubmitToAuctionCaptureNotCompleted()}
          {this.renderMissingItems()}
          {this.renderCaptureCompleted()}
          {this.renderAcceptOrCounter()}
          {this.renderVehicleArrived()}
          {this.renderVehicleReleaseForm()}
          {this.renderViewBillOfSale()}
          {this.renderViewDMVReport()}
          {this.renderArbitrate()}
          {this.renderCancel()}
          {this.renderTransferSale()}
          {this.renderModifyPricing()}
          {this.renderSetAsIs()}
          {this.renderTransferRunningItem()}
          {this.renderChangeSeller()}
          {this.renderArchive()}
          {this.renderSellDirect()}
          {this.renderSellInternally()}
        </div>
      </div>
    );
  }
}

export default withRouter(connector(Operations));
