import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import User from 'constants/user';
import { CurrentBiddingAsSelections, currentBiddingAsSelections } from 'store/user/userActions';
import { FeatureFlag, OperatingRegion, UserStatus, ValueDTO } from 'store/shared/api/graph/interfaces/types';

interface UserModel extends User {
  /* User current biddings */
  currentBiddingAsSelections: CurrentBiddingAsSelections;

  /** All available feature flags */
  featureFlags: FeatureFlag[];

  /* User locations */
  locations: string[];

  /* Users pipeline Id */
  pipelineId: string | null;

  /* Users supported locales*/
  supportedLocales: ValueDTO[] | null;

  /** Operating regions for the user. */
  operatingRegions: OperatingRegion[];
}

export type UserProps = ServerRecordOf<UserModel>;
export type UserPropsJs = ReturnType<UserProps['toJS']>;

export const UserProfile = ServerRecord<UserModel>({
  // A collection of the user's bidding selections at an auction level
  currentBiddingAsSelections: JSON.parse(currentBiddingAsSelections.get() || 'null'),

  auctionAccessId: null,
  auctionAccessLastVerified: null,
  cellPhone: null,
  channels: [],
  companyRelationships: null,
  created: null,
  distanceUnit: null,
  email: '',
  employee: false,
  featureFlags: [],
  firstName: '',
  hasCarfaxUSASession: false,
  id: '',
  internal: false,
  lastLoggedIn: null,
  lastName: '',
  locale: null,
  locations: [],
  mainPhotoMain: null,
  mainPhotoThumb: null,
  omvicRegExpiryDate: null,
  omvicRegNumber: null,
  operatingRegions: [],
  photoUrl: null,
  pipelineId: null,
  preferredLocation: null,
  secondaryPhone: null,
  sessionActingAsOtherUser: false,
  sessionId: null,
  source: null,
  sourceEnum: null,
  status: UserStatus.PENDING,
  supportedLocales: null,
  timeZone: null,
  title: null,
  updated: null,
});
