import { useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import ascendingGlyph from 'glyphs/ascending.svg';
import descendingGlyph from 'glyphs/descending.svg';

import AuctionItem from 'constants/auctionItem';
import Button from 'components/ui/shared/button';
import ListItem from 'components/sections/inventoryItem/details/comparables/listItem';
import Loading from 'components/ui/loading/loading';
import NoResults from 'components/ui/noResults/noResults';
import SlideOut from 'components/ui/slideOuts/slideOut';
import Sprite from 'components/ui/shared/sprite';
import { AppState } from 'store/configureStore';
import { SlideOutComponentProps } from 'components/sections/inventoryItem/details/slideOut/slideOut';
import { t } from 'utils/intlUtils';

import style from './comparablesSlideOut.scss';

const stateConnect = (state: AppState) => ({
  /** Current logged in user. */
  user: state.app.user,
});

const connector = connect(stateConnect);

interface Props extends SlideOutComponentProps, ConnectedProps<typeof connector> {
  /** The list of comparable auction items to be shown. */
  auctionItems?: AuctionItem[];

  /** Callback function to fetch the comparables. */
  fetchComparables?: (soldDateDesc: boolean) => void;

  /** Whether click outside is disabled. */
  isClickOutsideDisabled?: boolean;

  /** Whether the list is loading or not. */
  isLoading?: boolean;

  /** Whether the slide out is open or not. */
  isOpen?: boolean;

  /** Callback function triggered when slide out closes. */
  onClose?: () => void;

  /** Callback function to handle on list item click. */
  onListItemClick?: (auctionItemId: string) => void;
}

const ComparablesSlideOut = ({
  auctionItems = [],
  fetchComparables,
  isClickOutsideDisabled,
  isLoading,
  isOpen,
  onClose,
  onListItemClick,
  user,
}: Props) => {
  const [isDescending, setIsDescending] = useState<boolean>(true);

  const headerActions = useMemo(
    () => (
      <Button
        dataTestId="sortComparablesButton"
        onClick={() => {
          fetchComparables?.(!isDescending);
          setIsDescending(!isDescending);
        }}
        theme="none"
      >
        <Sprite className={style.sortGlyph} glyph={isDescending ? descendingGlyph : ascendingGlyph} />
      </Button>
    ),
    [fetchComparables, isDescending]
  );

  const itemList = useMemo(() => {
    if (!auctionItems?.length) {
      return <NoResults />;
    }

    // Only show first 10 auction items in the list.
    return (
      <ul>
        {auctionItems.slice(0, 10).map((auctionItem) => (
          <li key={auctionItem?.id}>
            <ListItem auctionItem={auctionItem} onClick={onListItemClick} user={user} />
          </li>
        ))}
      </ul>
    );
  }, [auctionItems, onListItemClick, user]);

  return (
    <SlideOut
      contentClassName={style.content}
      headerActions={headerActions}
      isClickOutsideDisabled={isClickOutsideDisabled}
      isOpen={isOpen}
      onClose={onClose}
      title={t('comparables')}
    >
      <div className={style.itemsList}>{itemList}</div>
      <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
    </SlideOut>
  );
};

export default connector(ComparablesSlideOut);
