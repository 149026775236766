import { handleActions } from 'redux-actions';

import {
  auctionItemLoaded,
  auctionSubmissionListLoaded,
  clearAuctionSubmission,
  isLoading,
} from './auctionSubmissionActions';
import { AuctionSubmissionInitialState } from './auctionSubmissionModels';

export const auctionSubmissionReducer = handleActions(
  {
    [isLoading().type]: (state) => state.setLoading(),

    [clearAuctionSubmission().type]: (state) => new AuctionSubmissionInitialState(),

    [auctionSubmissionListLoaded().type]: (state, action) => {
      return state.setLoaded().merge({
        auctionItem: null,
        list: action?.payload,
      });
    },

    [auctionItemLoaded().type]: (state, action) => {
      return state.setLoaded().merge({
        auctionItem: action?.payload,
      });
    },
  },
  new AuctionSubmissionInitialState()
);
