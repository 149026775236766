import { ReactNode } from 'react';
import classnames from 'classnames';

import addGlyph from 'glyphs/plus-skinny.svg';
import closeGlyph from 'glyphs/expand.svg';

import Badge from 'components/ui/badges/badge';
import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import OutsideClick from 'components/ui/shared/directives/outsideClick';
import Sprite from 'components/ui/shared/sprite';
import raf from 'utils/raf';

import style from './slideOut.scss';

interface Props {
  /** The rendered child elements. */
  children?: ReactNode;

  /** CSS styling to overwrite default container style. */
  className?: string;

  /** CSS styling to overwrite default content style. */
  contentClassName?: string;

  /** CSS styling to overwrite default inner content style. */
  contentInnerClassName?: string;

  /** Action Buttons rendered in the header. */
  headerActions?: ReactNode;

  /** CSS styling to overwrite default header style. */
  headerClassName?: string;

  /** Ignored elements for outside click. */
  ignoredElements?: HTMLElement[];

  /** True when outside click component is disabled. */
  isClickOutsideDisabled?: boolean;

  /** True when slide out should appear as a dialog. */
  isDialogMode?: boolean;

  /** True when slide out is open. */
  isOpen?: boolean;

  /** Function invoked when add glyph has been clicked. */
  onAdd?: () => void;

  /** Function invoked when close button has been clicked. */
  onClose?: () => void;

  /** True when header should be displayed. */
  showHeader?: boolean;

  /** The title of the slide out. */
  title?: ReactNode;
}

interface State {
  /** True when slide out is open. */
  isOpen: boolean;

  /** True when slide out is visible. */
  isVisible: boolean;

  /** True when slide out is transitioning from open to closed or closed to open. */
  isTransitioning: boolean;
}

class SlideOut extends BaseClass<Props, State> {
  static defaultProps = {
    ignoredElements: [],
    onClose: () => {},
    showHeader: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: props?.isOpen,
      isVisible: props?.isOpen,
      isTransitioning: false,
    };
  }

  componentDidUpdate() {
    const { isOpen } = this.props;
    const { isVisible, isTransitioning } = this.state;

    if (isOpen && !isVisible && !isTransitioning) {
      this.setState({ isTransitioning: true });
      requestAnimationFrame(() => {
        this.setState({ isOpen: true });
        requestAnimationFrame(() => {
          this.setState({ isVisible: true, isTransitioning: false });
        });
      });
    } else if (!isOpen && isVisible) {
      this.setState({ isVisible: false, isOpen: false });
    }
  }

  onClose = () => {
    const { isOpen } = this.props;
    const { isVisible, isTransitioning } = this.state;

    if (isOpen && isVisible && !isTransitioning) {
      this.setState({ isTransitioning: true });
      raf(() => {
        this.setState({ isVisible: false });
        setTimeout(() => {
          this.setState({ isOpen: false, isTransitioning: false });
          this.props?.onClose?.();
        }, 300);
      });
    }
  };

  render() {
    const {
      children,
      className,
      contentClassName,
      contentInnerClassName,
      headerActions,
      headerClassName,
      ignoredElements,
      isClickOutsideDisabled,
      isDialogMode,
      onAdd,
      showHeader,
      title,
    } = this.props;
    const { isOpen, isVisible } = this.state;

    if (!isOpen) {
      return null;
    }

    return (
      <OutsideClick
        disabled={isClickOutsideDisabled}
        ignoredElements={ignoredElements}
        onClick={this.onClose}
        stopPropagation
      >
        <div
          className={classnames(style.container, className, {
            [style.isVisible]: isVisible,
            [style.isDialogMode]: isDialogMode,
          })}
          data-testid="slide-out"
        >
          <div className={classnames(style.content, contentClassName)}>
            {showHeader && (
              <div className={classnames(style.header, headerClassName)}>
                <div className={style.titleContainer} data-testid="slide-title">
                  <h3>{title}</h3>
                  {onAdd && (
                    <Badge
                      className={style.badge}
                      content={addGlyph}
                      dataTestId={`add-${title}-button`}
                      onClick={onAdd}
                      theme="square"
                    />
                  )}
                  {headerActions && <div className={style.headerActions}>{headerActions}</div>}
                </div>
                <Button
                  className={style.closeButton}
                  dataTestId="slideOut-close-button"
                  onClick={this.onClose}
                  theme="none"
                >
                  <Sprite className={style.sprite} glyph={closeGlyph} />
                </Button>
              </div>
            )}
            <div className={classnames(style.contentInner, contentInnerClassName)} data-testid="slide-out-inner">
              {children}
            </div>
          </div>
        </div>
      </OutsideClick>
    );
  }
}

export default SlideOut;
