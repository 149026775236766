import classnames from 'classnames';
import { useMemo } from 'react';

import AuctionItem from 'constants/auctionItem';
import Chip from 'components/ui/chip/chip';
import VehicleImage from 'components/ui/shared/images/vehicleImage';
import {
  AssuredBadge,
  Badges,
  BuyerShieldBadge,
  VerifiedBadge,
  WarningBadge,
} from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { MyBlockVariant } from 'components/sections/myBlock/myBlock';
import { hasCarfaxClaimOrDeclarations } from 'utils/inventoryItemUtils';
import { isVerified } from 'utils/auctionItemUtils';
import { joinStrings } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './vehicleDetails.scss';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** The variation of my block view. */
  variant: MyBlockVariant;
}

const VehicleDetails = ({ auctionItem, variant }: Props) => {
  const { asIs, inventoryItem, isAssured, status } = auctionItem;
  const imageUrl = inventoryItem.photos?.[0]?.thumb;
  const titleYearMake = joinStrings([inventoryItem.year, inventoryItem.make], ' ');
  const titleModelTrim = joinStrings([inventoryItem.model, inventoryItem.trim], ' ');

  const badges = {
    hasBuyerShield: !!inventoryItem?.vehicleBuyerShield,
    hasWarnings: hasCarfaxClaimOrDeclarations(inventoryItem.conditionReport) || !!asIs,
    isAssured,
    isVerified: isVerified(inventoryItem?.captureType),
  };

  /**
   * Displayed auction item status label.
   */
  const statusLabel = useMemo(() => {
    switch (status) {
      case AuctionItemStatus.LIVE:
        return t('timed_auction');
      case AuctionItemStatus.SOLD:
        return variant === MyBlockVariant.BUYER ? t('purchased') : t('auction_item_status_sold');
      default:
        return t(`auction_item_status_${status?.toLowerCase()}`);
    }
  }, [status, variant]);

  return (
    <div className={style.vehicleDetails}>
      <div className={style.imageContainer}>
        <VehicleImage containerClassName={style.image} imageUrl={imageUrl} />
      </div>
      <div>
        <div className={style.top}>
          <div className={style.title}>
            <div className={style.yearMake}>{titleYearMake}</div>
            <div className={style.modelTrim}>{titleModelTrim}</div>
          </div>
          <div>
            {!!inventoryItem.mileage && <div className={style.mileage}>{inventoryItem.mileage.formattedAmount}</div>}
            <Badges className={style.badges}>
              {badges.isVerified && <VerifiedBadge />}
              {badges.isAssured && <AssuredBadge />}
              {badges.hasBuyerShield && <BuyerShieldBadge />}
              {badges.hasWarnings && <WarningBadge />}
            </Badges>
          </div>
        </div>
        <div className={style.bottom}>
          {inventoryItem.vin && <Chip className={style.vin} label={inventoryItem.vin} />}
          <Chip
            className={classnames(style.statusLabel, style[status])}
            data-testid="auction-item-status"
            label={statusLabel}
          />
        </div>
      </div>
    </div>
  );
};

export default VehicleDetails;
