import classnames from 'classnames';
import { HTMLAttributes } from 'react';

import LeftEdge from 'glyphs/badges/badgeText/leftEdge.svg';
import RightEdge from 'glyphs/badges/badgeText/rightEdge.svg';

import Sprite from 'components/ui/shared/sprite';

import style from './badgeText.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** CSS styling to overwrite the default style. */
  className?: string;
  /** The badge theme color. */
  color: 'blue' | 'gray' | 'green' | 'red' | 'yellow';
  /** The text to display alongside the badge. */
  text: string;
  /** CSS styling to overwrite the default text label style. */
  textClassName?: string;
}

const BadgeText = ({ className, color, text, textClassName, ...props }: Props) => (
  <div className={classnames(style.container, className)} {...props}>
    <Sprite className={classnames(style.p1, style[color])} glyph={LeftEdge} />
    <div className={classnames(style.p2, style[color])}>
      <div className={classnames(style.text, textClassName)}>{text}</div>
    </div>
    <Sprite className={classnames(style.p3, style[color])} glyph={RightEdge} />
  </div>
);

export default BadgeText;
