import { MouseEventHandler, useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Dialog from 'components/sections/auctionItem/operations/makeOffer/dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppState } from 'store/configureStore';
import { AuctionItemDetailsPropsJs } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { AuctionItemFormat, AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { FeatureFlag } from 'constants/featureFlags';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { UserAction } from 'logging/analytics/events/userActions';
import { hasAuctionFeatureFlag } from 'utils/featureFlagUtils';
import { isAuctionStaff, isGroupManagerRole, representsCompany } from 'utils/userUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './makeOffer.scss';

export enum MakeOfferVariant {
  ITEM_LIST = 'ItemList',
  DETAILS = 'Details',
}

/**
 * Returns user tracking action for different variants
 */
export const getUserTrackingAction = (variant: MakeOfferVariant) => {
  switch (variant) {
    case MakeOfferVariant.DETAILS:
      return {
        cancel: UserAction.VDP_MAKE_OFFER_CANCEL_CLICK,
        click: UserAction.VDP_MAKE_OFFER_CLICK,
        confirm: UserAction.VDP_MAKE_OFFER_CONFIRM_CLICK,
      };

    case MakeOfferVariant.ITEM_LIST:
    default:
      return {
        cancel: UserAction.BUY_NOW_LIST_MAKE_OFFER_CANCEL_CLICK,
        click: UserAction.BUY_NOW_LIST_MAKE_OFFER_CLICK,
        confirm: UserAction.BUY_NOW_LIST_MAKE_OFFER_CONFIRM_CLICK,
      };
  }
};

export interface LocationQuery {
  showOffers: string;
}

interface Props extends RouterProps<LocationQuery> {
  /** The auction item details. */
  auctionItem: AuctionItemDetailsPropsJs | undefined;
  /** The variant options for the Make Offer button */
  variant: MakeOfferVariant;
}

const MakeOffer = ({ auctionItem, router, location, variant }: Props) => {
  const user = useSelector((state: AppState) => state.app.user);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const userTrackingAction = useMemo(() => getUserTrackingAction(variant), [variant]);
  const isGroupManager = useMemo(() => isGroupManagerRole(user), [user]);
  const isStaffUser = useMemo(() => isAuctionStaff(user, auctionItem?.auction.id), [auctionItem?.auction.id, user]);
  const isAuthorized = isGroupManager || isStaffUser || !auctionItem?.isMyItem;
  const isSeller = representsCompany(user, auctionItem?.inventoryItem.company?.id);

  /**
   * Handles buy now action - Opens Dialog for confirmation
   */
  const handleOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e?.preventDefault();

      setIsOpen(true);
      trackUserActionWithAuctionItemAttributes(userTrackingAction.click, auctionItem);
    },
    [auctionItem, userTrackingAction.click]
  );

  /**
   * Handles cancel action - Closes Dialog
   */
  const handleOnClose = useCallback(() => {
    setIsOpen(false);
    trackUserActionWithAuctionItemAttributes(userTrackingAction.cancel, auctionItem);
  }, [auctionItem, userTrackingAction.cancel]);

  /**
   * Handles showing of offer list on click event
   */
  const handleShowOfferListOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      router.push({
        pathname: location.pathname,
        query: {
          ...location.query,
          id: auctionItem?.id,
          showOffers: 'true',
        },
      });
    },
    [auctionItem?.id, location.pathname, location.query, router]
  );

  if (auctionItem?.timedOfferTimeline?.waitingOnThem) {
    return (
      <OperationButton className={style.button} onClick={handleShowOfferListOnClick} theme="gray-outline">
        {t('awaiting_response')}
      </OperationButton>
    );
  }

  if (auctionItem?.timedOfferTimeline?.waitingOnYou) {
    return (
      <OperationButton className={style.button} onClick={handleShowOfferListOnClick} theme="red">
        {t('offer_pending')}
      </OperationButton>
    );
  }

  if (
    !hasAuctionFeatureFlag(FeatureFlag.TIMED_OFFER) ||
    isSeller ||
    !isAuthorized ||
    !auctionItem ||
    auctionItem.format !== AuctionItemFormat.TIMED_OFFER ||
    auctionItem.status !== AuctionItemStatus.LIVE ||
    auctionItem._ended
  ) {
    return null;
  }

  return (
    <>
      <OperationButton className={style.button} onClick={handleOnClick} theme="green">
        {t('make_offer')}
      </OperationButton>
      {isOpen && <Dialog auctionItem={auctionItem} onClose={handleOnClose} variant={variant} />}
    </>
  );
};

export default withRouter(MakeOffer);
