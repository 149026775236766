import moment from 'moment';
import { createAction } from 'redux-actions';

import { getNow } from './systemApi';
import { Latency } from 'utils/latencyUtils';

const MAX_LATENCY = 2000;
let latency: Latency | null = new Latency();

export const networkDown = createAction('SYSTEM_NETWORK_DOWN');
export const networkUp = createAction('SYSTEM_NETWORK_UP');
export const setInteractionTimeout = createAction('SYSTEM_SET_INTERACTION_TIMEOUT');
export const setIsError429Active = createAction('SYSTEM_SET_IS_ERROR_429_ACTIVE');
export const setIsPubSubActive = createAction('SYSTEM_SET_IS_PUB_SUB_ACTIVE');
export const setTimeOffset = createAction('SYSTEM_SET_TIME_OFFSET');

export const resetTimeOffset = () => {
  latency = null;
  latency = new Latency();
};

export const updateTimeOffset = (dispatch) => {
  getNow().then((response: any) => {
    const reportedLatency = moment().valueOf() - moment(response.data.now).valueOf();
    let avgLatency = latency?.report(reportedLatency);

    if (avgLatency && avgLatency > MAX_LATENCY) {
      //  Log latency error with Sentry
      // const error = new Error(`Exceeded max latency of ${MAX_LATENCY}ms`);
      // SentryLogger.captureException(error, { latency, reportedLatency });

      // Reset offset/latency
      resetTimeOffset();
      avgLatency = 0;
    }

    dispatch(setTimeOffset(avgLatency));
  });
};
