import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AuctionItem from 'constants/auctionItem';
import BidOverlay from 'components/sections/auctionItem/operations/submitBid/overlay';
import ListItemOverlay from 'components/ui/lists/listItem/listItemOverlay';
import { AppState } from 'store/configureStore';
import { BuyNowVariant, getUserTrackingAction } from 'components/sections/auctionItem/operations/buyNow/buyNow';
import { Route } from 'store/routing/routes';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { buyNow } from 'store/auctionItemDetails/auctionItemDetailsApi';
import { getBiddingSelectionByAuctionId, isAuctionStaff, isGroupManagerRole } from 'utils/userUtils';
import { getErrors } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';
import { useGlobalDialog } from 'contexts/globalDialogContext';

const stateConnect = (state: AppState) => ({
  user: state.app.user,
});

const connector = connect(stateConnect);

interface Props extends ConnectedProps<typeof connector>, RouterProps {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** CSS styling to override the default style. */
  className?: string;
  /** Function invoked when on cancel. */
  onCancel: MouseEventHandler<HTMLButtonElement>;
  /** Different variants of the Buy Now overlays */
  variant: BuyNowVariant;
}

const Overlay = ({ auctionItem, className, location: { pathname }, onCancel, user, variant }: Props) => {
  const { setConfig } = useGlobalDialog();
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);
  const userTrackingAction = useMemo(() => getUserTrackingAction(variant, pathname as Route), [variant, pathname]);
  const isGroupManager = isGroupManagerRole(user);
  const isStaffUser = isAuctionStaff(user, auctionItem?.auction?.id);

  /**
   * Handles buy now confirmation button
   */
  const handleOnSubmit = useCallback(
    (e?) => {
      e?.preventDefault?.();

      setShouldAnimate(true);
      trackUserActionWithAuctionItemAttributes(userTrackingAction.confirm, auctionItem);

      const biddingAs = getBiddingSelectionByAuctionId(auctionItem?.auction?.id);
      const options = {
        auctionItemId: auctionItem.id,
        companyId: biddingAs?.company?.id,
        userId: biddingAs?.user?.id,
      };

      buyNow(options)
        ?.catch((error) => setConfig({ errorsOverride: getErrors(error) }))
        ?.finally(() => onCancel(e));
    },
    [auctionItem, onCancel, setConfig, userTrackingAction]
  );

  if (variant === BuyNowVariant.DETAILS) {
    return (
      <BidOverlay
        auctionItem={auctionItem}
        bidAmount={auctionItem?.buyNowPrice?.amount}
        confirmButtonText={t('confirm_purchase')}
        isBidding={shouldAnimate}
        isCustomBiddingDisabled
        isGroupManager={isGroupManager}
        isStaffUser={isStaffUser}
        onCancel={onCancel}
        onConfirm={handleOnSubmit}
      />
    );
  }

  return (
    <ListItemOverlay
      auctionItem={auctionItem}
      bidAmount={auctionItem?.listPrice?.amount}
      inventoryItem={auctionItem?.inventoryItem}
      isAnimating={shouldAnimate}
      isCustomBiddingDisabled
      onCancel={onCancel}
      onConfirm={handleOnSubmit}
      onConfirmText={t('confirm_purchase')}
      user={user}
    />
  );
};

export default withRouter(connector(Overlay));
