export const topOffer = `
  topOffer {
    id
    amount {
      amount
      currencyCode
      formattedAmount
      formattedAmountRounded
    }
    bidType
    company {
      id
      name
    }
    created
    createdBy {
      firstName
      lastName
    }
  }
`;

export const transactionDetails = `
  transactionDetails {
    transactionNumber
    purchasePrice {
      formattedAmount
    }
    purchaseTaxes {
      formattedAmount
    }
    purchaseTaxRate
    purchaseTotal {
      formattedAmount
    }
    buyBillOfSale
    buyFee {
      formattedAmount
    }
    buyFeeCredit {
      formattedAmount
    }
    buyCarfaxCanadaFee {
      currencyCode
      amount
      formattedAmount
    }
    buyProcessingFee {
      formattedAmount
    }
    buyTransportCompoundFee {
      formattedAmount
    }
    buyPSIFee {
      formattedAmount
    }
    buyTaxRate
    buySubTaxes {
      formattedAmount
    }
    buySubTotal {
      formattedAmount
    }
    buyTaxes {
      formattedAmount
    }
    buyTotal {
      formattedAmount
    }
    sellBillOfSale
    sellFee {
      formattedAmount
    }
    sellFeeCredit {
      formattedAmount
    }
    sellProcessingFee {
      formattedAmount
    }
    sellTransportCompoundFee {
      formattedAmount
    }
    sellPSIFee {
      formattedAmount
    }
    sellTaxRate
    sellSubTaxes {
      formattedAmount
    }
    sellSubTotal {
      formattedAmount
    }
    sellTaxes {
      formattedAmount
    }
    sellTotal {
      formattedAmount
    }
    buyerCompany {
      id
      name
    }
    created
    sellTransportFee {
      amount
      formattedAmount
    }
    sellTransportFeeText
    sellMechanicalFee {
      amount
      formattedAmount
    }
    sellMechanicalFeeText
    sellReconditioningFee {
      amount
      formattedAmount
    }
    sellReconditioningFeeText
    sellStorageFee {
      amount
      formattedAmount
    }
    sellStorageFeeText
    sellRedemptionFee {
      amount
      formattedAmount
    }
    sellRedemptionFeeText
    sellMarshallingFee {
      amount
      formattedAmount
    }
    sellMarshallingFeeText
    sellMiscFee {
      amount
      formattedAmount
    }
    sellMiscFeeText
    sellRegistrationFee {
      amount
      formattedAmount
    }
    sellRegistrationFeeText
    sellFuelFee {
      amount
      formattedAmount
    }
    sellFuelFeeText
  }
`;
