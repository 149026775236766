import InventoryItem from 'constants/inventoryItem';
import OperationButton from '../operationButton';
import { FeatureFlag } from 'constants/featureFlags';
import { VehicleCaptureType, VehicleStatus } from 'store/shared/api/graph/interfaces/types';
import { hasFeatureFlag } from 'utils/featureFlagUtils';
import { t } from 'utils/intlUtils';

import style from './submitToAuctionCaptureNotCompleted.scss';

interface Props {
  /** The Vehicle being captured. */
  inventoryItem?: InventoryItem;
}

const SubmitToAuctionCaptureNotCompleted = ({ inventoryItem }: Props) => {
  if (
    !hasFeatureFlag(FeatureFlag.CAPTURE_CONFIRMATION) ||
    inventoryItem?.status === VehicleStatus.CAPTURED ||
    inventoryItem?.completionPercentage !== 1 ||
    inventoryItem?.captureType === VehicleCaptureType.SELF_CAPTURE
  ) {
    return null;
  }

  return (
    <OperationButton
      key="button"
      className={style.buttonLabel}
      dataTestId="submit-to-auction-capture-not-completed-button"
      disabled
      theme="gray-outline"
    >
      {t('submit_to_auction')}
      <br />
      <small>{t('capture_incomplete')}</small>
    </OperationButton>
  );
};

export default SubmitToAuctionCaptureNotCompleted;
