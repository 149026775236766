import Button from 'components/ui/shared/button';
import MakeModelFilterList from 'components/sections/notifications/tabs/vehicleListings/makeModelFilterList';
import { ClearableFieldInput } from 'constants/enums/forms';
import { FormDialogFooter } from 'layouts/formLayouts/formDialogLayouts';
import { Notification, NotificationCategory } from 'store/shared/api/graph/interfaces/types';
import { Notifications } from 'components/sections/notifications/userNotificationsSlideOut';
import { UserAction } from 'logging/analytics/events/userActions';
import { camelCaseToSnakeCase } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithUserAttributes } from 'utils/analyticsUtils';

import style from 'components/sections/notifications/userNotificationsSlideOut.scss';

interface Props {
  getNotificationOptions: (notification: Notification) => string[];
  mileageUnit: string;
  notifications: Notifications | null;
  onAddVehicleListing: () => void;
  onDistanceChange: (value: number | null) => () => void;
  onToggleNotification: (notification: Notification) => void;
  onToggleVehicleListing: (fieldName: string) => () => void;
  saveNotifications: (notifications: Notifications, shouldUpdateFromResponse: boolean) => Promise<void>;
  setIsClickOutsideDisabled: (isDisabled: boolean) => void;
}

const VehicleListingsTab = ({
  getNotificationOptions,
  mileageUnit,
  notifications,
  onAddVehicleListing,
  onDistanceChange,
  onToggleNotification,
  onToggleVehicleListing,
  saveNotifications,
  setIsClickOutsideDisabled,
}: Props) => (
  <>
    <div>
      {notifications?.notifications?.[NotificationCategory.VEHICLE_LISTINGS]?.map((notification) => (
        <div key={notification?.id} className={style.section}>
          <div className={style.sectionTitle}>{notification?.name}</div>
          {notification?.description && <div className={style.sectionSubtitle}>{notification?.description}</div>}
          <div className={style.buttons}>
            {getNotificationOptions(notification as Notification).map((item) => (
              <Button
                key={item}
                onClick={() => {
                  const eventName = `notifications_${notification?.id}_${item}_clicked`;
                  trackUserActionWithUserAttributes(eventName as UserAction);
                  onToggleNotification({ ...notification, [item]: !notification?.[item] } as Notification);
                }}
                theme={notification?.[item] ? 'blue' : 'black-outline'}
              >
                {t(item)}
              </Button>
            ))}
          </div>
        </div>
      ))}
      <div className={style.section}>
        <div className={style.sectionTitle}>{t('auction_format')}</div>
        <div className={style.sectionSubtitle}>{t('notifications_auction_format_msg')}</div>
        <div className={style.buttons}>
          {[
            { label: 'appraisal', value: 'appraisal' },
            { label: 'grounded', value: 'grounded' },
            { label: 'buyNow', value: 'timedOffer' },
            { label: 'live', value: 'auction' },
          ].map((item) => (
            <Button
              key={item.label}
              onClick={onToggleVehicleListing(item.value)}
              theme={notifications?.[item.value] ? 'blue' : 'black-outline'}
            >
              {t(camelCaseToSnakeCase(item.label))}
            </Button>
          ))}
        </div>
      </div>
      <div className={style.section}>
        <div className={style.sectionTitle}>{`${t('distance_away')} (${mileageUnit})`}</div>
        <div className={style.buttons}>
          {[10, 50, 100, 250, 500, 750].map((item) => (
            <Button
              key={item}
              onClick={onDistanceChange(item)}
              theme={notifications?.distanceFromPreferredLocation === item ? 'blue' : 'black-outline'}
            >
              {t(item)}
            </Button>
          ))}
          <Button
            onClick={onDistanceChange(ClearableFieldInput.NUMBER)}
            theme={
              !notifications?.distanceFromPreferredLocation ||
              notifications?.distanceFromPreferredLocation === ClearableFieldInput.NUMBER
                ? 'blue'
                : 'black-outline'
            }
          >
            {t('any')}
          </Button>
        </div>
      </div>
      <div className={style.section}>
        <div className={style.sectionTitle}>{t('vehicle_listing_details')}</div>
        <MakeModelFilterList
          makeModelFilters={notifications?.makeModelFilters}
          mileageUnit={mileageUnit}
          notifications={notifications}
          saveNotifications={saveNotifications}
          setIsClickOutsideDisabled={setIsClickOutsideDisabled}
        />
      </div>
    </div>
    <FormDialogFooter>
      <Button className={style.addButton} onClick={onAddVehicleListing} theme="blue">
        {t('add_new')}
      </Button>
    </FormDialogFooter>
  </>
);

export default VehicleListingsTab;
