import ApiRequest from 'store/shared/api/apiRequest';
import { CarfaxUSAReportResponse, MutationorderCarfaxUSAReportArgs } from 'store/shared/api/graph/interfaces/types';

export const orderCarfaxUSAReport = ({ authorizationCode = undefined, vin }: MutationorderCarfaxUSAReportArgs) =>
  ApiRequest<{ orderCarfaxUSAReport: CarfaxUSAReportResponse }>({
    data: {
      query: `
        mutation ($authorizationCode: String, $vin: String!) {
          orderCarfaxUSAReport(authorizationCode: $authorizationCode, vin: $vin) {
            reportUrl
            requiresAuthentication
          }
        }
      `,
      variables: {
        authorizationCode,
        vin,
      },
    },
  });
