import classnames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import checkmarkGlyph from 'glyphs/check.svg';
import editGlyph from 'glyphs/edit.svg';

import Button from 'components/ui/shared/button';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import SaleLightButton from 'components/ui/shared/saleLights/saleLightButton';
import Sprite from 'components/ui/shared/sprite';
import { AppDispatch } from 'store/configureStore';
import { AuctionItemUpdateInput, SaleLight } from 'store/shared/api/graph/interfaces/types';
import { processAuctionItemUpdate } from 'store/admin/liveLanes/auctionClerk/auctionClerkActions';
import { t } from 'utils/intlUtils';
import { usePrevious } from 'hooks/usePrevious';

import style from './saleLights.scss';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to set the sale lights. */
  auctionItemUpdate: (options: AuctionItemUpdateInput) => processAuctionItemUpdate(options, dispatch),
});

export enum EditType {
  NONE,
  EDIT_BUTTON,
  LIGHT_CLICK_EDIT,
}

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** The active sale light of the auction lane. */
  activeSaleLights: SaleLight[] | undefined;
  /** The id of the auction item. */
  auctionItemId: string;
  /** The className to overwrite default styles */
  className?: string;
  /**
   * The way the sale lights can be edited.
   *    NONE - editing disabled.
   *    EDIT_BUTTON - User must click edit button, select active lights, then click button again to submit.
   *    LIGHT_CLICK - Clicking a light will invoke update function to turn on or off.
   */
  editType: EditType;
  /**
   * The number of active autobids
   * @default 0
   */
  numOfAutoBids?: number;
  /** CSS styling to overwrite default sale light style. */
  saleLightClassName?: string;
}

const SaleLights = ({
  activeSaleLights,
  auctionItemId,
  auctionItemUpdate,
  className,
  editType,
  numOfAutoBids = 0,
  saleLightClassName,
}: Props) => {
  const [selectedSaleLights, setSelectedSaleLights] = useState<SaleLight[]>(activeSaleLights ?? []);
  const [isEditMode, setEditMode] = useState<boolean>(editType === EditType.LIGHT_CLICK_EDIT);
  const [isRemoveAutobidsDialogOpen, setIsRemoveAutobidsDialogOpen] = useState<boolean>(false);
  const previousActiveSaleLights = usePrevious(activeSaleLights);
  const previousAuctionItemId = usePrevious(auctionItemId);

  /**
   * Re-initialize sale lights when auction item changes.
   */
  useEffect(() => {
    if (previousAuctionItemId !== auctionItemId || previousActiveSaleLights !== activeSaleLights) {
      setEditMode(editType === EditType.LIGHT_CLICK_EDIT);
      setSelectedSaleLights(activeSaleLights ?? []);
    }
  }, [activeSaleLights, auctionItemId, editType, previousActiveSaleLights, previousAuctionItemId]);

  /**
   * Add sale light.
   * Possible selections:
   *   - Red or Green active; not both.
   *   - Yellow can only be active if red or green active.
   *   - None selected.
   */
  const onAddSaleLight = useCallback(
    (value: SaleLight) => {
      let currentLights = [...selectedSaleLights, value];

      // Yellow light is never active by itself.
      if (value === SaleLight.YELLOW && currentLights.length === 1) {
        return currentLights.filter((saleLight) => saleLight !== SaleLight.YELLOW);
      }

      // Only green OR red can be selected, not both.
      if (value === SaleLight.GREEN) {
        currentLights = currentLights.filter((saleLight) => saleLight !== SaleLight.RED);
      } else if (value === SaleLight.RED) {
        currentLights = currentLights.filter((saleLight) => saleLight !== SaleLight.GREEN);
      }

      return currentLights;
    },
    [selectedSaleLights]
  );

  /**
   * Remove sale light.
   */
  const onRemoveSaleLight = useCallback(
    (value: SaleLight) => {
      let currentLights = selectedSaleLights.filter((saleLight) => saleLight !== value);

      // Yellow light must be deactivated when red or green light deactivated.
      if ([SaleLight.RED, SaleLight.GREEN].includes(value)) {
        currentLights = currentLights.filter((saleLight) => saleLight !== SaleLight.YELLOW);
      }
      return currentLights;
    },
    [selectedSaleLights]
  );

  /**
   * Submit changes to api, close dialog
   */
  const onSubmit = useCallback(
    (shouldClearAutoBids?: boolean, saleLights?: SaleLight[]) => {
      setIsRemoveAutobidsDialogOpen(false);
      auctionItemUpdate({
        auctionItemId,
        saleLightsInput: {
          clearAutoBids: !!shouldClearAutoBids,
          saleLights: saleLights ?? selectedSaleLights,
        },
      });
    },
    [auctionItemId, auctionItemUpdate, selectedSaleLights]
  );

  /**
   * Confirm dialog that gives the Auction Clerk an option to clear autobids after changing the sale lights.
   */
  const onConfirmChange = useCallback(
    (saleLights?: SaleLight[]) => {
      if (numOfAutoBids > 0) {
        setIsRemoveAutobidsDialogOpen(true);
        return;
      }
      onSubmit(false, saleLights);
    },
    [numOfAutoBids, onSubmit]
  );

  /**
   * Click handler for sale light buttons.
   **/
  const onClickSaleLight = useCallback(
    (saleLight: SaleLight) => {
      const isActive = selectedSaleLights.includes(saleLight);
      const currentLights = isActive ? onRemoveSaleLight(saleLight) : onAddSaleLight(saleLight);

      setSelectedSaleLights(currentLights);
      if (editType === EditType.LIGHT_CLICK_EDIT) {
        onConfirmChange(currentLights);
      }
    },
    [editType, onAddSaleLight, onConfirmChange, onRemoveSaleLight, selectedSaleLights]
  );

  /**
   * Click handler for edit button.
   **/
  const onEditButtonClick = useCallback(() => {
    if (isEditMode) {
      onConfirmChange();
    }
    setEditMode(!isEditMode);
  }, [isEditMode, onConfirmChange]);

  return (
    <>
      <div className={classnames(style.container, className)}>
        {Object.values(SaleLight).map((saleLight: SaleLight) => (
          <SaleLightButton
            key={`sale-light-${saleLight}`}
            className={saleLightClassName}
            isActive={selectedSaleLights.includes(saleLight)}
            isDisabled={saleLight === SaleLight.YELLOW && selectedSaleLights.length === 0}
            onClick={isEditMode ? () => onClickSaleLight(saleLight) : undefined}
            saleLight={saleLight}
          />
        ))}
        {editType === EditType.EDIT_BUTTON && (
          <Button
            className={classnames(style.editButton, { [style.edit]: !isEditMode })}
            dataTestId="edit-auction-lights"
            onClick={() => onEditButtonClick()}
            theme="none"
            title={t('edit_x', [t('lights')])}
          >
            <Sprite
              className={classnames(style.icon, { [style.pencil]: !isEditMode })}
              glyph={isEditMode ? checkmarkGlyph : editGlyph}
            />
          </Button>
        )}
      </div>

      <ConfirmDialog
        actionLabel={t('yes')}
        bringToFront
        dismissLabel={t('no')}
        isOpen={isRemoveAutobidsDialogOpen}
        onConfirm={() => onSubmit(true)}
        onDismiss={() => onSubmit(false)}
        theme="green"
        title={t('remove_autobids')}
      >
        <p>{t('remove_autobids_confirm_message_a')}</p>
        <p>{t('remove_autobids_confirm_message_b')}</p>
      </ConfirmDialog>
    </>
  );
};

export default connector(SaleLights);
