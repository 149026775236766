import classnames from 'classnames';
import { useCallback, useMemo } from 'react';

import Button from 'components/ui/shared/button';
import { SaleLight } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './saleLightButton.scss';

type Props = {
  /** True when the button is active. */
  isActive: boolean;
  /** CSS styling to overwrite default style. */
  className?: string;
  /** True when the button is disabled. */
  isDisabled?: boolean;
  /** Function invoked on button click. */
  onClick?: (saleLight: SaleLight) => void;
  /** The sale light the button represents. */
  saleLight: SaleLight;
};

const SaleLightButton = ({ className, isActive, isDisabled, onClick, saleLight }: Props) => {
  const isClickable = useMemo(() => !isDisabled && !!onClick, [isDisabled, onClick]);

  /**
   * Sale light onClick handler.
   */
  const onSaleLightClick = useCallback(() => isClickable && onClick?.(saleLight), [isClickable, onClick, saleLight]);

  return (
    <Button
      className={classnames(style.button, className, {
        [style[`theme-${saleLight.toLowerCase()}`]]: isActive,
        [style.clickable]: isClickable,
      })}
      dataTestId={`sale-light-${saleLight.toLowerCase()}`}
      onClick={onSaleLightClick}
      theme="none"
    >
      {t(`${saleLight.toLowerCase()}_abbreviation`)}
    </Button>
  );
};

export default SaleLightButton;
