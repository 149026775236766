import { MouseEventHandler, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import cancelGlyph from 'glyphs/operationIcons/circle-cancel.svg';
import checkGlyph from 'glyphs/operationIcons/circle-check.svg';

import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import User from 'constants/user';
import { VehicleCaptureType, VehicleStatus } from 'store/shared/api/graph/interfaces/types';
import { AppDispatch } from 'store/configureStore';
import { FeatureFlag } from 'constants/featureFlags';
import { InventoryItemDetailsProps } from 'store/inventoryItemDetails/inventoryItemDetailsModels';
import { UserAction } from 'logging/analytics/events/userActions';
import { getErrors } from 'utils/apiUtils';
import { hasFeatureFlag } from 'utils/featureFlagUtils';
import { hasSystemPermission } from 'utils/userUtils';
import { processUpdateInventoryItemStatus } from 'store/inventoryItemDetails/inventoryItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithInventoryItemAttributes } from 'utils/analyticsUtils';
import { useGlobalDialog } from 'contexts/globalDialogContext';

import style from './captureCompleted.scss';

interface Props {
  /** The Vehicle being captured. */
  inventoryItem: InventoryItemDetailsProps;
  /** Current logged in user. */
  user: User;
}

const CaptureCompleted = ({ inventoryItem, user }: Props) => {
  const { setConfig } = useGlobalDialog();
  const dispatch = useDispatch<AppDispatch>();

  const isCompleted: boolean = useMemo(() => {
    return inventoryItem?.status === VehicleStatus.CAPTURED;
  }, [inventoryItem]);

  /**
   * Handles capture completion toggle
   */
  const handleOnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e?.preventDefault();

      if (!inventoryItem.id) {
        return;
      }

      // Toggle the vehicle status between inprogress and captured
      processUpdateInventoryItemStatus(
        {
          inventoryItemId: inventoryItem.id,
          status: isCompleted ? VehicleStatus.CAPTURE_IN_PROGRESS : VehicleStatus.CAPTURED,
        },
        dispatch
      )?.catch((error) => {
        // Show alert dialog with errors
        setConfig({ errorsOverride: getErrors(error) });
      });

      // Analytics
      trackUserActionWithInventoryItemAttributes(
        isCompleted ? UserAction.VDP_CAPTURE_COMPLETE_CANCEL : UserAction.VDP_CAPTURE_COMPLETE_CONFIRM,
        inventoryItem
      );
    },
    [dispatch, isCompleted, inventoryItem, setConfig]
  );

  // Do not display `Capture Completion` button if any of the follow conditions are true
  if (
    !hasFeatureFlag(FeatureFlag.CAPTURE_CONFIRMATION) ||
    inventoryItem?.completionPercentage !== 1 ||
    inventoryItem?.captureType === VehicleCaptureType.SELF_CAPTURE ||
    !hasSystemPermission(user, ['CAPTURE_VERIFIED_ITEMS'])
  ) {
    return null;
  }

  return (
    <>
      <OperationButton
        className={style.button}
        dataTestId="capture-completed-button"
        glyph={isCompleted ? cancelGlyph : checkGlyph}
        onClick={handleOnClick}
        theme={isCompleted ? 'red' : 'green'}
      >
        {t(isCompleted ? 'capture_set_incomplete' : 'capture_set_complete')}
      </OperationButton>
    </>
  );
};

export default CaptureCompleted;
