import { lazy, ReactElement, Suspense } from 'react';

import AuthService from 'store/shared/services/authService';
import { FeatureFlag } from 'constants/featureFlags';
import { Route } from 'store/routing/routes';
import { AuctionItemFormat } from 'store/shared/api/graph/interfaces/types';
import { getUrlParams } from 'utils/urlUtils';
import { hasAuctionFeatureFlag, hasFeatureFlag } from 'utils/featureFlagUtils';
import { hasSystemPermission } from 'utils/userUtils';

const AddAuctionButton = lazy(() => import('components/sections/admin/auctions/addAuctionButton'));
const AddBroadcastButton = lazy(() => import('components/sections/admin/broadcasts/addBroadcastButton'));
const AddCarrierButton = lazy(() => import('components/sections/admin/carriers/addCarrierButton'));
const AddVehicleButton = lazy(() => import('components/sections/inventoryItem/addModify/addVehicleButton'));
const CreateTransportJobButton = lazy(() => import('components/sections/admin/transportJobs/addTransportJobButton'));
const PrintMyBlockButton = lazy(() => import('components/sections/myBlock/printMyBlockButton'));
const RegisterCompanyButton = lazy(() => import('components/sections/admin/companies/registerCompanyButton'));
const RequestTransportButton = lazy(() => import('components/sections/admin/transportJobs/requestTransportButton'));
const SelectConsignerButton = lazy(() => import('components/sections/reports/selectConsignerButton'));
const ToggleDetailsViewButton = lazy(() => import('components/sections/auctionItem/details/toggleDetailsViewButton'));

export interface MenuItem {
  /** An array of available links for a section */
  links: {
    /** The className to overwrite default styles */
    className?: string;
    /** The component to render instead of the label */
    component?: ReactElement<any, any>;
    /** The label of the link */
    label: string;
    /** The link's path: e.g.: "Route.ACCOUNT" */
    link: Route | string;
    /** The permission required to view the MenuItem */
    permission?: string;
    /** The route in which a link is restricted to */
    restrictedTo?: Route | string;
  }[];
  /** An array of buttons available on the right-side of the sub-nav */
  buttons?: {
    /** The className to overwrite default styles */
    className?: string;
    /** The component to render instead of the label */
    component: ReactElement<any, any>;
    /** The label of the button */
    label?: string;
    /** The permission required to view the MenuItem */
    permission?: string;
    /** The route in which a button is restricted to */
    restrictedTo?: Route | string;
  }[];
}

export interface MenuItems {
  [key: string]: MenuItem;
}

export const getSubNavigationMenuItems = (): MenuItems => {
  const isInventoryItemSet = getUrlParams()?.inventoryItemId !== undefined;
  const isComparablesView = (window.location?.pathname as Route) === Route.BUY_COMPARABLES;
  const isComparablesViewEnabled =
    hasSystemPermission(AuthService.user, ['SYSTEM_VIEW_COMPARABLES']) &&
    AuthService.isStaffUser() &&
    isComparablesView &&
    isInventoryItemSet;
  const isAuctionClerkViewEnabled = window.location?.pathname?.includes(Route.ADMIN_LIVE_LANES_AUCTION_CLERK);
  const isAuctioneerViewEnabled = window.location?.pathname?.includes(Route.ADMIN_LIVE_LANES_AUCTIONEER);
  const isSimulcastEnabled = hasFeatureFlag(FeatureFlag.SIMULCAST);
  const isMyBlockBuyerViewEnabled = hasFeatureFlag(FeatureFlag.MY_BLOCK_BUYER_VIEW);
  const isTimedOfferEnabled = hasAuctionFeatureFlag(FeatureFlag.TIMED_OFFER);

  return {
    buy: {
      links: [
        {
          label: 'timed_auction',
          link: Route.BUY_TIMED_AUCTION,
        },
        {
          label: 'run_list',
          link: Route.BUY_RUN_LIST,
        },
        {
          label: 'live_auction',
          link: Route.BUY_LIVE_AUCTION,
        },
        isTimedOfferEnabled && {
          label: 'buy_now',
          link: Route.BUY_BUY_NOW,
        },
        isComparablesViewEnabled
          ? {
              label: 'comparables',
              link: Route.BUY_COMPARABLES,
            }
          : undefined,
      ].filter(Boolean),
      buttons: [
        {
          component: (
            <Suspense fallback={<div />}>
              <ToggleDetailsViewButton isLiveAuction />
            </Suspense>
          ),
          restrictedTo: Route.BUY_LIVE_AUCTION,
        },
      ],
    },

    sell: {
      links: [
        {
          label: 'parked',
          link: Route.SELL_PARKED,
        },
      ],
      buttons: [
        {
          label: 'add_vehicle',
          component: (
            <Suspense fallback={<div />}>
              <AddVehicleButton />
            </Suspense>
          ),
        },
      ],
    },

    pending: {
      links: [
        {
          label: 'auction_item_status_in_if_bid',
          link: Route.PENDING_IN_IF_BID,
        },
        {
          label: 'auction_item_status_pending_delivery',
          link: Route.PENDING_DELIVERY,
        },
        {
          label: 'auction_item_status_awaiting_checkout',
          link: `${Route.PENDING_AWAITING_CHECKOUT}?transactionType=BUYING`,
        },
      ],
    },

    finished: {
      links: [
        {
          label: 'sold',
          link: Route.FINISHED_SOLD,
        },
        !AuthService.isStaffUser()
          ? {
              label: 'purchased',
              link: Route.FINISHED_PURCHASED,
            }
          : undefined,
        {
          label: 'auction_item_status_no_sale',
          link: Route.FINISHED_NO_SALE,
        },
        {
          label: 'cancelled',
          link: Route.FINISHED_CANCELLED,
        },
      ].filter(Boolean),
    },

    admin: {
      links: [
        {
          label: 'companies',
          link: `${Route.ADMIN_COMPANIES}?status=ENABLED`,
          permission: 'SYSTEM_VIEW_COMPANIES',
        },
        {
          label: 'users',
          link: `${Route.ADMIN_USERS}?status=ENABLED`,
          permission: 'SYSTEM_VIEW_USERS',
        },
        {
          label: 'auctions',
          link: Route.ADMIN_AUCTIONS,
          permission: 'SYSTEM_VIEW_AUCTIONS',
        },
        {
          label: 'events',
          link: `${Route.ADMIN_EVENTS}?timeSlotFormats=${AuctionItemFormat.AUCTION}`,
          permission: 'SYSTEM_VIEW_EVENTS',
        },
        {
          label: 'broadcasts',
          link: Route.ADMIN_BROADCASTS,
          permission: 'SYSTEM_VIEW_BROADCASTS',
        },
        isSimulcastEnabled &&
          isAuctionClerkViewEnabled && {
            label: 'auction_clerk',
            link: Route.ADMIN_LIVE_LANES_AUCTION_CLERK,
            permission: 'SYSTEM_VIEW_EVENTS',
          },
        isSimulcastEnabled &&
          isAuctioneerViewEnabled && {
            label: 'auctioneer',
            link: Route.ADMIN_LIVE_LANES_AUCTIONEER,
            permission: 'SYSTEM_VIEW_EVENTS',
          },
        {
          label: 'arbitrations',
          link: Route.ADMIN_ARBITRATIONS,
          permission: 'SYSTEM_VIEW_ARBITRATIONS',
        },
        {
          label: 'reports',
          link: Route.ADMIN_REPORTS,
        },
      ].filter(Boolean),
      buttons: [
        {
          component: (
            <Suspense fallback={<div />}>
              <RegisterCompanyButton />
            </Suspense>
          ),
          restrictedTo: Route.ADMIN_COMPANIES,
        },
        {
          component: (
            <Suspense fallback={<div />}>
              <AddBroadcastButton />
            </Suspense>
          ),
          restrictedTo: Route.ADMIN_BROADCASTS,
        },
        {
          component: (
            <Suspense fallback={<div />}>
              <AddAuctionButton />
            </Suspense>
          ),
          permission: 'SYSTEM_CREATE_EDIT_AUCTION',
          restrictedTo: Route.ADMIN_AUCTIONS,
        },
        {
          component: (
            <Suspense fallback={<div />}>
              <SelectConsignerButton />
            </Suspense>
          ),
          restrictedTo: Route.ADMIN_REPORT,
        },
      ],
    },

    transport: {
      links: [
        {
          label: 'transport_jobs',
          link: Route.TRANSPORT_JOBS,
          permission: 'SYSTEM_VIEW_TRANSPORT',
        },
        {
          label: 'carriers',
          link: Route.TRANSPORT_CARRIERS,
          permission: 'SYSTEM_VIEW_TRANSPORT',
        },
      ],
      buttons: [
        {
          component: (
            <Suspense fallback={<div />}>
              <RequestTransportButton />
            </Suspense>
          ),
          restrictedTo: Route.TRANSPORT_JOBS,
        },
        {
          component: (
            <Suspense fallback={<div />}>
              <CreateTransportJobButton />
            </Suspense>
          ),
          restrictedTo: Route.TRANSPORT_JOBS,
        },
        {
          component: (
            <Suspense fallback={<div />}>
              <AddCarrierButton />
            </Suspense>
          ),
          restrictedTo: Route.TRANSPORT_CARRIERS,
        },
      ],
    },

    myBlock: {
      links: [
        {
          label: 'seller',
          link: Route.MY_BLOCK_SELLER,
        },
        isMyBlockBuyerViewEnabled && {
          label: 'buyer',
          link: Route.MY_BLOCK_BUYER,
        },
      ].filter(Boolean),
      buttons: [
        {
          component: (
            <Suspense fallback={<div />}>
              <ToggleDetailsViewButton />
            </Suspense>
          ),
          restrictedTo: Route.MY_BLOCK,
        },
        {
          component: (
            <Suspense fallback={<div />}>
              <PrintMyBlockButton />
            </Suspense>
          ),
        },
      ],
    },

    myMarketGuide: {
      links: [
        {
          label: 'my_market_guide',
          link: Route.MY_MARKET_GUIDE,
        },
      ].filter(Boolean),
    },

    account: {
      links: [
        {
          label: 'my_account',
          link: Route.ACCOUNT,
        },
      ],
    },
  };
};

export const hasAdminVisibility = (): boolean => {
  const menuItems = getSubNavigationMenuItems()?.admin?.links ?? [];
  return menuItems?.some((menuItem) => hasSystemPermission(AuthService.user, [menuItem?.permission].filter(Boolean)));
};

export const getFirstAvailableAdminMenuItem = () => {
  const menuItems = getSubNavigationMenuItems()?.admin?.links ?? [];
  return menuItems?.filter((menuItem) =>
    hasSystemPermission(AuthService.user, [menuItem?.permission].filter(Boolean))
  )?.[0];
};
