import { ConnectedProps, connect } from 'react-redux';
import { LocationDescriptor } from 'history';
import { Suspense, ReactNode } from 'react';
import { replace } from 'react-router-redux';

import BaseClass from 'components/ui/shared/base';
import CoreLayout from 'layouts/coreLayouts/coreLayouts';
import DatadogLogger from 'logging/DatadogLogger';
import Loading, { LOADER_TEXT } from 'components/ui/loading/loading';
import { AppDispatch, AppState } from 'store/configureStore';
import { ErrorMessages } from 'constants/errors';
import { Location } from 'constants/reactRouter';
import { Route } from 'store/routing/routes';
import { clearRoutingTarget, setRoutingTarget } from 'store/routing/routingActions';
import { getUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { logoutAndDeleteToken, processTryLogin } from 'store/auth/authActions';
import { onError as onApiError } from 'store/shared/api/apiRequest';
import { removeLoaderFromIndexHTML } from 'utils/animationUtils';
import { setIsError429Active } from 'store/system/systemActions';
import { t } from 'utils/intlUtils';

const noAuthPaths = [
  // NOTE: If the homepage is going to live within this environment, enable the following line.
  // '/',
  Route.AUTH_REGISTER,
  Route.AUTH_EMPLOYEE,
  Route.AUTH_FORGOT_PASSWORD,
  Route.AUTH_LOGIN,
  Route.AUTH_LOGOUT,
  Route.AUTH_PIPELINE,
  Route.AUTH_REQUEST_INVITE,
  '/auth/reset-password/\\S+$',
];

const publicPaths = [Route.ACKNOWLEDGEMENTS, '?redirectToAcknowledgements=true'];

const enabledMobilePaths = [Route.AUTH_REQUEST_INVITE, Route.AUTH_CARFAX_USA, Route.AUTH_PIPELINE, Route.AUTH_REGISTER];

const requiresAuth = (location) => {
  const { pathname, search } = location;
  if ([pathname, search].some((path) => publicPaths.includes(path))) {
    // If pathname or search are included, don't redirect
    return false;
  }
  return !noAuthPaths.reduce((matches, noAuthPath) => matches || pathname.match(new RegExp(noAuthPath)), false);
};

const stateConnect = (state: AppState) => ({
  /** Authorization information. */
  auth: state.app.auth,
  /** Whether network is up or not */
  isNetworkUp: state.app.system.isNetworkUp,
  /** True when a 429 error status code has been received */
  isError429Active: state.app.system.isError429Active,
  /** Routing target information. */
  routingTarget: state.app.routes.routingTarget,
  /** The logged-in user. */
  user: state.app.user,
});

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback function to clear routing target. */
  clearRoutingTarget: () => dispatch(clearRoutingTarget()),
  /** Callback function to log out the user. */
  logout: () => logoutAndDeleteToken(dispatch),
  /** Callback function to replace history location. */
  replace: (location: LocationDescriptor) => dispatch(replace(location)),
  /** Enables Error429 page upon receiving 429 error status code */
  setError429: () => dispatch(setIsError429Active(true)),
  /** Callback function to set routing target. */
  setRoutingTarget: (data: Location) => dispatch(setRoutingTarget(data)),
  /** Callback function to try login. */
  tryLogin: () => processTryLogin(dispatch),
});

const connector = connect(stateConnect, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** Children components. */
  children: ReactNode | ReactNode[];
  /** Router location. */
  location?: Location;
}

interface State {
  /** Whether the auth info has been loaded. */
  authLoaded: boolean;
  /** Error messages. */
  errorMessages: ErrorMessages;
}

class RouterRoot extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      authLoaded: false,
      errorMessages: [],
    };

    if (props?.location?.query?.path) {
      props?.replace(props?.location?.query?.path as Route);
    } else if (props?.location?.query?.redirectToAcknowledgements) {
      props?.replace(Route.ACKNOWLEDGEMENTS);
    }

    if (!props?.auth.isLoggedIn && requiresAuth(props?.location)) {
      props?.replace(`${Route.AUTH_REGISTER}?${paramsToQueryString(getUrlParams())}`);
    } else if (!props?.user.loadedDate && !props?.user.isLoading) {
      props
        ?.tryLogin()
        ?.then(() => this.setState({ authLoaded: true }))
        ?.catch(this.onApiError);
    } else {
      this.setState({ authLoaded: true });
    }

    if (props?.auth.isLoggedIn && noAuthPaths.includes(props?.location?.pathname)) {
      // Reroute to root when trying to access auth areas while logged in
      props?.replace(Route.HOME);
    }

    this.conditionallySetRoutingTarget(props?.location);

    onApiError((err) => {
      if (err.response && err.response.status === 401) {
        props?.logout();
        props?.replace(Route.AUTH_REGISTER);
      }

      if (err.response && err.response.status === 429) {
        // Too many requests, short-circuit activity by redirecting to 429 error page
        props?.setError429();
      }
    });

    // Track the initial page view when booting up the application
    DatadogLogger.trackPageView(props?.location?.pathname);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.authLoaded && this.state.authLoaded) {
      // The application has booted - so let's remove the spinner animation from the shell
      removeLoaderFromIndexHTML();
    }

    if (!this.props?.auth.isLoggedIn && requiresAuth(this.props?.location)) {
      this.props?.replace(`${Route.AUTH_REGISTER}?${paramsToQueryString(getUrlParams())}`);
      return;
    }

    // If the user was reset, try to re fetch it
    if (this.props?.user?.loadedDate && !this.props?.user?.loadedDate && !this.props?.user?.isLoading) {
      this.props?.tryLogin()?.catch(this.onApiError);
    }

    // If we are changing routes, set the routing target
    if (prevProps?.location?.pathname !== this.props?.location?.pathname) {
      this.conditionallySetRoutingTarget(this.props?.location);

      if (this.props?.location?.pathname) {
        DatadogLogger.trackPageView(this.props?.location?.pathname);
      }

      if (this.props?.location?.pathname === (Route.AUTH_REGISTER as string)) {
        this.setState({ authLoaded: true });
      }
    }
  }

  conditionallySetRoutingTarget(location) {
    if (requiresAuth(location)) {
      this.props?.setRoutingTarget(location);
    }
  }

  render() {
    const { auth, children, location, isNetworkUp, isError429Active } = this.props;
    const { authLoaded, errorMessages } = this.state;
    const isMobileEnabledPath = enabledMobilePaths.includes(location?.pathname as Route);
    const props = { isMobileEnabledPath, isNetworkUp, isError429Active };

    if (errorMessages.length) {
      // Remove root loading animation and throw an error to force the ErrorBoundary
      removeLoaderFromIndexHTML();
      throw new Error(errorMessages[0].message);
    }

    const fallbackLoader = (
      <Loading hasFullWidth isLoading isModalTransparent text={auth.isLoggedIn ? t(LOADER_TEXT) : undefined} />
    );

    return (
      authLoaded && (
        <CoreLayout {...props}>
          <Suspense fallback={fallbackLoader}>{children}</Suspense>
        </CoreLayout>
      )
    );
  }
}

export default connector(RouterRoot);
