import Button from 'components/ui/shared/button';
import { Notification } from 'store/shared/api/graph/interfaces/types';
import {
  Notifications,
  NotificationTab,
  NotificationTabMap,
} from 'components/sections/notifications/userNotificationsSlideOut';
import { UserAction } from 'logging/analytics/events/userActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithUserAttributes } from 'utils/analyticsUtils';

import style from 'components/sections/notifications/userNotificationsSlideOut.scss';

interface Props {
  getNotificationOptions: (notification: Notification) => string[];
  notifications: Notifications | null;
  onToggleNotification: (notification: Notification) => void;
  selectedTab: NotificationTab;
}

const OtherTab = ({ getNotificationOptions, notifications, onToggleNotification, selectedTab }: Props) =>
  notifications?.notifications?.[NotificationTabMap[selectedTab]]?.map((notification) => (
    <div key={notification?.id} className={style.section}>
      <div className={style.sectionTitle}>{notification?.name}</div>
      {notification?.description && <div className={style.sectionSubtitle}>{notification?.description}</div>}
      <div className={style.buttons}>
        {getNotificationOptions(notification as Notification).map((item) => (
          <Button
            key={item}
            onClick={() => {
              const eventName = `notifications_${notification?.id}_${item}_clicked`;
              trackUserActionWithUserAttributes(eventName as UserAction);
              onToggleNotification({ ...notification, [item]: !notification?.[item] } as Notification);
            }}
            theme={notification?.[item] ? 'blue' : 'black-outline'}
          >
            {t(item)}
          </Button>
        ))}
      </div>
    </div>
  ));

export default OtherTab;
