import { MouseEvent, useCallback, useEffect, useState } from 'react';

import infoGlyph from 'glyphs/info.svg';

import MiniButton from 'components/ui/shared/buttons/miniButton';
import SaleLightFormButton from 'components/sections/auctionItem/operations/submitToAuction/pages/saleLightForm/saleLightFormButton';
import Sprite from 'components/ui/shared/sprite';
import TitleBanner from 'components/ui/shared/notifications/titleBanner';
import { SaleLight } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './saleLightFormSection.scss';

interface Props {
  /** The active sale lights of the auction lane. */
  activeSaleLights: SaleLight[];
  /** True when the current lights differ from the suggested lights. */
  isSuggestedOverridden: boolean;
  /** Function invoked on sale light changes. */
  onChange: (activeLights: SaleLight[]) => void;
  /** Function invoked when the user reverts to the suggested lights. */
  onRevert: (event?: MouseEvent<HTMLButtonElement>) => void;
}

const SaleLightFormSection = ({ activeSaleLights, isSuggestedOverridden, onChange, onRevert }: Props) => {
  const [selectedSaleLights, setSelectedSaleLights] = useState<SaleLight[]>(activeSaleLights ?? []);

  /**
   * Update selected sale lights when active sale lights change.
   */
  useEffect(() => {
    setSelectedSaleLights(activeSaleLights);
  }, [activeSaleLights]);

  /**
   * Add sale light.
   * Possible selections:
   *   - Red or Green selected; not both (Future changes will allow multiple lights to be selected).
   *   - Yellow can only be active if red or green active.
   *   - Neither selected.
   */
  const onAddSaleLight = useCallback(
    (value: SaleLight) => {
      let currentLights = [...selectedSaleLights, value];

      // Yellow light is never active by itself.
      if (value === SaleLight.YELLOW && currentLights.length === 1) {
        currentLights = currentLights.filter((saleLight) => saleLight !== SaleLight.YELLOW);
      }

      // Only green OR red can be selected, not both.
      if (value === SaleLight.GREEN) {
        currentLights = currentLights.filter((saleLight) => saleLight !== SaleLight.RED);
      } else if (value === SaleLight.RED) {
        currentLights = currentLights.filter((saleLight) => saleLight !== SaleLight.GREEN);
      }

      setSelectedSaleLights(currentLights);
      onChange(currentLights);
    },
    [selectedSaleLights, onChange]
  );

  /**
   * Remove sale light.
   */
  const onRemoveSaleLight = useCallback(
    (value: SaleLight) => {
      let currentLights = selectedSaleLights.filter((saleLight) => saleLight !== value);

      // Yellow light must be deactivated when red or green light deactivated.
      if ([SaleLight.RED, SaleLight.GREEN].includes(value)) {
        currentLights = currentLights.filter((saleLight) => saleLight !== SaleLight.YELLOW);
      }

      setSelectedSaleLights(currentLights);
      onChange(currentLights);
    },
    [selectedSaleLights, onChange]
  );

  /**
   * Click handler for sale light buttons.
   **/
  const onClickSaleLight = useCallback(
    (saleLight: SaleLight) => {
      const isActive = selectedSaleLights.includes(saleLight);
      return isActive ? onRemoveSaleLight(saleLight) : onAddSaleLight(saleLight);
    },
    [onAddSaleLight, onRemoveSaleLight, selectedSaleLights]
  );

  return (
    <div className={style.section} data-testid="sale-light-form">
      <div className={style.title}>{t('sale_lights')}</div>
      <div className={style.infoMessage}>
        <Sprite className={style.iconInfo} glyph={infoGlyph} />
        <span>{t('suggested_sale_lights_message')}</span>
      </div>
      <div className={style.buttonContainer}>
        {Object.values(SaleLight).map((saleLight: SaleLight) => (
          <SaleLightFormButton
            key={`sale-light-${saleLight}`}
            isActive={selectedSaleLights.includes(saleLight)}
            isDisabled={saleLight === SaleLight.YELLOW && selectedSaleLights.length === 0}
            onClick={onClickSaleLight}
            saleLight={saleLight}
          />
        ))}
      </div>
      {isSuggestedOverridden && (
        <div className={style.overriddenBanner}>
          <div>{t('suggested_sale_lights_overriden_message')}</div>
          <MiniButton onClick={onRevert} theme="black">
            {t('revert')}
          </MiniButton>
        </div>
      )}
      <TitleBanner theme="green" title={t('green_light')}>
        {t('green_light_description')}
      </TitleBanner>
      <TitleBanner theme="red" title={t('red_light')}>
        {t('red_light_description')}
      </TitleBanner>
      <TitleBanner theme="yellow" title={t('yellow_light')}>
        {t('yellow_light_description')}
      </TitleBanner>
    </div>
  );
};

export default SaleLightFormSection;
