import { createAction } from 'redux-actions';

import { MutationtransportRequestUpdateArgs } from 'store/shared/api/graph/interfaces/types';
import {
  acceptQuote,
  cancelTransportJob,
  cancelTransportRequest,
  getCarriers,
  getTransportJob,
  inviteCarriers,
  saveQuote,
  saveTransportJob,
  transportRequestDelivered,
  transportRequestPickedUp,
  transportRequestUpdate,
} from './transportJobDetailsApi';
import { getTransportJobsList } from '../list/transportJobsApi';
import { getUrlParams } from 'utils/urlUtils';
import { parseQueryParams } from 'utils/apiUtils';
import { processGetTransportJobsList } from '../list/transportJobsActions';

export const isLoaded = createAction('ADMIN_TRANSPORT_JOB_DETAILS_IS_LOADED');
export const isUpdating = createAction('ADMIN_TRANSPORT_JOB_DETAILS_SET_IS_UPDATING');
export const isNotUpdating = createAction('ADMIN_TRANSPORT_JOB_DETAILS_SET_IS_NOT_UPDATING');
export const preloadTransportJobItem = createAction('ADMIN_TRANSPORT_JOB_DETAILS_IS_PRELOADED');
export const updateTransportJobItem = createAction('ADMIN_TRANSPORT_JOB_DETAILS_IS_UPDATED');
export const updateCarriers = createAction('ADMIN_TRANSPORT_JOB_UPDATE_CARRIERS_IS_LOADED');
export const setTransportJobData = createAction('ADMIN_TRANSPORT_JOB_DETAILS_SETTING_DATA');
export const setSavingState = createAction('ADMIN_TRANSPORT_JOB_DETAILS_SET_SAVING_STATE');
export const setSelectedQuoteId = createAction('ADMIN_TRANSPORT_JOB_DETAILS_SET_SELECTED_QUOTE_ID');
export const clearTransportRequestsList = createAction('ADMIN_TRANSPORT_JOB_DETAILS_CLEAR_REQUESTS_LIST');
export const updateTransportRequestsList = createAction('ADMIN_TRANSPORT_JOB_DETAILS_UPDATE_REQUESTS_LIST');

export const processGetTransportJob = async (options, dispatch) => {
  dispatch(isUpdating());
  const transportJobDetails = (await getTransportJob(options))?.data?.data;
  dispatch(isLoaded(transportJobDetails));
};

export const processUpdateTransportRequest = async (options: MutationtransportRequestUpdateArgs, dispatch) => {
  const transportRequest = (await transportRequestUpdate(options))?.data?.data?.transportRequestUpdate;
  dispatch(updateTransportJobItem(transportRequest));
  processGetTransportJobsList(parseQueryParams(getUrlParams()), dispatch);
  return transportRequest;
};

export const processGetCarriers = (dispatch) => {
  return getCarriers().then((response) => {
    const formattedResponse = response?.data?.data?.carrierConnection?.edges;
    dispatch(updateCarriers(formattedResponse));
  });
};

export const processSaveQuote = async (options, dispatch) => {
  const isAdding = !options.id;
  const mutationName = isAdding ? 'transportJobQuoteCreate' : 'transportJobQuoteUpdate';
  const transportJob = (await saveQuote(options))?.data?.data?.[mutationName];
  dispatch(updateTransportJobItem(transportJob));
  processGetTransportJobsList(parseQueryParams(getUrlParams()), dispatch);
  return transportJob;
};

export const processAcceptQuote = async (options, dispatch) => {
  const transportJob = (await acceptQuote(options))?.data?.data?.transportJobQuoteAccept;
  dispatch(updateTransportJobItem(transportJob));
  processGetTransportJobsList(parseQueryParams(getUrlParams()), dispatch);
  return transportJob;
};

export const processInviteCarriers = async (options, dispatch) => {
  const transportJob = (await inviteCarriers(options))?.data?.data?.transportJobQuoteInvite;
  dispatch(updateTransportJobItem(transportJob));
  processGetTransportJobsList(parseQueryParams(getUrlParams()), dispatch);
  return transportJob;
};

export const processCancelTransportJob = async (options, dispatch) => {
  const transportJob = (await cancelTransportJob(options))?.data?.data?.transportJobCancelAll;
  dispatch(updateTransportJobItem(transportJob));
  processGetTransportJobsList(parseQueryParams(getUrlParams()), dispatch);
  return transportJob;
};

export const processGetTransportRequests = async (options, dispatch) => {
  const transportRequests = (await getTransportJobsList(parseQueryParams(options), false))?.data?.data
    ?.transportConnection;
  dispatch(updateTransportRequestsList(transportRequests));
};

export const processSaveTransportJob = async (options, dispatch) => {
  const { isAdding } = options;
  const mutationName = isAdding ? 'transportJobCreate' : 'transportJobUpdate';
  const transportJob = (await saveTransportJob(options))?.data?.data?.[mutationName];

  if (!isAdding) {
    dispatch(updateTransportJobItem(transportJob));
  }

  processGetTransportJobsList(parseQueryParams(getUrlParams()), dispatch);
  return transportJob;
};

export const processCancelTransportRequest = async (options, dispatch) => {
  const transportRequest = (await cancelTransportRequest(options))?.data?.data?.transportRequestCancel;
  const params = getUrlParams();

  if (options?.isTransportJobView) {
    processGetTransportJob({ transportJobId: params?.id }, dispatch);
  } else {
    dispatch(updateTransportJobItem(transportRequest));
  }

  processGetTransportJobsList(parseQueryParams(params), dispatch);
  return transportRequest;
};

export const processTransportRequestPickedUp = async (options, dispatch) => {
  const transportRequest = (await transportRequestPickedUp(options))?.data?.data?.transportRequestPickedUp;
  const params = getUrlParams();

  dispatch(updateTransportJobItem(transportRequest));
  processGetTransportJobsList(parseQueryParams(params), dispatch);
  return transportRequest;
};

export const processTransportRequestDelivered = async (options, dispatch) => {
  const transportRequest = (await transportRequestDelivered(options))?.data?.data?.transportRequestDelivered;
  const params = getUrlParams();

  if (options?.isTransportJobView) {
    processGetTransportJob({ transportJobId: params?.id }, dispatch);
  } else {
    dispatch(updateTransportJobItem(transportRequest));
  }

  processGetTransportJobsList(parseQueryParams(params), dispatch);
  return transportRequest;
};
