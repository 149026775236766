import classnames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { useCallback, useState } from 'react';

import starGlyph from 'glyphs/star.svg';

import AuctionItem from 'constants/auctionItem';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { AppDispatch } from 'store/configureStore';
import { MutationauctionItemSetWatchingArgs } from 'store/shared/api/graph/interfaces/types';
import { UserAction } from 'logging/analytics/events/userActions';
import { processSetAuctionItemWatching } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './watchButton.scss';

const dispatchConnect = (dispatch: AppDispatch) => ({
  /** Callback to mark the auction item as watched or un-watched. */
  setWatch: (options: MutationauctionItemSetWatchingArgs) => processSetAuctionItemWatching(options, dispatch),
});

const connector = connect(undefined, dispatchConnect);

export interface Props extends ConnectedProps<typeof connector> {
  /** The id of the auction item. */
  auctionItemId: string;
  /** True when the button label is hidden. */
  hideLabel?: boolean;
  /** True when the component is rendered from a list view. */
  isListView?: boolean;
  /** True when the current user is watching the auction item, otherwise false. */
  isWatched: boolean;
  /** CSS styling to overwrite the default button style. */
  className?: string;
  /** CSS styling to overwrite the icon style. */
  glyphClassName?: string;
}

const WatchButton = ({
  auctionItemId,
  glyphClassName,
  hideLabel,
  isListView,
  isWatched,
  className,
  setWatch,
}: Props) => {
  const [isSubmitting, setSubmitting] = useState<boolean>();
  const watchText = isWatched ? t('remove') : t('add');

  const toggleWatch = useCallback(
    (e) => {
      e.preventDefault();

      if (isSubmitting) {
        return;
      }

      setSubmitting(true);
      setWatch({
        auctionItemId,
        watching: !isWatched,
      }).finally(() => setSubmitting(false));
    },
    [setWatch, auctionItemId, isWatched, isSubmitting]
  );

  return (
    <OperationButton
      key="button"
      className={classnames(style.watch, { [style.remove]: isWatched }, className)}
      dataTestId="watch-button"
      glyph={starGlyph}
      glyphClassName={classnames(style.icon, glyphClassName, { [style.remove]: isWatched })}
      onClick={(e) => {
        toggleWatch(e);

        const action = isListView ? UserAction.RUN_LIST_ADD_TO_WATCHLIST_CLICK : UserAction.VDP_ADD_TO_WATCHLIST_CLICK;
        trackUserActionWithAuctionItemAttributes(action, { id: auctionItemId } as AuctionItem);
      }}
    >
      {!hideLabel && watchText}
    </OperationButton>
  );
};

export default connector(WatchButton);
