import classnames from 'classnames';

import infoGlyph from 'glyphs/info.svg';

import BaseClass from 'components/ui/shared/base';
import Sprite, { Glyph } from 'components/ui/shared/sprite';

import style from './radioButtons.scss';

export interface RadioButtonLabel<ValueType = string> {
  /** Subtitle to be rendered. */
  subtitle?: string;

  /** Subtitle's theme. */
  subtitleTheme?: 'green' | 'orange';

  /** Svg to be rendered. */
  svg?: Glyph;

  /** Title to be rendered. */
  title?: string;

  /** Value of the label. */
  value: ValueType;
}

export interface RadioButtonsProps<ValueType = string> {
  /** Css to overwrite default styles */
  className?: string;

  /** Whether the radio buttons are disabled or not. */
  disabled?: boolean;

  /** ID of the component. */
  id?: string;

  /** Whether is inner buttons style or not. */
  innerButtons?: boolean;

  /** Whether text is capitalized or not. */
  isCapitalizedText?: boolean;

  /** Labels for each radio button. */
  labels: RadioButtonLabel<ValueType>[];

  /** Callback function to handle on radio select change event. */
  onChange?: (e: { target: { value: ValueType } }) => void;

  /** Currently selected option value. */
  selectedOption?: string | number;

  /** Whether to show labels as square or not. */
  squareLabels?: boolean;

  /** Whether the text is stacked or not. */
  stackedText?: boolean;

  /** CSS styles to overwrite the style of the text. */
  textClassName?: string;

  /** Radio buttons theme. */
  theme?: 'button' | 'green' | 'blue' | 'red' | 'colorful';

  /** Whether toggle is enabled or not. */
  toggleEnabled?: boolean;
}

interface RadioButtonsState {
  /** Currently selected option. */
  selectedOption?: string | number;
}

/**
 * RadioButtons renders a group of styled buttons functioning like radial options
 */
class RadioButtons extends BaseClass<RadioButtonsProps, RadioButtonsState> {
  static defaultProps = {
    id: null,
    theme: null,
    disabled: false,
    selectedOption: null,
    className: null,
    textClassName: null,
    innerButtons: false,
    squareLabels: false,
    stackedText: false,
    onChange: () => {},
    toggleEnabled: false,
    isCapitalizedText: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.selectedOption,
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedOption } = this.props;
    const { selectedOption: selectedOptionPrev } = prevProps;
    const { selectedOption: selectedOptionState } = this.state;

    if (selectedOption !== selectedOptionPrev && selectedOption !== selectedOptionState) {
      this.setState({ selectedOption });
    }
  }

  noOp() {
    // Handle onChange requirement on radio input for linter
  }

  handleOptionChange = (e) => {
    const { toggleEnabled } = this.props;
    const { selectedOption } = this.state;
    let value = e.target.value;

    if (toggleEnabled && value === selectedOption) {
      value = null;
    }

    if (value !== selectedOption) {
      this.setState({ selectedOption: value });
      this.props.onChange?.({ target: { value } });
    }
  };

  render() {
    const { selectedOption } = this.state;
    const {
      labels,
      className,
      textClassName,
      innerButtons,
      squareLabels,
      disabled,
      theme,
      id,
      stackedText,
      isCapitalizedText,
    } = this.props;
    const isChecked = (item) => selectedOption === item;
    const isSelectedClass = (item) => (isChecked(item.value) ? style.selected : '');
    const themeColorfulClass = (item) => !!selectedOption && !isChecked(item.value) && style.unselected;
    const showSelectedText = (item) => isChecked(item.value) && <span className={style.selectedText}>Selected</span>;

    return (
      <div
        className={classnames(
          theme !== 'colorful' && style.form,
          style[`theme-${theme}`],
          innerButtons && style.innerButtons,
          squareLabels && style.squareLabels,
          className
        )}
        data-testid="radio-buttons"
      >
        {labels.map((item) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <label
            key={`radioContainer-${item.value}`}
            className={classnames(
              style.label,
              disabled && style.disabled,
              theme === 'colorful' && themeColorfulClass(item),
              style[item.value.toLowerCase()],
              isCapitalizedText && style.capitalizedText,
              theme && isSelectedClass(item)
            )}
            htmlFor={`radio-${id}${item.value}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.handleOptionChange({ target: { value: item.value } });
            }}
          >
            <input
              checked={isChecked(item.value)}
              className={style.inputOption}
              data-testid={`radio-${item.value}`}
              disabled={disabled}
              id={`radio-${id}${item.value}`}
              name="radio"
              onChange={this.noOp}
              type="radio"
              value={item.value}
            />
            <div className={classnames(style.content, textClassName, stackedText && style.subTitleClass)}>
              {item.svg && <Sprite className={style.sprite} glyph={item.svg} />}
              <span>{item.title ? item.title : item.value}</span>
              {theme === 'colorful' && showSelectedText(item)}
              {item.subtitle && (
                <span className={classnames(style.subtitle, item.subtitleTheme && style[item.subtitleTheme])}>
                  {stackedText ? (
                    <Sprite
                      className={classnames(style.infoGlyph, style[`infoGlyph-${item.subtitleTheme}`])}
                      glyph={infoGlyph}
                    />
                  ) : (
                    ' - '
                  )}
                  {item.subtitle}
                </span>
              )}
            </div>
            <div className={classnames(style.optionButton, isSelectedClass(item))} />
          </label>
        ))}
      </div>
    );
  }
}

export default RadioButtons;
