import axios from 'axios';
import { SetOptional } from 'type-fest';

import ApiRequest, { getDefaultHeaders } from 'store/shared/api/apiRequest';
import AuctionItem from 'constants/auctionItem';
import {
  AuctionConnection,
  AuctionItemConnection,
  Company,
  CompanyAuctionRelationship,
  CompanyAuctionRelationshipConnection,
  CompanyConnection,
  CompanyCreditLog,
  CompanyCreditLogConnection,
  CompanySearchResult,
  FindUser,
  InventoryItemConnection,
  MutationcompanyAuctionRelationshipUpdateArgs,
  MutationcompanyAuctionRelationshipsCreateArgs,
  MutationcompanyAuctionRepresentativeAssignArgs,
  MutationcompanyBillingUpdateArgs,
  MutationcompanyInvoiceSignatureSetArgs,
  MutationcompanyUpdateCreditsArgs,
  MutationcompanyUpdateRestrictCarfaxOrderingArgs,
  MutationuserCompanyRelationshipCreateArgs,
  MutationuserCompanyRelationshipUpdateArgs,
  MutationuserCreateArgs,
  QuerycompanyArgs,
  QuerycompanyAuctionConnectionArgs,
  QuerycompanyConnectionArgs,
  QuerycompanyCreditLogConnectionArgs,
  QuerycompanyUserConnectionArgs,
  QueryfindUserArgs,
  User,
  UserCompanyRelationship,
  UserCompanyRelationshipConnection,
} from 'store/shared/api/graph/interfaces/types';
import { auctionFragment } from 'store/shared/api/graph/fragments/auction';
import { companyAuctionPermissionFragment } from 'store/shared/api/graph/fragments/companyAuctionPermission';
import { companyFragment } from 'store/shared/api/graph/fragments/company';
import { companyIntegrationFragment } from 'store/shared/api/graph/fragments/companyIntegrations';
import { companyBilling } from 'store/shared/api/graph/schema/company';
import { getGraphBaseURL } from 'utils/apiUtils';
import { locationFragment } from 'store/shared/api/graph/fragments/common';
import { repsFragment } from 'store/shared/api/graph/fragments/reps';
import { user } from 'store/shared/api/graph/schema/user';

export const getCompanyDetails = ({ companyId = null }) =>
  ApiRequest<{
    company: Company;
    appraisals: Pick<AuctionItemConnection, 'pageInfo'>;
    buyNow: Pick<AuctionItemConnection, 'pageInfo'>;
    upcoming: Pick<AuctionItemConnection, 'pageInfo'>;
    parked: InventoryItemConnection;
    inIfBid: Pick<AuctionItemConnection, 'pageInfo'>;
    pendingDelivery: Pick<AuctionItemConnection, 'pageInfo'>;
    awaitingCheckout: Pick<AuctionItemConnection, 'facetGroups'>;
    purchased: Pick<AuctionItemConnection, 'pageInfo'>;
    sold: Pick<AuctionItemConnection, 'pageInfo'>;
    noSale: Pick<AuctionItemConnection, 'pageInfo'>;
    cancelled: Pick<AuctionItemConnection, 'pageInfo'>;
  }>({
    data: {
      operationName: 'getCompanyDetails',
      query: `
      query( $companyId: String! ) {
        company( companyId: $companyId ) {
          id
          numericId
          checkReleasePreference
          status
          source
          stats {
            purchased
            purchasedArbitrated
            sold
            soldArbitrated
          }
          name
          legalName
          created
          updated
          type
          subType
          parentCompany { name }
          description
          integrations {
            ...companyIntegration
          }
          invoiceSignature {
            signatureUrl
            registrationNumber
          }
          visibleRegions
          primaryLocation {
            address1
            address2
            city
            regionCode
            country
          }
          holdback {
            amount
            type
          }
          notes {
            id
            content
            createdBy {
              firstName
              lastName
              photoUrl(width:150, height:150)
            }
            created
          },
          parentCompany { id, name }
          americanLegalInfo {
            bondAmount {
              amount
              formattedAmount
            }
            bondCompany
            bondExpiry
            bondNumber
            dealerLicenseExpiry
            dealerLicenseNumber
            dealerLicenseState
            ein
            modifiable
            sellerPermitNumber
          }
          auctionAccessInfo {
            auctionAccessId
            auctionAccessLastVerified
          }
          canadianLegalInfo {
            dealerLicenseNumber
            dealerLicenseExpiry
            craHstGstNumber
            ontarioRin
            numeroDeDossier
            skPstNumber
            bcPstNumber
            modifiable
          }
          creditsBuy {
            formattedAmount
          }
          creditsSell {
            formattedAmount
          }
          creditsLoyalty {
            formattedAmount
          }
          auctionRelationships {
            list {
              id
              auctionAccessIssueLogs {
                id
                effectiveDate
                issueName
                resolvedDate
                status
                visibility
              }
              auctionAccessStatus
              defaultTransportLocation {
                id
                name
              }
              status
              tier
              buyerCreditLimit {
                amount
                formattedAmountRounded
              }
              buyerShieldEnabled
              hideSellerNameEnabled
              defaultPaymentType {
                id
                defaultType
                logicalName
                name
              }
              defaultSellerNotes
              eftEnabled
              auction {
                id
                accountingSystem {
                  id
                  name
                }
                currencyCode
                title
                managingCompanies {
                  id
                }
                regionCodes
                representativeTypes {
                  id
                  name
                  description
                }
              }
              auctionServiceDefaults {
                id
                auctionServiceMetadata {
                  id
                  defaultSelected
                  optional
                  feeName
                  serviceName
                  transportService
                }
                defaultSelected
              }
              paymentTypes {
                id
                active
                defaultType
                logicalName
                name
                subType
              }
              permissions {
                id
                name
              }
              sellerAssuranceEnabled
              sendTitlePaymentOverride
              vehicleReleasePaymentOverride
              reps {
                ...reps
              }
            }
          }
          restrictCarfaxCanadaOrdering
          rolePermissionsEnabled
          ${companyBilling}
          titleReleasePreference
        }
        appraisals: auctionItemConnection(first:1, after:"0", consignerId: $companyId, formats:[APPRAISAL], status: [LIVE], transactionType: SELLING) { pageInfo { totalEdges } }
        buyNow: auctionItemConnection(first:1, after:"0", consignerId: $companyId, formats:[GROUNDED], status: [LIVE], transactionType: SELLING) { pageInfo { totalEdges } }
        upcoming: auctionItemConnection(first:1, after:"0", consignerId: $companyId, formats:[AUCTION, AUCTION_PHYSICAL], status: [UPCOMING], auctionTimeSlotStatus: [WAITING], transactionType: SELLING) { pageInfo { totalEdges } }
        parked: inventoryItemConnection(first:1, after:"0", archived: false, consignerId: $companyId) { pageInfo { totalEdges } }
        inIfBid: auctionItemConnection(first:1, after:"0", consignerId: $companyId, status: [IN_IF_BID]) { pageInfo { totalEdges } }
        pendingDelivery: auctionItemConnection(first:1, after:"0", consignerId: $companyId, status: [PENDING_DELIVERY]) { pageInfo { totalEdges } }
        awaitingCheckout: auctionItemConnection(first: 1, after: "0", consignerId: $companyId, status: [AWAITING_CHECKOUT], archived: false) {
          facetGroups (name:["transactionType"]) {
            name
            facets {
              count
              localizedName
              name
            }
          }
        }
        purchased: auctionItemConnection(first:1, after:"0", consignerId: $companyId, status: [SOLD], transactionType: BUYING) { pageInfo { totalEdges } }
        sold: auctionItemConnection(first:1, after:"0", consignerId: $companyId, status: [SOLD], transactionType: SELLING) { pageInfo { totalEdges } }
        noSale: auctionItemConnection(first:1, after:"0", consignerId: $companyId, status: [NO_SALE]) { pageInfo { totalEdges } }
        cancelled: auctionItemConnection(first:1, after:"0", consignerId: $companyId, status: [SALE_CANCELLED]) { pageInfo { totalEdges } }
      }
      ${repsFragment}
      ${companyIntegrationFragment}
      `,
      variables: {
        companyId,
      },
    },
  });

export const getCompanyUsers = ({ companyIds }: QuerycompanyUserConnectionArgs) =>
  ApiRequest<{ companyUserConnection: UserCompanyRelationshipConnection }>({
    data: {
      operationName: 'getCompanyUsers',
      query: `
      query ($companyIds: [String]) {
        companyUserConnection(first: 500, companyIds:$companyIds) {
          edges {
            node {
              user {
                id
                status
                firstName
                lastName
                preferredLocation {
                  city
                  regionCode
                  distanceAway
                }
                photoUrl(width: 200, height: 200)
                title
                lastLoggedIn
                distanceUnit
              }
              status
              department
              roles {
                id
                name
              }
            }
          }
        }
      }
    `,
      variables: {
        companyIds,
      },
    },
  });

export const getCompanySubsidiaries = ({ parentCompanyIds = null }: QuerycompanyConnectionArgs) =>
  ApiRequest<{ companyConnection: CompanyConnection }>({
    data: {
      query: `
      query ($parentCompanyIds: [String]) {
        companyConnection(first: 50, parentCompanyIds: $parentCompanyIds) {
          edges {
            node {
              id
              name
              parentCompany {
                id
              }
              primaryLocation {
                address1
                address2
                city
                regionCode
                country
              }
            }
          }
        }
      }
    `,
      variables: {
        parentCompanyIds,
      },
    },
  });

export const createCompany = ({
  name = null,
  legalName = null,
  parentCompanyId = null,
  type = null,
  subType = null,
  description = null,
  auctionIds = null,
}) =>
  ApiRequest<{ companyCreate: Company }>({
    data: {
      operationName: 'createCompany',
      query: `
        mutation(
          $name: String,
          $legalName: String,
          $parentCompanyId: String,
          $type: CompanyType,
          $subType: CompanySubType,
          $description: String,
          $auctionIds: [String]!
        ) {
          companyCreate(
            name: $name,
            legalName: $legalName,
            parentCompanyId: $parentCompanyId,
            type: $type,
            subType: $subType,
            description: $description,
            auctionIds: $auctionIds
          ) {
            id
            name
          }
        }
    `,
      variables: {
        name,
        legalName,
        parentCompanyId,
        type,
        subType,
        description,
        auctionIds,
      },
    },
  });

export const updateCompanyInfo = ({
  id,
  checkReleasePreference = undefined,
  description = null,
  legalName = null,
  name = null,
  parentCompanyId = null,
  rolePermissionsEnabled = null,
  status = null,
  subType = null,
  titleReleasePreference = undefined,
  type = null,
}) =>
  ApiRequest<{ companyUpdateInfo: Company }>({
    data: {
      operationName: 'updateCompanyInfo',
      query: `
      mutation(
        $id: String!,
        $checkReleasePreference: String
        $description: String,
        $legalName: String,
        $name: String,
        $parentCompanyId: String,
        $rolePermissionsEnabled: Boolean,
        $status: CompanyStatus,
        $subType: CompanySubType,
        $titleReleasePreference: String,
        $type: CompanyType
      ) {
        companyUpdateInfo(
          id: $id,
          checkReleasePreference: $checkReleasePreference,
          description: $description,
          legalName: $legalName,
          name: $name,
          parentCompanyId: $parentCompanyId,
          rolePermissionsEnabled: $rolePermissionsEnabled,
          status: $status,
          subType: $subType,
          titleReleasePreference: $titleReleasePreference,
          type: $type
        ) {
          id
          checkReleasePreference
          description
          legalName
          name
          parentCompany {
            id
            name
          }
          rolePermissionsEnabled
          status
          subType
          titleReleasePreference
          type
        }
      }
    `,
      variables: {
        id,
        checkReleasePreference,
        description,
        legalName,
        name,
        parentCompanyId,
        rolePermissionsEnabled,
        status,
        subType,
        titleReleasePreference,
        type,
      },
    },
  });

export const updateCanadianLegalInfo = ({
  companyId = null,
  auctionAccessId = null,
  dealerLicenseNumber = null,
  dealerLicenseExpiry = null,
  craHstGstNumber = null,
  ontarioRin = null,
  numeroDeDossier = null,
  skPstNumber = null,
  bcPstNumber = null,
}) =>
  ApiRequest<{ companyUpdateCanadianLegalInfo: Company }>({
    data: {
      operationName: 'companyUpdateCanadianLegalInfo',
      query: `
      mutation(
        $id: String!,
        $auctionAccessId: Int,
        $dealerLicenseNumber: String,
        $dealerLicenseExpiry: Date,
        $craHstGstNumber: String,
        $ontarioRin: String,
        $numeroDeDossier: String,
        $skPstNumber: String,
        $bcPstNumber: String
      ) {
        companyUpdateCanadianLegalInfo(
          id: $id,
          auctionAccessId: $auctionAccessId,
          dealerLicenseNumber: $dealerLicenseNumber,
          dealerLicenseExpiry: $dealerLicenseExpiry,
          craHstGstNumber: $craHstGstNumber,
          ontarioRin: $ontarioRin,
          numeroDeDossier: $numeroDeDossier,
          skPstNumber: $skPstNumber,
          bcPstNumber: $bcPstNumber
        ) {
          id
          auctionAccessInfo {
            auctionAccessId
            auctionAccessLastVerified
          }
          canadianLegalInfo {
            dealerLicenseNumber
            dealerLicenseExpiry
            craHstGstNumber
            ontarioRin
            numeroDeDossier
            skPstNumber
            bcPstNumber
            modifiable
          }
        }
      }
    `,
      variables: {
        id: companyId,
        auctionAccessId,
        dealerLicenseNumber,
        dealerLicenseExpiry,
        craHstGstNumber,
        ontarioRin,
        numeroDeDossier,
        skPstNumber,
        bcPstNumber,
      },
    },
  });

export const updateAmericanLegalInfo = ({
  id = null,
  auctionAccessId = null,
  bondAmount = null,
  bondCompany = null,
  bondExpiry = null,
  bondNumber = null,
  dealerLicenseExpiry = null,
  dealerLicenseNumber = null,
  dealerLicenseState = null,
  ein = null,
  sellerPermitNumber = null,
}) =>
  ApiRequest<{ companyUpdateAmericanLegalInfo: Company }>({
    data: {
      operationName: 'companyUpdateAmericanLegalInfo',
      query: `
      mutation(
        $id: String!,
        $auctionAccessId: Int,
        $bondAmount: Float,
        $bondCompany: String,
        $bondExpiry: Date,
        $bondNumber: String,
        $dealerLicenseExpiry: Date,
        $dealerLicenseNumber: String,
        $dealerLicenseState: String,
        $ein: String,
        $sellerPermitNumber: String,
      ) {
        companyUpdateAmericanLegalInfo(
          id: $id,
          auctionAccessId: $auctionAccessId,
          bondAmount: $bondAmount,
          bondCompany: $bondCompany,
          bondExpiry: $bondExpiry,
          bondNumber: $bondNumber,
          dealerLicenseExpiry: $dealerLicenseExpiry,
          dealerLicenseNumber: $dealerLicenseNumber,
          dealerLicenseState: $dealerLicenseState,
          ein: $ein,
          sellerPermitNumber: $sellerPermitNumber,
        ) {
          id
          americanLegalInfo {
            bondAmount {
              amount
              formattedAmount
            }
            bondCompany
            bondExpiry
            bondNumber
            dealerLicenseExpiry
            dealerLicenseNumber
            dealerLicenseState
            ein
            modifiable
            sellerPermitNumber
          }
          auctionAccessInfo {
            auctionAccessId
            auctionAccessLastVerified
          }
        }
      }
    `,
      variables: {
        id,
        auctionAccessId,
        bondAmount,
        bondCompany,
        bondExpiry,
        bondNumber,
        dealerLicenseExpiry,
        dealerLicenseNumber,
        dealerLicenseState,
        ein,
        sellerPermitNumber,
      },
    },
  });

export const updateCompanyHoldback = ({ id = null, amount, type }) =>
  ApiRequest<{ companyUpdateHoldback: Company }>({
    data: {
      operationName: 'updateCompanyHoldback',
      query: `
      mutation ($id: String!, $amount: BigDecimal, $type: AmountType ) {
        companyUpdateHoldback (
          id: $id,
          amount: $amount
          type: $type,
        ) {
          id
          holdback {
            type
            amount
          }
        }
      }
    `,
      variables: {
        id,
        amount,
        type,
      },
    },
  });

export const updateCompanyRegions = ({ companyId = null, regions }) =>
  ApiRequest<{ companyUpdateVisibleRegions: Pick<Company, 'id' | 'visibleRegions'> }>({
    data: {
      operationName: 'updateCompanyRegions',
      query: `
      mutation ($id: String!, $regions: [String]!) {
        companyUpdateVisibleRegions (
          id: $id,
          regions: $regions
        ) {
          id
          visibleRegions
        }
      }
    `,
      variables: {
        id: companyId,
        regions,
        companyIds: [companyId],
      },
    },
  });

export const updateRestrictCarfaxOrdering = ({
  id,
  restrictCarfaxCanadaOrdering,
}: MutationcompanyUpdateRestrictCarfaxOrderingArgs) =>
  ApiRequest<{ companyUpdateRestrictCarfaxOrdering: Pick<Company, 'id' | 'restrictCarfaxCanadaOrdering'> }>({
    data: {
      operationName: 'updateRestrictCarfaxOrdering',
      query: `
      mutation ($id: String!, $restrictCarfaxCanadaOrdering: Boolean!) {
        companyUpdateRestrictCarfaxOrdering (id: $id, restrictCarfaxCanadaOrdering: $restrictCarfaxCanadaOrdering) {
          id
          restrictCarfaxCanadaOrdering
        }
      }
    `,
      variables: {
        id,
        restrictCarfaxCanadaOrdering,
      },
    },
  });

export const getCompanyAuctions = ({ companyIds = null, keyword = null }: QuerycompanyAuctionConnectionArgs) =>
  ApiRequest<{ companyAuctionConnection: CompanyAuctionRelationshipConnection }>({
    data: {
      operationName: 'getCompanyAuctions',
      query: `
      query($companyIds: [String], $keyword: String) {
        companyAuctionConnection(first:500, companyIds: $companyIds, keyword: $keyword) {
          edges {
            node {
              id
              auction {
                ...auction
              }
              company {
                ...company
              }
              reps {
                ...reps
              }
              permissions {
                ...companyAuctionPermissions
              }
            }
          }
        }
      }
      ${auctionFragment}
      ${companyFragment}
      ${repsFragment}
      ${companyAuctionPermissionFragment}
      `,
      variables: {
        companyIds,
        keyword,
      },
    },
  });

export const getCompanyContacts = ({ companyId }: QuerycompanyArgs) =>
  ApiRequest<{ company: Pick<Company, 'id' | 'contacts'> }>({
    data: {
      operationName: 'getCompanyContacts',
      query: `
        query($companyId: String!) {
          company(companyId: $companyId) {
            id
            contacts {
              id
              name
              roles
              archived
              email
              phone
              locale
              created
              updated
              note
              primary
              user {
                id
                firstName
                lastName
              }
            }
          }
        }
      `,
      variables: {
        companyId,
      },
    },
  });

export const findUser = ({ keyword = null, notInCompanyId }: QueryfindUserArgs) =>
  ApiRequest<{ findUser: FindUser[] }>({
    data: {
      operationName: 'findUser',
      query: `
      query($keyword: String, $notInCompanyId: String!) {
        findUser(keyword: $keyword, notInCompanyId: $notInCompanyId) {
          id
          firstName
          lastName
          email
          companyNames
        }
      }
    `,
      variables: {
        keyword,
        notInCompanyId,
      },
    },
  });

export const findCompany = ({ keyword = null }) =>
  ApiRequest<{ findCompany: CompanySearchResult[] }>({
    data: {
      operationName: 'findCompany',
      query: `
      query($keyword: String) {
        findCompany(keyword: $keyword) {
          id
          name
          auctionAccessId
          address {
            id
            address1
            address2
            city
            region
          }
        }
      }
    `,
      variables: {
        keyword,
      },
    },
  });

export const createUser = ({
  cellPhone = null,
  companyId = null,
  email = null,
  firstName = null,
  lastName = null,
  secondaryPhone = null,
  status = null,
}: MutationuserCreateArgs) =>
  ApiRequest<{ userCreate: User }>({
    data: {
      operationName: 'createUser',
      query: `
      mutation(
        $cellPhone: String,
        $companyId: String
        $email: String,
        $firstName: String,
        $lastName: String,
        $secondaryPhone: String,
        $status: UserStatus
      ) {
        userCreate(
          cellPhone: $cellPhone,
          companyId: $companyId,
          email: $email,
          firstName: $firstName,
          lastName: $lastName,
          secondaryPhone: $secondaryPhone,
          status: $status
        ) {
          ${user({ withSavedFilterDetails: true })}
        }
      }
      ${locationFragment}
    `,
      variables: {
        cellPhone,
        companyId,
        email,
        firstName,
        lastName,
        secondaryPhone,
        status,
      },
    },
  });

export const createUserCompanyRelationship = ({
  companyId,
  department = null,
  roleIds = null,
  status = null,
  userId,
}: MutationuserCompanyRelationshipCreateArgs) =>
  ApiRequest<{ userCompanyRelationshipCreate: UserCompanyRelationship }>({
    data: {
      operationName: 'createUserCompanyRelationship',
      query: `
      mutation(
        $companyId: String!,
        $department: String,
        $roleIds: [String],
        $status: UserCompanyRelationshipStatus,
        $userId: String!
      ) {
        userCompanyRelationshipCreate(
          companyId: $companyId,
          department: $department,
          roleIds: $roleIds,
          status: $status,
          userId: $userId
        ) {
          user {
            id
          }
        }
      }
    `,
      variables: {
        companyId,
        department,
        roleIds,
        status,
        userId,
      },
    },
  });

export const updateUserCompanyRelationship = ({
  userId,
  companyId,
  status = null,
  department = null,
  roleIds = null,
}: MutationuserCompanyRelationshipUpdateArgs) =>
  ApiRequest<{ userCompanyRelationshipUpdate: UserCompanyRelationship }>({
    data: {
      operationName: 'updateUserCompanyRelationship',
      query: `
      mutation(
        $userId: String!,
        $companyId: String!,
        $status: UserCompanyRelationshipStatus,
        $department: String,
        $roleIds: [String]
      ) {
        userCompanyRelationshipUpdate(
          userId: $userId,
          companyId: $companyId,
          status: $status,
          department: $department,
          roleIds: $roleIds
        ) {
          user {
            id
          }
        }
      }
    `,
      variables: {
        userId,
        companyId,
        status,
        department,
        roleIds,
      },
    },
  });

export const companyAuctionRelationshipsCreate = ({
  companyId,
  auctionIds,
}: MutationcompanyAuctionRelationshipsCreateArgs) =>
  ApiRequest<{ companyAuctionRelationshipsCreate: Pick<Company, 'id'> }>({
    data: {
      operationName: 'companyAuctionRelationshipsCreate',
      query: `
      mutation($companyId: String!, $auctionIds: [String]!) {
        companyAuctionRelationshipsCreate(companyId: $companyId, auctionIds: $auctionIds) {
          id
        }
      }
    `,
      variables: {
        companyId,
        auctionIds,
      },
    },
  });

export const updateCompanyAuctionRelationship = ({
  activePaymentTypeIds = null,
  buyerCreditLimit = null,
  buyerShieldEnabled = null,
  companyAuctionRelationshipId,
  defaultPaymentTypeId = null,
  defaultTransportLocationId = null,
  eftEnabled = null,
  hideSellerNameEnabled = null,
  permissions = null,
  sellerAssuranceEnabled,
  sendTitlePaymentOverride = null,
  status = null,
  tier = null,
  vehicleReleasePaymentOverride = null,
}: MutationcompanyAuctionRelationshipUpdateArgs) =>
  ApiRequest<{ companyAuctionRelationshipUpdate: CompanyAuctionRelationship }>({
    data: {
      operationName: 'updateCompanyAuctionRelationship',
      query: `
        mutation (
          $activePaymentTypeIds: [String]
          $buyerCreditLimit: Int
          $buyerShieldEnabled: Boolean
          $companyAuctionRelationshipId: String!
          $defaultPaymentTypeId: String
          $defaultTransportLocationId: String
          $eftEnabled: Boolean
          $hideSellerNameEnabled: Boolean
          $permissions: [String]
          $sellerAssuranceEnabled: Boolean
          $sendTitlePaymentOverride: Boolean
          $status: CompanyAuctionRelationshipStatus
          $tier: CompanyTier
          $vehicleReleasePaymentOverride: Boolean
        ) {
          companyAuctionRelationshipUpdate(
            activePaymentTypeIds: $activePaymentTypeIds
            buyerCreditLimit: $buyerCreditLimit
            buyerShieldEnabled: $buyerShieldEnabled
            companyAuctionRelationshipId: $companyAuctionRelationshipId
            defaultPaymentTypeId: $defaultPaymentTypeId
            defaultTransportLocationId: $defaultTransportLocationId
            eftEnabled: $eftEnabled
            hideSellerNameEnabled: $hideSellerNameEnabled
            permissions: $permissions
            sellerAssuranceEnabled: $sellerAssuranceEnabled
            sendTitlePaymentOverride: $sendTitlePaymentOverride
            status: $status
            tier: $tier
            vehicleReleasePaymentOverride: $vehicleReleasePaymentOverride
          ) {
            id
            buyerCreditLimit {
              amount
              formattedAmountRounded
            }
            buyerShieldEnabled
            eftEnabled
            hideSellerNameEnabled
            defaultPaymentType {
              id
              defaultType
              logicalName
              name
            }
            defaultTransportLocation {
              id
              name
            }
            paymentTypes {
              id
              active
              defaultType
              logicalName
              name
              subType
            }
            permissions {
              id
              name
            }
            sellerAssuranceEnabled
            sendTitlePaymentOverride
            status
            tier
            vehicleReleasePaymentOverride
          }
        }
      `,
      variables: {
        activePaymentTypeIds,
        buyerCreditLimit,
        buyerShieldEnabled,
        companyAuctionRelationshipId,
        defaultPaymentTypeId,
        defaultTransportLocationId,
        eftEnabled,
        hideSellerNameEnabled,
        permissions,
        sellerAssuranceEnabled,
        sendTitlePaymentOverride,
        status,
        tier,
        vehicleReleasePaymentOverride,
      },
    },
  });

export const updateCompanyAuctionSellerNotes = ({ companyAuctionRelationshipId = null, defaultSellerNotes = null }) =>
  ApiRequest<{ companyAuctionRelationshipUpdateSellerNotes: CompanyAuctionRelationship }>({
    data: {
      query: `
      mutation($companyAuctionRelationshipId: String!, $defaultSellerNotes: String!) {
        companyAuctionRelationshipUpdateSellerNotes(
          companyAuctionRelationshipId: $companyAuctionRelationshipId,
          defaultSellerNotes: $defaultSellerNotes
        ) {
          id
          defaultSellerNotes
        }
      }
      `,
      variables: {
        companyAuctionRelationshipId,
        defaultSellerNotes,
      },
    },
  });

export const saveCompanyAuctionReps = ({ companyAuctionRelationshipId = null, reps = null }) =>
  ApiRequest<{ companyAuctionRepsSet: CompanyAuctionRelationship }>({
    data: {
      query: `
      mutation($companyAuctionRelationshipId: String!, $reps: [RepInput]!) {
        companyAuctionRepsSet(companyAuctionRelationshipId: $companyAuctionRelationshipId, reps: $reps) {
          id
        }
      }
    `,
      variables: { companyAuctionRelationshipId, reps },
    },
  });

// Legacy Consigner billing
export const updateCompanyBilling = ({
  buyFee,
  buyLienCheck,
  buyPSIEnabled,
  buyPSIFee,
  buyProcessingFee,
  buyTransportCompoundEnabled,
  buyTransportCompoundFee,
  buyTransportCompoundId,
  companyId,
  dataCaptureFee,
  dealerNumber,
  defaultSellerNotes,
  loyaltyPointsAppraisal,
  loyaltyPointsAuction,
  loyaltyPointsBuyNow,
  rin,
  sellFee,
  sellLienCheck,
  sellListingFee,
  sellPSIEnabled,
  sellPSIFee,
  sellPSIPaidBy,
  sellProcessingFee,
  sellTransportCompoundEnabled,
  sellTransportCompoundFee,
  sellTransportCompoundId,
  sellTransportCompoundPaidBy,
  taxNumber,
}: MutationcompanyBillingUpdateArgs) =>
  ApiRequest<{ companyBillingUpdate: Company }>({
    data: {
      operationName: 'companyBillingUpdate',
      query: `
      mutation(
        $buyFee: Int,
        $buyLienCheck: Boolean,
        $buyPSIEnabled: Boolean,
        $buyPSIFee: Int,
        $buyProcessingFee: Int,
        $buyTransportCompoundEnabled: Boolean,
        $buyTransportCompoundFee: Int,
        $buyTransportCompoundId: String,
        $companyId: String!,
        $dataCaptureFee: Int,
        $dealerNumber: String,
        $defaultSellerNotes: String,
        $loyaltyPointsAppraisal: Int,
        $loyaltyPointsAuction: Int,
        $loyaltyPointsBuyNow: Int,
        $rin: String,
        $sellFee: Int,
        $sellLienCheck: Boolean,
        $sellListingFee: Int,
        $sellPSIEnabled: Boolean,
        $sellPSIFee: Int,
        $sellPSIPaidBy: CompoundPayer,
        $sellProcessingFee: Int,
        $sellTransportCompoundEnabled: Boolean,
        $sellTransportCompoundFee: Int,
        $sellTransportCompoundId: String,
        $sellTransportCompoundPaidBy: CompoundPayer,
        $taxNumber: String,
      ) {
        companyBillingUpdate (
          buyFee: $buyFee,
          buyLienCheck: $buyLienCheck,
          buyPSIEnabled: $buyPSIEnabled,
          buyPSIFee: $buyPSIFee,
          buyProcessingFee: $buyProcessingFee,
          buyTransportCompoundEnabled: $buyTransportCompoundEnabled,
          buyTransportCompoundFee: $buyTransportCompoundFee,
          buyTransportCompoundId: $buyTransportCompoundId,
          companyId: $companyId,
          dataCaptureFee: $dataCaptureFee,
          dealerNumber: $dealerNumber,
          defaultSellerNotes: $defaultSellerNotes,
          loyaltyPointsAppraisal: $loyaltyPointsAppraisal,
          loyaltyPointsAuction: $loyaltyPointsAuction,
          loyaltyPointsBuyNow: $loyaltyPointsBuyNow,
          rin: $rin,
          sellFee: $sellFee,
          sellLienCheck: $sellLienCheck,
          sellListingFee: $sellListingFee,
          sellPSIEnabled: $sellPSIEnabled,
          sellPSIFee: $sellPSIFee,
          sellPSIPaidBy: $sellPSIPaidBy,
          sellProcessingFee: $sellProcessingFee,
          sellTransportCompoundEnabled: $sellTransportCompoundEnabled,
          sellTransportCompoundFee: $sellTransportCompoundFee,
          sellTransportCompoundId: $sellTransportCompoundId,
          sellTransportCompoundPaidBy: $sellTransportCompoundPaidBy,
          taxNumber: $taxNumber,
        ) {
          ${companyBilling}
        }
      }
    `,
      variables: {
        buyFee,
        buyLienCheck,
        buyPSIEnabled,
        buyPSIFee,
        buyProcessingFee,
        buyTransportCompoundEnabled,
        buyTransportCompoundFee,
        buyTransportCompoundId,
        companyId,
        dataCaptureFee,
        dealerNumber,
        defaultSellerNotes,
        loyaltyPointsAppraisal,
        loyaltyPointsAuction,
        loyaltyPointsBuyNow,
        rin,
        sellFee,
        sellLienCheck,
        sellListingFee,
        sellPSIEnabled,
        sellPSIFee,
        sellPSIPaidBy,
        sellProcessingFee,
        sellTransportCompoundEnabled,
        sellTransportCompoundFee,
        sellTransportCompoundId,
        sellTransportCompoundPaidBy,
        taxNumber,
      },
    },
  });

export const updateCredits = ({ input }: MutationcompanyUpdateCreditsArgs) =>
  ApiRequest<{ companyUpdateCredits: Company }>({
    data: {
      operationName: 'updateCredits',
      query: `
      mutation($input: CompanyCreditLogInput!) {
        companyUpdateCredits(input: $input) {
          creditsBuy {
            formattedAmount
          }
          creditsSell {
            formattedAmount
          }
          creditsLoyalty {
            formattedAmount
          }
        }
      }
    `,
      variables: {
        input,
      },
    },
  });

export type CreditLogEntry = Omit<CompanyCreditLog, 'auctionItem'> & {
  auctionItem: AuctionItem;
};
export const getCreditLog = ({
  accountingSystemId,
  after = '0',
  companyId,
  first = 50,
  type,
}: SetOptional<QuerycompanyCreditLogConnectionArgs, 'after' | 'first'>) =>
  ApiRequest<{ log: CompanyCreditLogConnection }>({
    data: {
      operationName: 'getCreditLog',
      query: `
    query($accountingSystemId: String!, $after: String!, $companyId: String!, $first: Int!, $type: AccountEnum!) {
      log: companyCreditLogConnection(
        accountingSystemId: $accountingSystemId,
        type: $type,
        after: $after,
        companyId: $companyId,
        first: $first,
      )
      {
        metadata {
          balance {
            amount
            formattedAmount
          }
        }
        edges {
          node {
            id
            auctionItem {
              id
              inventoryItem {
                ... on Vehicle {
                  year
                  make
                  model
                  mileage {
                    formattedAmount
                  }
                  primaryPhoto: photos(type: EXTERIOR, first: 1) {
                    url(width: 100, height: 100)
                  }
                  company {
                    primaryLocation {
                      city
                      regionCode
                    }
                  }
                }
              }
              transactionDetails {
                transactionNumber
              }
            }
            accountingSystem {
              id
              name
            }
            amount 
            reason
            comment
            createdByUser {
              firstName
              lastName
              photoUrl(width: 100, height: 100)
            }
            created
          }
        }
      }
    }
    `,
      variables: {
        accountingSystemId,
        after,
        companyId,
        first,
        type,
      },
    },
  });

export const getCompanyAuctionReps = ({ title = null }) =>
  ApiRequest<{ auctionConnection: AuctionConnection }>({
    data: {
      operationName: 'getCompanyAuctionReps',
      query: `
        {
          auctionConnection(first:1, title: "${title}") {
            edges {
              node {
                id
                title
                representativeTypes {
                  id
                  name
                }
                managingCompanies {
                  userRelationships {
                    list {
                      user {
                        id
                        firstName
                        lastName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
    },
  });

export const companyAuctionRepresentativeAssign = ({
  relationshipIds,
  representative,
}: MutationcompanyAuctionRepresentativeAssignArgs) =>
  ApiRequest<{ companyAuctionRepresentativeAssign: boolean }>({
    data: {
      query: `
        mutation ($relationshipIds: [String]!, $representative: RepInput!) {
          companyAuctionRepresentativeAssign(relationshipIds: $relationshipIds, representative: $representative)
        }
      `,
      variables: {
        relationshipIds,
        representative,
      },
    },
  });

export const setCompanyInvoiceSignature = ({
  companyId,
  registrationNumber,
  signature,
}: MutationcompanyInvoiceSignatureSetArgs) =>
  ApiRequest<{ companyInvoiceSignatureSet: Company }>({
    data: {
      operationName: 'setCompanyInvoiceSignature',
      query: `
      mutation (
        $companyId: String!,
        $registrationNumber: String,
        $signature: String
      ) {
        companyInvoiceSignatureSet(
          companyId: $companyId,
          registrationNumber: $registrationNumber,
          signature: $signature
        ) {
          id
          invoiceSignature {
            signatureUrl
            registrationNumber
          }
        }
      }
    `,
      variables: {
        companyId,
        registrationNumber,
        signature,
      },
    },
  });

export const getConsignerInvoiceSignatureImage = (path) =>
  axios({
    url: `${getGraphBaseURL()}${path}`,
    headers: {
      ...getDefaultHeaders(),
    },
    responseType: 'blob',
    method: 'GET',
  }).then((response) => URL.createObjectURL(response.data));
