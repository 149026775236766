import { Client, IMessage } from '@stomp/stompjs';

import { contains, PubSubSubscription } from 'io/pubsub/pubsub';
import { getAuthToken } from 'utils/authUtils';
import { getGraphBaseURL } from 'utils/apiUtils';

let client: Client;
let isRunning: boolean = false;

const config: { channels?: string[] } = { channels: undefined };
const subscriptions: [Record<string, any>, (...args: any) => void][] = [];

/**
 * Returns formatted brokerURL
 */
const getBrokerURL = (): string => {
  let baseURL: string = getGraphBaseURL();
  if (baseURL.includes('https')) {
    baseURL = baseURL.replace('https', 'wss');
  } else {
    baseURL = baseURL.replace('http', 'ws');
  }

  return `${baseURL}/notifications`;
};

/**
 * All websocket notifications go through here and are dispatched to callbacks
 * if the subscription query is fully contained in the payload.
 */
const onReceive = (message: IMessage) => {
  const event = JSON.parse(message?.body);
  subscriptions.slice().forEach((subscription) => {
    const [query, fn] = subscription;
    if (contains(event, query)) {
      fn?.(event);
    }
  });
};

/**
 * Subscribe to the configured channels.
 */
export const start = () => {
  if (!config.channels?.length) {
    return;
  }

  if (!isRunning && getAuthToken() !== null) {
    isRunning = true;

    const headers = { Authorization: `Bearer ${getAuthToken()}` };
    const brokerURL = getBrokerURL();

    client = new Client({
      brokerURL,
      connectHeaders: headers,
      onConnect: () => {
        config?.channels?.forEach((channel) => {
          client.subscribe(`/events/${channel}`, onReceive, headers);
        });
      },
      onWebSocketError: (error) => console.info('Websocket error', error),
      onStompError: (error) => console.info('STOMP error', error),
    });
    client.activate();
  }
};

/**
 * Unsubscribe from the configured channels.
 * This pub/sub utility will automatically unsubscribe whenever
 * it is no longer interested in receiving notifications.
 */
export const stop = (cb?: () => void) => {
  isRunning = false;
  client?.deactivate();

  if (cb) {
    cb();
  }
};

/**
 * Unsubscribe from the specified type of notification, constrained by the
 * supplied query object.
 */
const unsubscribe = (query: Record<any, any>, fn: (...args: any) => void) => {
  const i = subscriptions.findIndex((sub) => sub[0] === query && sub[1] === fn);
  if (i !== -1) {
    subscriptions.splice(i, 1);
    if (subscriptions.length === 0) {
      stop();
    }
  }
};

/**
 * Subscribe to notifications, constrained by the supplied query object.
 */
const subscribe = (query: Record<any, any>, fn: (...args: any) => void) => {
  subscriptions.push([query, fn]);
  if (subscriptions.length === 1) {
    start();
  }

  return {
    cancel() {
      unsubscribe(query, fn);
    },
  };
};

/**
 * Subscribe to be notified of incoming messages. The callback function fn
 * will be called when the message payload matches (fully contains) the query.
 * The returned object has a cancel() function that will cancel the subscription.
 */
export const onMessage = (query: Record<any, any>, fn: (...args: any) => void): PubSubSubscription => {
  return subscribe(query, fn);
};

/**
 * Configure PubSub with channels.
 */
export const configure = (channels: string[]) => {
  if (isRunning) {
    stop(() => {
      config.channels = channels;
      start();
    });
  } else {
    config.channels = channels;
    start();
  }
};
