import { useCallback, useMemo } from 'react';

import AuctionItem from 'constants/auctionItem';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import StatusColumn from 'components/sections/myBlock/list/status/statusColumn';
import StatusRow from 'components/sections/myBlock/list/status/statusRow';
import StatusRowCost from 'components/sections/myBlock/list/status/statusRowCost';
import { MyBlockVariant } from 'components/sections/myBlock/myBlock';
import { UserAction } from 'logging/analytics/events/userActions';
import { getGraphBaseURL } from 'utils/apiUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './statusInfo.scss';

interface Props {
  /** The auction item details. */
  auctionItem: AuctionItem;
  /** The variation of my block view. */
  variant: MyBlockVariant;
}

const StatusInfoSold = ({ auctionItem, variant }: Props) => {
  const { topOffer, transactionDetails } = auctionItem;

  /**
   * The displayed net amount for seller view.
   */
  const netAmount = useMemo(() => {
    if (!auctionItem.net) {
      return '-';
    }
    return auctionItem.net?.amount > 0 ? (
      <span className={style.green}>{auctionItem.net?.formattedAmount}</span>
    ) : (
      <span className={style.red}>({auctionItem.net?.formattedAmount})</span>
    );
  }, [auctionItem.net]);

  /**
   * Click handler for bill of sale button.
   */
  const onBillOfSaleClick = useCallback(() => {
    const billOfSaleUrl =
      variant === MyBlockVariant.BUYER ? transactionDetails?.buyBillOfSale : transactionDetails?.sellBillOfSale;
    trackUserActionWithAuctionItemAttributes(UserAction.VDP_BILL_OF_SALE_CLICK, auctionItem);
    window.open(`${getGraphBaseURL()}${billOfSaleUrl}`);
  }, [auctionItem, transactionDetails?.buyBillOfSale, transactionDetails?.sellBillOfSale, variant]);

  return (
    <div className={style.container} data-testid="status-info-sold">
      <StatusColumn>
        <StatusRow subTitle={topOffer?.amount?.formattedAmount} title={t('sale_price')} />
        {variant === MyBlockVariant.BUYER && (
          <>
            <StatusRow
              dataTestId="buy-fees-total"
              subTitle={transactionDetails?.buyFeesTotal?.formattedAmount}
              title={t('fees')}
            />
            <StatusRow
              dataTestId="buy-fee-credit"
              subTitle={transactionDetails?.buyFeeCredit?.formattedAmount}
              title={t('credits')}
            />
            <StatusRow
              dataTestId="buy-total"
              subTitle={transactionDetails?.buyTotal?.formattedAmount}
              title={t('total')}
            />
          </>
        )}
        {variant === MyBlockVariant.SELLER && (
          <>
            <StatusRow
              dataTestId="sell-fees-total"
              subTitle={transactionDetails?.sellFeesTotal?.formattedAmount}
              title={t('fees')}
            />
            <StatusRow
              dataTestId="sell-fee-credit"
              subTitle={transactionDetails?.sellFeeCredit?.formattedAmount}
              title={t('credits')}
            />
            <StatusRow
              dataTestId="sell-total"
              subTitle={transactionDetails?.sellTotal?.formattedAmount}
              title={t('total')}
            />
            <StatusRowCost auctionItem={auctionItem} dataTestId="cost-row" />
            <StatusRow dataTestId="net-amount-row" subTitle={netAmount} title={t('net')} />
          </>
        )}
      </StatusColumn>
      <div className={style.operations}>
        {((variant === MyBlockVariant.BUYER && transactionDetails?.buyBillOfSale) ||
          (variant === MyBlockVariant.SELLER && transactionDetails?.sellBillOfSale)) && (
          <OperationButton
            className={style.button}
            dataTestId="bill-of-sale-button"
            onClick={onBillOfSaleClick}
            theme="green"
          >
            {t('bill_of_sale')}
          </OperationButton>
        )}
      </div>
    </div>
  );
};

export default StatusInfoSold;
