import ApiRequest from 'store/shared/api/apiRequest';
import { AuctionItemPublic } from 'constants/auctionItem';
import {
  AuctionItemPublicConnection,
  QueryauctionItemPublicArgs,
  QuerymarketGuideConnectionArgs,
} from 'store/shared/api/graph/interfaces/types';
import { facetGroups, pageInfo } from 'store/shared/api/graph/schema/connection';
import { listItemPhotoFragment } from 'store/shared/api/graph/fragments/photos';

export const DEFAULT_SOLD_WITHIN_DAYS = 90;

export const getMarketGuideList = (options: QuerymarketGuideConnectionArgs) =>
  ApiRequest<{ marketGuideConnection: AuctionItemPublicConnection }>({
    data: {
      operationName: 'getMarketGuideList',
      query: `
        query(
          $after: String,
          $before: String,
          $bodyTypes: [String!],
          $distance: Int,
          $driveTrains: [String!],
          $exteriorColors: [String!],
          $first: Int,
          $fuelTypes: [String!],
          $last: Int,
          $latitude: Float,
          $longitude: Float,
          $make: String,
          $mileageGTE: Int,
          $mileageLTE: Int,
          $model: String,
          $options: [String!],
          $regions: [String!],
          $soldWithinDays: Int!,
          $sort: [Sort],
          $subModels: [String!],
          $transmissions: [String!],
          $vin: String,
          $yearGTE: Int,
          $yearLTE: Int,
        ) {
          marketGuideConnection(
            after: $after,
            before: $before,
            bodyTypes: $bodyTypes,
            distance: $distance,
            driveTrains: $driveTrains,
            exteriorColors: $exteriorColors,
            first: $first,
            fuelTypes: $fuelTypes,
            last: $last,
            latitude: $latitude,
            longitude: $longitude,
            make: $make,
            mileageGTE: $mileageGTE,
            mileageLTE: $mileageLTE,
            model: $model,
            options: $options,
            regions: $regions,
            soldWithinDays: $soldWithinDays,
            sort: $sort,
            subModels: $subModels,
            transmissions: $transmissions,
            vin: $vin,
            yearGTE: $yearGTE,
            yearLTE: $yearLTE,
          ) {
            edges {
              node {
                id
                asIs
                assured
                soldAmount {
                  amount
                  currencyCode
                  formattedAmount
                  formattedAmountRounded
                }
                soldDate
                vehicle {
                  autoGradeScore
                  captureType
                  conditionReport {
                    carfaxCanadaClaimAmount {
                      amount
                      currencyCode
                      formattedAmount
                      formattedAmountRounded
                    }
                    carfaxCanadaReportUrl
                    declarations(selected: true) {
                      id
                      name
                    }
                    overallConditionRating
                  }
                  extensiveVehicleConditionScore
                  location {
                    country
                    countryCode
                    distanceAway
                    region
                    regionCode
                  }
                  make
                  mileage {
                    formattedAmount
                  }
                  model
                  options(selected: true) {
                    id
                    name
                  }
                  primaryPhoto {
                    ...listItemPhotoFragment
                  }
                  trim
                  verified
                  vin
                  year
                }
              }
            }
            metadata { 
              averagePrice {
                amount
                formattedAmountRounded
              }
              highestPrice {
                amount
                formattedAmountRounded
              }
              lowestPrice {
                amount
                formattedAmountRounded
              }
            }
            ${facetGroups}
            ${pageInfo}
          }
        }
        ${listItemPhotoFragment}
      `,
      variables: options,
    },
  });

export const getMarketGuideItemDetails = (options: QueryauctionItemPublicArgs) =>
  ApiRequest<{ auctionItemPublic: AuctionItemPublic }>({
    data: {
      operationName: 'getMarketGuideItemDetails',
      query: `
        query($auctionItemId: String!) {
          auctionItemPublic(auctionItemId: $auctionItemId) {
            vehicle {
              autoGradeScoreData {
                autoGradeVehicleDamage {
                  damageCodeRecord {
                    id
                    itemName
                    damageName
                    severityName
                  }
                  photos {
                    id
                    location
                    ...photoFrag
                  }
                }
              }
              captureType
              driveTrain
              engine
              exteriorColor
              fuelType
              interiorColor
              make
              model
              numberOfDoors
              numberOfPassengers
              photos(type: EXTERIOR) {
               ...photoFrag
              }
              undercarriagePhotos: photos(type: UNDERCARRIAGE) {
               ...photoFrag
              }
              interiorPhotos: photos(type: INTERIOR) {
               ...photoFrag
              }
              damagePhotos: photos(type: DAMAGE) {
               ...photoFrag
               location
              }
              subModel
              transmission
              trim
              year
            }
          }
        }
        fragment photoFrag on Photo {
          expanded: url (width: 1200, height: 768)
        }
      `,
      variables: options,
    },
  });

export const getMarketGuideMetadata = (
  options: Pick<
    QuerymarketGuideConnectionArgs,
    'make' | 'model' | 'soldWithinDays' | 'subModels' | 'yearGTE' | 'yearLTE'
  >
) =>
  ApiRequest<{ marketGuideConnection: Pick<AuctionItemPublicConnection, 'metadata'> }>({
    data: {
      operationName: 'getMarketGuideMetadata',
      query: `
        query(
          $make: String,
          $model: String,
          $soldWithinDays: Int!,
          $subModels: [String!],
          $yearGTE: Int,
          $yearLTE: Int,
        ) {
          marketGuideConnection(
            first: 0,
            make: $make,
            model: $model,
            soldWithinDays: $soldWithinDays,
            subModels: $subModels,
            yearGTE: $yearGTE,
            yearLTE: $yearLTE,
          ) {
            metadata { 
              averagePrice {
                amount
                formattedAmountRounded
              }
              highestPrice {
                amount
                formattedAmountRounded
              }
              lowestPrice {
                amount
                formattedAmountRounded
              }
            }
          }
        }
      `,
      variables: options,
    },
  });
