import ApiRequest from 'store/shared/api/apiRequest';
import { InventoryItemConnection, QueryinventoryItemConnectionArgs } from 'store/shared/api/graph/interfaces/types';
import { facetGroups, pageInfo } from 'store/shared/api/graph/schema/connection';

export const toBooleanValue = (value) => {
  if (value === 'Any') {
    return null;
  }
  if (typeof value === 'string') {
    return value === 'true' || value === 'Yes';
  }
  if (typeof value === 'boolean') {
    return value;
  }
  return null;
};

export const getInventoryItemsList = ({
  first = null,
  after = null,
  last = null,
  before = null,
  keyword = null,
  auction = null,
  auctionLocationName = null,
  createdGTE = null,
  createdLTE = null,
  status = null,
  updatedGTE = null,
  updatedLTE = null,
  region = null,
  consigner = null,
  consignerId = null,
  salesRepId = null,
  type = null,
  archived = false,
  inspectorCaptureCompleted = null,
  latitude = null,
  longitude = null,
  distance = null,
  condition = null,
  mileageGTE = null,
  mileageLTE = null,
  yearGTE = null,
  yearLTE = null,
  makes = null,
  models = null,
  subModels = null,
  option = null,
  bodyType = null,
  driveTrain = null,
  exteriorColor = null,
  fuelType = null,
  transmission = null,
}: QueryinventoryItemConnectionArgs) =>
  ApiRequest<{ inventoryItemConnection: InventoryItemConnection }>({
    data: {
      operationName: 'getInventoryItemsList',
      query: `
        query(
          $first: Int
          $after: String
          $last: Int
          $before: String
          $keyword: String
          $auction: [String]
          $auctionLocationName: [String]
          $createdGTE: DateTime
          $createdLTE: DateTime
          $status: [VehicleStatus!]
          $updatedGTE: DateTime
          $updatedLTE: DateTime
          $region: [String]
          $consigner: [String]
          $consignerId: String
          $salesRepId: String
          $type: [String]
          $inspectorCaptureCompleted: Boolean
          $archived: Boolean
          $latitude: Float
          $longitude: Float
          $distance: Int
          $condition: [String]
          $mileageGTE: Int
          $mileageLTE: Int
          $yearGTE: Int
          $yearLTE: Int
          $makes: [String]
          $models: [String]
          $subModels: [String]
          $option: [String]
          $bodyType: [String]
          $driveTrain: [String]
          $exteriorColor: [String]
          $fuelType: [String]
          $transmission: [String]
        ) {
          inventoryItemConnection(
            first: $first
            after: $after
            last: $last
            before: $before
            keyword: $keyword
            auction: $auction
            auctionLocationName: $auctionLocationName
            createdGTE: $createdGTE
            createdLTE: $createdLTE
            status: $status
            updatedGTE: $updatedGTE
            updatedLTE: $updatedLTE
            region: $region
            consigner: $consigner
            consignerId: $consignerId
            salesRepId: $salesRepId
            type: $type
            archived: $archived
            inspectorCaptureCompleted: $inspectorCaptureCompleted
            latitude: $latitude
            longitude: $longitude
            distance: $distance
            condition: $condition
            mileageGTE: $mileageGTE
            mileageLTE: $mileageLTE
            yearGTE: $yearGTE
            yearLTE: $yearLTE
            makes: $makes
            models: $models
            subModels: $subModels
            option: $option
            bodyType: $bodyType
            driveTrain: $driveTrain
            exteriorColor: $exteriorColor
            fuelType: $fuelType
            transmission: $transmission
            limitToAvailableAuctions: true
          ) {
            edges {
              cursor
              node {
                id
                location {
                  name
                  city
                  regionCode
                  distanceAway
                }
                stockNumber
                description
                company {
                  id
                  name
                  primaryLocation {
                    regionCode
                  }
                }
                complete
                completionPercentage
                incompleteFieldMessages {
                  field
                  message
                }
                archived
                notes {
                  id
                  content
                  created
                  createdBy {
                    firstName
                    lastName
                  }
                }
                ... on Vehicle {
                  autoGradeScore
                  captureType
                  status
                  year
                  make
                  model
                  trim
                  mileage {
                    formattedAmount
                  }
                  transmission
                  displacement
                  cylinders
                  fuelType
                  photos (first: 1) {
                    ...thumbnailListFrag
                  }
                  vin
                  conditionReport {
                    carfaxCanadaClaimAmount {
                      amount
                    }
                    declarations: declarations(selected: true) {
                      name
                    }
                  }
                  vehicleBuyerShield
                }
              }
            }
            ${facetGroups}
            ${pageInfo}
          }
        }
        fragment thumbnailListFrag on Photo {
          main: url (width: 800, height: 600)
          thumb: url (width: 110, height: 110)
        }
      `,
      variables: {
        first,
        after,
        last,
        before,
        keyword,
        auction,
        auctionLocationName,
        createdGTE,
        createdLTE,
        status,
        updatedGTE,
        updatedLTE,
        region,
        consigner,
        consignerId,
        salesRepId,
        type,
        archived: toBooleanValue(archived),
        inspectorCaptureCompleted: toBooleanValue(inspectorCaptureCompleted),
        latitude,
        longitude,
        distance: distance ? Number(distance) : null,
        condition,
        mileageGTE: mileageGTE ? Number(mileageGTE) : null,
        mileageLTE: mileageLTE ? Number(mileageLTE) : null,
        yearGTE: yearGTE ? Number(yearGTE) : null,
        yearLTE: yearLTE ? Number(yearLTE) : null,
        makes,
        models,
        subModels,
        option,
        bodyType,
        driveTrain,
        exteriorColor,
        fuelType,
        transmission,
      },
    },
    seqKey: 'inventoryItemsList',
  });
