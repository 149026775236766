import ApiRequest from 'store/shared/api/apiRequest';
import { Auction, AuctionSettingsUpdateInput } from 'store/shared/api/graph/interfaces/types';
import { auctionSettingsFragment } from 'store/shared/api/graph/fragments/auctionSettings';

export const auctionSettingsUpdate = (input: AuctionSettingsUpdateInput) =>
  ApiRequest<{ auctionSettingsUpdate: Auction }>({
    data: {
      operationName: 'auctionSettingsUpdate',
      query: `
        mutation ($input: AuctionSettingsUpdateInput!) {
          auctionSettingsUpdate(input: $input) {
            id
            auctionSettings {
              ...auctionSettings
            }
            lanes {
              defaultActivated
              id
              name
              type
            }
          }
        }
        ${auctionSettingsFragment}
      `,
      variables: { input },
    },
  });
