import classnames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';

import defaultVehicleGlyph from 'glyphs/default-vehicle.svg';
import editNoteGlyph from 'glyphs/edit-note.svg';
import expandGlyph from 'glyphs/expand.svg';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import Header from './header';
import IconButton from 'components/ui/shared/buttons/iconButton';
import InventoryItemNotes from 'components/sections/inventoryItem/details/inventoryItemNotes';
import Photo from 'constants/photo';
import Sprite from 'components/ui/shared/sprite';
import User from 'constants/user';
import { AppDispatch } from 'store/configureStore';
import {
  AsIsBadge,
  AssuredBadge,
  AutoBidBadge,
  Badges,
  BuyerShieldBadge,
  NoteBadge,
  UnreservedBadge,
  VerifiedBadge,
  WarningBadge,
  WatchedBadge,
} from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import {
  AuctionItemStatus,
  LiveLane,
  MutationauctionItemSetWatchingArgs,
} from 'store/shared/api/graph/interfaces/types';
import { Case, Switch } from 'components/ui/shared/directives/switch';
import {
  HeroForehead,
  HeroForeheadAwaitingCheckout,
  HeroForeheadInIfBid,
  HeroForeheadLiveAppraisal,
  HeroForeheadLiveAuction,
  HeroForeheadLiveBuyNow,
  HeroForeheadProps,
  HeroForeheadUpcomingPhysical,
} from './heroForehead';
import { LooseObject } from 'constants/objects';
import { PaneIndex } from 'components/sections/inventoryItem/addModify/vehicleForm';
import { SectionNavigation } from 'components/sections/inventoryItem/addModify/interfaces/vehicleForm';
import { UserAction } from 'logging/analytics/events/userActions';
import { getAutoGradePhotos } from 'utils/formatting/autoGradeFormatUtils';
import { hasCarfaxClaimOrDeclarations } from 'utils/inventoryItemUtils';
import {
  isLiveAppraisal,
  isLiveAuction,
  isLiveBuyNow,
  isLiveTimedOffer,
  isUpcomingManual,
  isVerified,
  isVerifiedAutoGrade,
} from 'utils/auctionItemUtils';
import { processSetAuctionItemWatching } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './hero.scss';

const scrollTop = () => {
  setTimeout(() => {
    const noteContainer = document.getElementById('noteList');
    if (noteContainer) {
      if (noteContainer.scrollTop) {
        noteContainer.scrollTop = 0;
      }
    }
  });
};

const dispatchConnect = (dispatch: AppDispatch) => ({
  setWatch: (options: MutationauctionItemSetWatchingArgs) => processSetAuctionItemWatching(options, dispatch),
});

const connector = connect(undefined, dispatchConnect);

interface Props extends ConnectedProps<typeof connector> {
  /** Auction item details information. */
  auctionItemDetails: AuctionItemDetailsProps;

  /** CSS style to override default style. */
  className?: string;

  /** Callback function to handle feature clicked event. */
  handleFeatureClicked: (featureType: string, inventoryDetailsData: LooseObject) => void;

  /** Whether is updating or not. */
  isUpdating: boolean;

  /** Live lane information. */
  liveLane?: LiveLane;

  /** Callback function to open add modify modal. */
  openAddModifyModal: (section: SectionNavigation['section'], paneIndex: SectionNavigation['paneIndex']) => void;

  /** Time offset. */
  timeOffset: number;

  /** Current user. */
  user: User;
}

interface State {
  /** Whether is image loaded or not. */
  isImageLoaded: boolean;
  /** Whether is notes visible or not. */
  isNotesVisible: boolean;
  /** Whether is watch disabled or not. */
  isWatchDisabled: boolean;
}

class AuctionItemDetailsHero extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isImageLoaded: false,
      isNotesVisible: !!props?.auctionItemDetails?.inventoryItem?.notes?.length,
      isWatchDisabled: false,
    };

    this.loadHeroImage(props?.auctionItemDetails?.inventoryItem?.photos?.[0]?.main);
  }

  componentDidUpdate(prevProps: Props) {
    const { id, inventoryItem } = prevProps.auctionItemDetails;
    const { id: idNext, inventoryItem: inventoryItemNext } = this.props.auctionItemDetails;
    const heroUrl = inventoryItem?.photos?.[0]?.main;
    const heroUrlNext = inventoryItemNext?.photos?.[0]?.main;

    if (id !== idNext) {
      if (inventoryItemNext?.notes) {
        this.setState({ isNotesVisible: !!inventoryItemNext.notes?.length });
        scrollTop();
      }
    }

    if (heroUrl !== heroUrlNext) {
      this.loadHeroImage(heroUrlNext);
    }
  }

  loadHeroImage(photoUrl) {
    this.setState({ isImageLoaded: false });

    if (photoUrl) {
      const heroImg = new Image();
      heroImg.src = photoUrl;
      heroImg.onload = () => {
        if (this._isMounted) {
          this.setState({ isImageLoaded: true });
        }
      };
    }
  }

  openImage = (photoUrl, photoCollection) => {
    const { handleFeatureClicked } = this.props;
    const activePhotoIndex = photoCollection.findIndex(
      (photo) => photoUrl === photo.main || photoUrl === photo.expanded
    );

    handleFeatureClicked('PHOTOS', {
      photos: photoCollection,
      scrollIndex: activePhotoIndex,
    });
  };

  toggleNotesVisibility = () => {
    if (!this.state.isNotesVisible) {
      trackUserActionWithAuctionItemAttributes(UserAction.INTERNAL_NOTES_CLICK, this.props?.auctionItemDetails);
    }
    this.setState((prevState) => ({ isNotesVisible: !prevState.isNotesVisible }));
  };

  toggleWatch = () => {
    if (this.state.isWatchDisabled) {
      return;
    }

    this.setState({ isWatchDisabled: true });
    this.props
      .setWatch({
        auctionItemId: this.props.auctionItemDetails.id,
        watching: !this.props.auctionItemDetails.watchers?.isWatched,
      })
      ?.then(() => {
        trackUserActionWithAuctionItemAttributes(UserAction.VDP_ADD_TO_WATCHLIST_CLICK, this.props?.auctionItemDetails);
      })
      ?.finally(() => this.setState({ isWatchDisabled: false }));
  };

  render() {
    const {
      isUpdating,
      auctionItemDetails,
      auctionItemDetails: {
        asIs,
        isAssured,
        isMyItem,
        autoBids,
        reserveMet,
        colorScheme,
        timerText,
        watchers,
        inventoryItem: {
          autoGradeScoreData,
          captureType,
          company,
          conditionReport,
          damagePhotos,
          id: inventoryItemId,
          interiorPhotos = [],
          isUpdatable,
          mileage,
          photos: exteriorPhotos = [],
          undercarriagePhotos = [],
          vehicleBuyerShield,
          vin,
        },
        status,
      },
      className,
      liveLane,
      user,
      timeOffset,
      openAddModifyModal,
    } = this.props;
    const { isNotesVisible, isImageLoaded } = this.state;

    const companyName = company?.name;
    const isWatched = !!watchers?.isWatched;
    let formattedDamagePhotos;
    if (isVerifiedAutoGrade(captureType)) {
      formattedDamagePhotos = getAutoGradePhotos(autoGradeScoreData);
    } else {
      formattedDamagePhotos = damagePhotos?.map((photo) => ({ ...photo, isDamagePhoto: true })) || [];
    }
    const photoCollection = [...formattedDamagePhotos, ...exteriorPhotos, ...undercarriagePhotos, ...interiorPhotos];
    const featurePhoto = exteriorPhotos.length && exteriorPhotos[0];
    const featurePhotoUrl = featurePhoto && featurePhoto.main;
    const featurePhotoLoaded = featurePhotoUrl && isImageLoaded && !isUpdating;
    const maxThumbnails = isUpdatable ? 10 : 11;
    const thumbnailCollection: Photo[] = [
      ...formattedDamagePhotos,
      ...exteriorPhotos.slice(1),
      ...undercarriagePhotos,
      ...interiorPhotos,
    ];
    const hasAdditionalThumbnails = thumbnailCollection.length > maxThumbnails;
    const hasAutobids = !!autoBids && !!autoBids.myAutoBid;
    const hasNotes = Number(auctionItemDetails?.inventoryItem?.notes?.length) > 0;
    const noTimerStatuses = [
      AuctionItemStatus.PENDING_DELIVERY,
      AuctionItemStatus.SOLD,
      AuctionItemStatus.NO_SALE,
      AuctionItemStatus.SALE_CANCELLED,
    ];
    const showTimerText = noTimerStatuses.includes(status) && !!timerText;

    const hasDeclarations = hasCarfaxClaimOrDeclarations({
      carfaxCanadaClaimAmount: conditionReport?.carfaxCanadaClaimAmount,
      declarations: conditionReport?.activeDeclarations,
    });

    let heroForeheadTheme: HeroForeheadProps['theme'] = 'green';
    if (colorScheme) {
      heroForeheadTheme = colorScheme.toLowerCase() as HeroForeheadProps['theme'];
    }

    return [
      <div
        key="hero-container"
        className={classnames(style.container, style.hero, className)}
        data-testid="hero-container"
      >
        <Switch>
          <Case if={isLiveAuction(auctionItemDetails) && !!liveLane}>
            <HeroForeheadLiveAuction liveLane={liveLane} timeOffset={timeOffset} user={user} />
          </Case>
          <Case if={isLiveAppraisal(auctionItemDetails)}>
            <HeroForeheadLiveAppraisal details={auctionItemDetails} timeOffset={timeOffset} user={user} />
          </Case>
          <Case if={isLiveBuyNow(auctionItemDetails) || isLiveTimedOffer(auctionItemDetails)}>
            <HeroForeheadLiveBuyNow details={auctionItemDetails} timeOffset={timeOffset} user={user} />
          </Case>
          <Case if={auctionItemDetails.status === AuctionItemStatus.IN_IF_BID && !!auctionItemDetails.ifBidTimeline}>
            <HeroForeheadInIfBid details={auctionItemDetails} timeOffset={timeOffset} />
          </Case>
          <Case if={auctionItemDetails.status === AuctionItemStatus.AWAITING_CHECKOUT}>
            <HeroForeheadAwaitingCheckout details={auctionItemDetails} timeOffset={timeOffset} />
          </Case>
          <Case if={isUpcomingManual(auctionItemDetails)}>
            <HeroForeheadUpcomingPhysical details={auctionItemDetails} user={user} />
          </Case>
          <Case if={showTimerText}>
            <HeroForehead theme={heroForeheadTheme} timerText={timerText || undefined} />
          </Case>
        </Switch>
        <div className={style.heroInner}>
          <div className={classnames(style.photoContainer)}>
            <Sprite className={style.defaultVehicleIcon} glyph={defaultVehicleGlyph} />
            {!!featurePhotoLoaded && (
              <Button
                className={style.photo}
                onClick={() => this.openImage(featurePhotoUrl, photoCollection)}
                style={{ backgroundImage: `url(${featurePhotoUrl})` }}
                theme="none"
              >
                &nbsp;
              </Button>
            )}
          </div>
          <div className={style.content}>
            <Header
              details={auctionItemDetails}
              isCompanyDetailsHidden={isNotesVisible}
              toggleWatch={this.toggleWatch}
              user={user}
            />
            <footer className={style.footer}>
              <div className={style.badgesDetails}>
                <Badges className={style.badges}>
                  {asIs && <AsIsBadge />}
                  {isAssured && <AssuredBadge />}
                  {!!vehicleBuyerShield && <BuyerShieldBadge />}
                  {isVerified(captureType) && <VerifiedBadge />}
                  {hasAutobids && <AutoBidBadge />}
                  {isWatched && <WatchedBadge />}
                  {hasNotes && <NoteBadge />}
                  {hasDeclarations && <WarningBadge />}
                  {reserveMet && <UnreservedBadge />}
                </Badges>
                {!isMyItem && !auctionItemDetails?.hideSellerNameEnabled && (
                  <div className={style.badgesSubtitle} data-testid="hero-company-name">
                    {companyName}
                  </div>
                )}
              </div>
              {!isNotesVisible && (
                <div className={style.buttonContainer}>
                  <div className={style.vehicleInfo}>
                    {mileage && <h3 className={style.mileage}>{mileage.formattedAmount}</h3>}
                    <div className={style.subtitle}>{vin}</div>
                  </div>
                  <div className={style.rightButtonGroup}>
                    <IconButton
                      className={classnames(
                        style.button,
                        auctionItemDetails?.inventoryItem?.notes?.length && style.notesAvailable
                      )}
                      dataTestId="edit-notes-button"
                      glyph={editNoteGlyph}
                      onClick={this.toggleNotesVisibility}
                      title={t('notes')}
                    />
                  </div>
                </div>
              )}
            </footer>
          </div>
        </div>
        {auctionItemDetails?.inventoryItem?.notes && isNotesVisible && (
          <InventoryItemNotes
            inventoryItemId={auctionItemDetails.inventoryItem.id}
            isAuctionItem
            notes={auctionItemDetails.inventoryItem.notes}
            onClose={this.toggleNotesVisibility}
          />
        )}
      </div>,
      !!thumbnailCollection && (
        <div key="hero-thumbnails-container" className={classnames(style.container, style.thumbnailsContainer)}>
          <ul
            className={classnames(
              style.thumbnailList,
              thumbnailCollection.length >= maxThumbnails && style.justifyThumbnails
            )}
          >
            {thumbnailCollection.slice(0, maxThumbnails).map((thumbnail, index) => {
              const key = `${inventoryItemId}-${thumbnail.thumb}-${index}`;
              return (
                <li key={key} className={style.thumbnailListItem}>
                  <Button
                    className={classnames(style.thumbnail, thumbnail.isDamagePhoto && style.isDamagePhoto)}
                    dataTestId={`${thumbnail.thumb}-${index}`}
                    onClick={() => this.openImage(thumbnail.main, photoCollection)}
                    style={{ backgroundImage: `url('${thumbnail.thumb}')` }}
                    theme="none"
                  >
                    &nbsp;
                    {index === maxThumbnails - 1 && hasAdditionalThumbnails && (
                      <span>+{thumbnailCollection.length - (maxThumbnails - 1)}</span>
                    )}
                  </Button>
                </li>
              );
            })}
            {isUpdatable && (
              <li className={style.thumbnailListItem}>
                <Button
                  className={classnames(style.thumbnail, style.addPhoto)}
                  dataTestId="add-photo-button"
                  onClick={() => openAddModifyModal('photos', PaneIndex.PHOTOS)}
                  theme="none"
                >
                  <span>
                    <Sprite glyph={expandGlyph} />
                  </span>
                </Button>
              </li>
            )}
          </ul>
        </div>
      ),
    ];
  }
}

export default connector(AuctionItemDetailsHero);
