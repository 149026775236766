import classnames from 'classnames';
import { ITooltip, Tooltip as ReactTooltip } from 'react-tooltip';
import { ReactElement, ReactNode, cloneElement, memo, useId } from 'react';
import { RequireAtLeastOne } from 'type-fest';

import Portal from 'components/ui/shared/dialogs/portal';

import 'react-tooltip/dist/react-tooltip.css';

import style from './tooltip.scss';

interface Props {
  /** Id reference from the element that the tooltip will be positioned around */
  anchorId?: string;
  /** The child elements to be rendered. */
  children?: ReactNode;
  /** CSS styling to overwrite default style. */
  className?: string;
  /** Tooltip show will be delayed in milliseconds by the amount of value */
  delayShow?: number;
  /** Tooltip hide will be delayed in milliseconds by the amount of value */
  delayHide?: number;
  /** Space in pixels between the tooltip element and anchor element (arrow not included in calculation) */
  offset?: number;
  /** The portal id prefix. */
  portalIdPrefix?: string;
  /** Position of the tooltip relative to the trigger. */
  position?: ITooltip['place'];
  /** True to show tooltip */
  showTooltip?: boolean;
  /** The element whose mouse events show/hide the tooltip. */
  trigger?: ReactElement;
}

interface TooltipChildrenProps {
  /** The child elements to be rendered. */
  children?: ReactNode;
  /** CSS styling to overwrite default style. */
  className?: string;
}

export const TooltipTitle = ({ className, children }: TooltipChildrenProps) => (
  <div className={classnames(style.title, className)}>{children}</div>
);

export const TooltipDescription = ({ className, children }: TooltipChildrenProps) => (
  <div className={classnames(style.description, className)}>{children}</div>
);

const Tooltip = ({
  anchorId,
  children,
  className,
  portalIdPrefix,
  position = 'top',
  showTooltip = true,
  trigger,
  ...props
}: RequireAtLeastOne<Props, 'anchorId' | 'trigger'>) => {
  const generatedId = useId();
  const id = anchorId || generatedId;

  if (!showTooltip) {
    return null;
  }

  return (
    <>
      {trigger && cloneElement(trigger, { 'data-tooltip-id': id })}
      <Portal portalId={`${portalIdPrefix || 'tooltip'}-${id}`} portalRootId="tooltip-root">
        <ReactTooltip
          {...props}
          className={classnames(style.container, className)}
          clickable
          id={id}
          place={position}
          variant="info"
        >
          {children}
        </ReactTooltip>
      </Portal>
    </>
  );
};

export default memo(Tooltip);
