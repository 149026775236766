export const companyBilling = `
  buyFee {
    formattedAmountRounded
    amount
  }
  buyProcessingFee {
    formattedAmountRounded
    amount
  }
  buyTransportCompound {
    id
    name
  }
  buyTransportCompoundFee {
    formattedAmountRounded
    amount
  }
  buyTransportCompoundEnabled
  buyPSIEnabled
  buyPSIFee {
    formattedAmountRounded
    amount
  }
  buyLienCheck
  defaultSellerNotes
  dataCaptureFee {
    formattedAmountRounded
    amount
  }
  sellListingFee {
    formattedAmountRounded
    amount
  }
  sellFee {
    formattedAmountRounded
    amount
  }
  sellProcessingFee {
    formattedAmountRounded
    amount
  }
  sellTransportCompound {
    id
    name
  }
  sellTransportCompoundFee {
    formattedAmountRounded
    amount
  }
  sellTransportCompoundEnabled
  sellTransportCompoundPaidBy
  sellPSIEnabled
  sellPSIPaidBy
  sellPSIFee {
    formattedAmountRounded
    amount
  }
  sellLienCheck
`;
