import { createAction } from 'redux-actions';

import { AppDispatch } from 'store/configureStore';
import {
  MutationauctionServiceDefaultSetArgs,
  MutationcompanyAuctionRelationshipUpdateArgs,
  MutationcompanyInvoiceSignatureSetArgs,
  MutationcompanyUpdateCreditsArgs,
} from 'store/shared/api/graph/interfaces/types';
import { auctionServiceDefaultSet } from 'store/shared/api/graph/mutations/auctionServices';
import {
  companyCreateIntegration,
  companyUpdateIntegration,
} from 'store/shared/api/graph/mutations/companyIntegrations';
import {
  getCompanyDetails,
  setCompanyInvoiceSignature,
  updateAmericanLegalInfo,
  updateCanadianLegalInfo,
  updateCompanyAuctionRelationship,
  updateCompanyAuctionSellerNotes,
  updateCompanyBilling,
  updateCompanyHoldback,
  updateCompanyInfo,
  updateCompanyRegions,
  updateCredits,
  updateRestrictCarfaxOrdering,
} from './companyDetailsApi';
import { getErrors } from 'utils/apiUtils';

export const companyAuctionRelationshipUpdated = createAction(
  'ADMIN_COMPANY_DETAILS_COMPANY_AUCTION_RELATIONSHIP_UPDATED'
);
export const companyDetailsCleared = createAction('ADMIN_COMPANY_DETAILS_CLEARED');
export const companyDetailsError = createAction('ADMIN_COMPANY_DETAILS_ERROR');
export const companyDetailsIsLoading = createAction('ADMIN_COMPANY_DETAILS_LOADING');
export const companyDetailsLoaded = createAction('ADMIN_COMPANY_DETAILS_LOADED');
export const companyDetailsUpdated = createAction('ADMIN_COMPANY_DETAILS_UPDATED');
export const companyDetailsUpdating = createAction('ADMIN_COMPANY_DETAILS_UPDATING');
export const preloadCompanyItem = createAction('ADMIN_COMPANY_DETAILS_PRELOADED');
export const setCompanyData = createAction('ADMIN_COMPANY_DETAILS_SETTING_DATA');
export const updateCreditsLog = createAction('ADMIN_COMPANY_DETAILS_UPDATE_CREDITS_LOG');
export const updateCompanySignature = createAction('ADMIN_COMPANY_DETAILS_SIGNATURE_UPDATED');

// Links
export const integrationsUpdated = createAction('ADMIN_COMPANY_DETAILS_INTEGRATIONS_UPDATED');

export const processGetCompanyDetails = (options, dispatch) => {
  dispatch(companyDetailsIsLoading(true));
  return getCompanyDetails(options)
    .then((response) => dispatch(companyDetailsLoaded(response?.data?.data)))
    .catch((err) => {
      dispatch(companyDetailsIsLoading(false));
      if (err.response) {
        dispatch(companyDetailsError(getErrors(err)));
      } else {
        dispatch(companyDetailsError(err.message));
      }
    });
};

export const processUpdateCompanyInfo = (options, dispatch) => {
  return updateCompanyInfo(options).then((response) => {
    const company = response?.data?.data?.companyUpdateInfo;
    dispatch(companyDetailsUpdated(company));
  });
};

export const processUpdateCanadianLegalInfo = (options, dispatch) => {
  dispatch(companyDetailsUpdating());
  return updateCanadianLegalInfo(options).then((response) => {
    const company = response?.data?.data?.companyUpdateCanadianLegalInfo;
    dispatch(companyDetailsUpdated(company));
  });
};

export const processUpdateAmericanLegalInfo = (options, dispatch) => {
  dispatch(companyDetailsUpdating());
  return updateAmericanLegalInfo(options).then((response) => {
    const company = response?.data?.data?.companyUpdateAmericanLegalInfo;
    dispatch(companyDetailsUpdated(company));
  });
};

export const processUpdateHoldback = (options, dispatch) => {
  dispatch(companyDetailsUpdating());
  return updateCompanyHoldback(options).then((response) => {
    const company = response?.data?.data?.companyUpdateHoldback;
    dispatch(companyDetailsUpdated(company));
  });
};

export const processUpdateOperatingRegion = (options, dispatch) => {
  dispatch(companyDetailsUpdating());
  return updateCompanyRegions(options).then((response) => {
    const company = response?.data?.data?.companyUpdateVisibleRegions;
    dispatch(companyDetailsUpdated(company));
  });
};

export const processUpdateCarfaxPermissions = (options, dispatch) => {
  dispatch(companyDetailsUpdating());
  return updateRestrictCarfaxOrdering(options).then((response) => {
    const company = response?.data?.data?.companyUpdateRestrictCarfaxOrdering;
    dispatch(companyDetailsUpdated(company));
  });
};

export const processSaveCompanyIntegration = (options, dispatch) => {
  const isAdding = options?.companyId;
  const saveFunc = isAdding ? companyCreateIntegration : companyUpdateIntegration;
  const integrationName = isAdding ? 'companyCreateIntegration' : 'companyUpdateIntegration';

  return saveFunc(options).then(
    (response: Awaited<ReturnType<typeof companyCreateIntegration | typeof companyUpdateIntegration>>) => {
      dispatch(integrationsUpdated(response?.data?.data?.[integrationName]));
      return response;
    }
  );
};

export const processUpdateCompanyAuctionRelationship = (
  options: MutationcompanyAuctionRelationshipUpdateArgs,
  dispatch: AppDispatch
) => {
  return updateCompanyAuctionRelationship(options).then((response) => {
    dispatch(companyAuctionRelationshipUpdated(response?.data?.data?.companyAuctionRelationshipUpdate));
  });
};

export const processUpdateCompanyAuctionSellerNotes = (options, dispatch) => {
  return updateCompanyAuctionSellerNotes(options).then((response) => {
    dispatch(companyAuctionRelationshipUpdated(response?.data?.data?.companyAuctionRelationshipUpdateSellerNotes));
  });
};

export const processSaveCompanyBillingInfo = (options, dispatch) => {
  return updateCompanyBilling(options).then((response) => {
    const company = response?.data?.data?.companyBillingUpdate;
    dispatch(companyDetailsUpdated(company));
    return response;
  });
};

export const processUpdateCredits = async (options: MutationcompanyUpdateCreditsArgs, dispatch: AppDispatch) => {
  return updateCredits(options).then((response) => {
    const company = response?.data?.data?.companyUpdateCredits;
    dispatch(companyDetailsUpdated(company));
    return response;
  });
};

export const processSetCompanyInvoiceSignature = async (
  options: MutationcompanyInvoiceSignatureSetArgs,
  dispatch: AppDispatch
) => {
  return setCompanyInvoiceSignature(options).then((response) => {
    dispatch(updateCompanySignature(response?.data?.data?.companyInvoiceSignatureSet));
    return response;
  });
};

export const processUpdateAuctionServiceDefaults = (
  options: MutationauctionServiceDefaultSetArgs,
  dispatch: AppDispatch
) => {
  return auctionServiceDefaultSet(options).then((response) => {
    dispatch(companyAuctionRelationshipUpdated(response?.data?.data?.auctionServiceDefaultSet));
  });
};
