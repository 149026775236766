import { handleActions } from 'redux-actions';
import { merge } from 'lodash-es';

import { LiveLaneResultModel, LiveLanesInitialState } from './liveLanesModels';
import { VideoStreamConfig } from 'store/shared/api/graph/interfaces/types';
import {
  activeLanesChanged,
  activeTimeSlotIdChanged,
  clearUpcomingItemsSearch,
  getAuctionTimeSlots,
  isLoading,
  isLoadingUpcomingItems,
  isNotLoading,
  liveLaneEnded,
  liveLaneInit,
  liveLanesCleared,
  liveLanesLoaded,
  pinPreviewItem,
  searchUpcomingItems,
  setAuctionVideoStreamConfigs,
  setNextAuctions,
  updateLiveLane,
  updateLiveLaneUpcomingItems,
} from './liveLanesActions';
import { auctionItemDetailsLoaded } from '../auctionItemDetails/auctionItemDetailsActions';
import { isLatestRequestSequence, parseQueryConnectionResponse } from 'utils/apiUtils';
import { removeDuplicateIds } from 'utils/arrayUtils';

export const liveLanesReducer = handleActions(
  {
    [isLoading().type]: (state) => state.setLoading(),

    [isNotLoading().type]: (state) => state.unsetLoading(),

    [isLoadingUpcomingItems().type]: (state, action) => state.set('isLoadingUpcomingItems', !!action?.payload),

    [liveLanesLoaded().type]: (state, action) => {
      const liveLanes = action.payload ?? [];
      const timeSlots = getAuctionTimeSlots(liveLanes);

      return state.setLoaded().set('resultList', liveLanes).set('timeSlots', timeSlots);
    },

    [liveLanesCleared().type]: () => new LiveLanesInitialState(),

    [updateLiveLane().type]: (state, action) => {
      const laneNext = action.payload;
      const laneIndex = state.resultList.findIndex((item) => item.id === laneNext.id);

      if (laneIndex === -1) {
        // No such lane
      } else {
        const existingItem = state.resultList[laneIndex];

        // The current live item should not be in the watch list.
        existingItem.auctionItemIdsWatching =
          existingItem.auctionItemIdsWatching?.filter((id) => id !== laneNext.liveItem?.id) ?? [];

        // The current live item should not be in the upcoming list.
        existingItem.upcoming = existingItem.upcoming?.filter((item) => item.id !== laneNext.liveItem?.id) || [];

        const nextItemIndex = existingItem.upcoming?.findIndex((item) => item.id === laneNext.nextItemId) ?? -1;
        const nextSearchItemIndex =
          existingItem.upcomingSearchResults?.findIndex((item) => item.id === laneNext.nextItemId) ?? -1;

        if (existingItem.upcoming) {
          if (nextItemIndex > -1) {
            // Filter out all items that have a runNumber less than the next live item
            existingItem.upcoming = existingItem.upcoming.filter(
              (item) => Number(item?.runNumber) >= Number(existingItem.upcoming?.[nextItemIndex]?.runNumber)
            );
          } else if (nextItemIndex === -1 && existingItem.upcoming?.length === 1) {
            // Empty upcoming
            existingItem.upcoming = [];
            existingItem.upcomingTotal = 0;
            existingItem.upcomingTotalLoaded = 0;
          }
        }

        if (existingItem.upcomingSearchResults) {
          if (nextSearchItemIndex !== -1) {
            // Filter out all items that have a runNumber less than the next live item
            existingItem.upcomingSearchResults = existingItem.upcomingSearchResults?.filter(
              (item) =>
                Number(item?.runNumber) >= Number(existingItem.upcomingSearchResults?.[nextSearchItemIndex]?.runNumber)
            );
          } else if (nextSearchItemIndex === -1) {
            if (nextItemIndex > -1) {
              // Filter out all items that have a runNumber less than the next live item
              // The `nextItemIndex` in `upcoming` is referenced since some items will be filtered out from search results
              existingItem.upcomingSearchResults = existingItem.upcomingSearchResults?.filter(
                (item) => Number(item?.runNumber) >= Number(existingItem.upcoming?.[nextItemIndex]?.runNumber)
              );
            }

            // Finally, ensure the current live item is excluded from the upcoming search results
            existingItem.upcomingSearchResults =
              existingItem.upcomingSearchResults?.filter((item) => item.id !== laneNext.liveItem?.id) || [];
          }
        }

        /**
         * Need to get rid of the existing liveItem. If not, and the incoming lane
         * has no liveItem then the old liveItem will get merged. Then when a subsequent
         * liveItem comes in it will merge with the old one. If the old one had bids
         * and the new one does not then the old bids will survive the merge.
         */
        delete existingItem.liveItem;

        // `LiveLane.type` is returned as `laneType` from PubSub. We need to re-map it to the original query response.
        laneNext.type = laneNext.laneType!;
        delete laneNext.laneType;

        const updatedItem = merge({}, existingItem, laneNext);
        const resultsUpdated = [...state.resultList];
        resultsUpdated[laneIndex] = updatedItem;

        return state.set('resultList', resultsUpdated);
      }

      return state;
    },

    [liveLaneInit().type]: (state, action) => {
      const lane = { ...action?.payload, liveItem: null };
      const lanes = state.resultList;
      const timeSlots = state.timeSlots;

      // `LiveLane.type` is returned as `laneType` from PubSub. We need to re-map it to the original query response.
      lane.type = lane.laneType;
      delete lane.laneType;

      // Filter lanes by name, since incoming INIT'd lanes from PubSub aren't guaranteed to come in order
      const lanesNext = [...lanes, lane]?.sort((a, b) => a?.name?.localeCompare(b?.name));

      // If a timeSlotId doesn't already exist, create one; otherwise return the existing timeSlots
      const timeSlotIndex = timeSlots?.findIndex((timeSlot) => timeSlot?.timeSlotId === lane?.timeSlotId);
      const timeSlotsNext = timeSlotIndex === -1 ? getAuctionTimeSlots(lanesNext) : timeSlots;

      return state.setLoaded().set('resultList', lanesNext).set('timeSlots', timeSlotsNext);
    },

    [liveLaneEnded().type]: (state, action) => {
      const { id, timeSlotId } = action.payload;
      let lanes = state.resultList;
      let timeSlots = state.timeSlots;
      let activeTimeSlotId = state.activeTimeSlotId;

      /**
       * It appears that not all users see all lanes. But all users do seem to
       * get events for all lanes, so it's possible to get notified of an
       * "invisible" lane ending. If this happens, then no-op it.
       */
      const endedLaneIndex = lanes.findIndex((lane) => lane.id === id);
      if (endedLaneIndex === -1) {
        return state;
      }

      lanes = lanes.filter((lane) => lane.id !== id);
      if (!lanes.find((lane) => lane.timeSlotId === timeSlotId)) {
        timeSlots = timeSlots.filter((timeSlot) => timeSlot.timeSlotId !== timeSlotId);

        if (timeSlotId === activeTimeSlotId) {
          activeTimeSlotId = timeSlots?.[0]?.timeSlotId;
        }
      }

      return state.set('resultList', lanes).set('timeSlots', timeSlots).set('activeTimeSlotId', activeTimeSlotId);
    },

    [updateLiveLaneUpcomingItems().type]: (state, action) => {
      const liveLanes = state?.resultList;
      const upcomingItemsRaw = action?.payload?.lane;
      const laneId = action?.payload?.auctionTimeSlotLaneId;
      const laneIndex = state?.resultList?.findIndex((item) => item.id === laneId);
      const upcomingItems = parseQueryConnectionResponse(upcomingItemsRaw) || [];
      let upcomingItemsFormatted;

      if (action?.payload?.isFetchingMore) {
        // If this was triggered by pagination, tack-on the results to the current list of upcoming items
        const upcomingItemsPrev = state?.resultList?.[laneIndex]?.upcoming ?? [];

        // Strip any possible duplicates
        upcomingItemsFormatted = removeDuplicateIds([...upcomingItemsPrev, ...upcomingItems]);
      } else {
        upcomingItemsFormatted = upcomingItems ?? [];
      }

      const upcomingItemsTotal = upcomingItemsRaw?.pageInfo?.totalEdges;
      const upcomingTotalLoaded = upcomingItemsFormatted?.length;
      const lastLoadedUpcomingItemId = upcomingItemsFormatted[upcomingItemsFormatted?.length - 1]?.id;

      liveLanes[laneIndex] = {
        ...state.resultList?.[laneIndex],
        upcoming: upcomingItemsFormatted,
        upcomingTotal: upcomingItemsTotal,
        upcomingTotalLoaded,
        lastLoadedUpcomingItemId,
      };

      return state.set('resultList', liveLanes);
    },

    [searchUpcomingItems().type]: (state, action) => {
      const liveLanes = state?.resultList;
      const upcomingItemsRaw = action?.payload?.lane;
      const laneId = action?.payload?.auctionTimeSlotLaneId;
      const laneIndex = state?.resultList?.findIndex((item) => item.id === laneId);
      const upcomingItems = parseQueryConnectionResponse(upcomingItemsRaw) ?? [];

      liveLanes[laneIndex] = {
        ...state.resultList?.[laneIndex],
        upcomingSearchResults: upcomingItems,
      };

      return state.set('resultList', liveLanes);
    },

    [clearUpcomingItemsSearch().type]: (state) => {
      const liveLanes = state.resultList?.map<LiveLaneResultModel>((liveLane) => ({
        ...liveLane,
        upcomingSearchResults: null,
      }));
      return state.set('resultList', liveLanes);
    },

    [activeLanesChanged().type]: (state, action) => state.set('activeLaneIds', action.payload),

    [activeTimeSlotIdChanged().type]: (state, action) => {
      const liveLanes = state.resultList?.map<LiveLaneResultModel>((liveLane) => ({
        ...liveLane,
        upcoming: null,
        upcomingTotal: null,
        upcomingTotalLoaded: null,
        lastLoadedUpcomingItemId: null,
      }));

      return state.set('activeTimeSlotId', action?.payload).set('resultList', liveLanes);
    },

    [auctionItemDetailsLoaded().type]: (state, action) => {
      const { id, watchers, autoBids, requestSequence } = action.payload;

      if (!isLatestRequestSequence(requestSequence)) {
        return state;
      }

      // Find the lane for this item by scanning the upcoming lists.
      const parentLane = state.resultList.find((lane) => !!(lane.upcoming || []).find((item) => item.id === id));
      const isWatched = watchers && watchers.isWatched;

      return state.set(
        'resultList',
        state.resultList?.map<LiveLaneResultModel>((lane) => ({
          ...lane,
          auctionItemIdsWatching:
            !!parentLane && lane.id === parentLane.id
              ? removeDuplicateIds(
                  isWatched
                    ? lane.auctionItemIdsWatching?.concat(id)
                    : lane.auctionItemIdsWatching?.filter((wid) => wid !== id)
                ) || []
              : lane.auctionItemIdsWatching,
          upcoming:
            lane?.upcoming?.map((item) => {
              if (item.id === id) {
                return { ...item, watchers, autoBids };
              }
              return item;
            }) ?? [],
        }))
      );
    },

    [setNextAuctions().type]: (state, action) => {
      // Omit auctions with zero upcoming units
      const auctionNodes = action.payload?.filter((nextAuction) => nextAuction?.itemCount)?.slice(0, 4);
      return state.set('nextAuctions', auctionNodes);
    },

    [pinPreviewItem().type]: (state, action) => state.set('previewItemPinned', !!action.payload),

    [setAuctionVideoStreamConfigs().type]: (state, action) => {
      const videoStreamConfigs: Record<string, VideoStreamConfig> = Object.fromEntries(
        (action.payload || []).map((auction) => {
          const { id, videoStreamConfig } = auction;
          return [id, videoStreamConfig];
        })
      );
      return state.set('auctionVideoStreamConfigs', videoStreamConfigs);
    },
  },
  new LiveLanesInitialState()
);
