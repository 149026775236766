import Header from 'components/ui/lists/listItem/header';
import InventoryItem from 'constants/inventoryItem';
import User from 'constants/user';
import { AuctionItemPropsJs } from 'store/auctionItemsList/auctionItemsModels';
import { CompanyTier } from 'store/shared/api/graph/interfaces/types';
import { companyTierTranslationMap } from 'constants/enums/company';
import { getMileageFormatted } from 'utils/numberUtils';
import { getMileageUnit, isAuctionStaff, isGroupManagerRole } from 'utils/userUtils';
import { joinStrings } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

interface Props {
  /** The auction item. */
  auctionItem: AuctionItemPropsJs;
  /** The inventory item. */
  inventoryItem: InventoryItem;
  /** Function invoked for auxiliary label. */
  onAuxLabelClick?: () => void;
  /** The logged-in user */
  user: User;
}

const ListItemHeader = ({ auctionItem, inventoryItem, onAuxLabelClick, user }: Props) => {
  const companyName = inventoryItem?.company?.name;
  const distanceAway = inventoryItem?.location?.distanceAway;
  const isMyItem = !auctionItem || auctionItem.isMyItem;
  const isEnded = auctionItem && auctionItem._ended;
  const isGroupManager = isGroupManagerRole(user);
  const isStaffUser = isAuctionStaff(user);
  const mileageUnit = getMileageUnit(user)?.toLowerCase();
  const distanceAwayFormatted = getMileageFormatted(distanceAway, mileageUnit);

  const headerLabels: string[] = [];
  if (auctionItem?.sellerTier && auctionItem?.sellerTier !== CompanyTier.NONE) {
    headerLabels.push(t(companyTierTranslationMap[auctionItem?.sellerTier]));
  }
  headerLabels.push(t('x_distance_away', [`${distanceAwayFormatted} ${mileageUnit}`]));
  const headerAuxLabel =
    !isStaffUser && isMyItem ? joinStrings([t('yours'), isGroupManager && companyName], ' - ') : companyName;

  return (
    <Header
      auxLabel={isEnded ? t('close') : headerAuxLabel}
      label={headerLabels}
      onAuxLabelClick={isEnded ? onAuxLabelClick : undefined}
    />
  );
};

export default ListItemHeader;
