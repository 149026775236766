import { List } from 'immutable';
import { handleActions } from 'redux-actions';
import { merge } from 'lodash-es';

import {
  AuctionItem,
  AuctionItemModel,
  AuctionItemsResults,
  Facet,
  FacetGroup,
  Filters,
  PageInfo,
  Sort,
} from './auctionItemsModels';
import {
  AuctionItemFormat,
  Bid,
  BidTimeline,
  Company,
  Facet as FacetType,
  FacetGroup as FacetGroupType,
  MonetaryAmount,
} from 'store/shared/api/graph/interfaces/types';
import { Location } from 'constants/reactRouter';
import { RecordOf } from 'store/shared/models/interfaces';
import { Route } from 'store/routing/routes';
import { auctionItemDetailsLoaded, auctionItemAddNote } from '../auctionItemDetails/auctionItemDetailsActions';
import {
  auctionItemsEndTimesUpdate,
  auctionItemsEnded,
  auctionItemsListAdded,
  auctionItemsListClear,
  auctionItemsListIsAdding,
  auctionItemsListIsLoading,
  auctionItemsListIsUpdating,
  auctionItemsListLoaded,
  auctionItemsListRemoveItem,
  auctionItemsListSetSubmittingItemIds,
  auctionItemsListUpdate,
  auctionItemsListUpdateItem,
  bidEventFacetsUpdate,
  bidEventItemUpdate,
  clearSavedFilters,
  incrementNewItemsCount,
  removeAuctionItem,
  resetNewItemsCount,
  savedFiltersLoaded,
  setFilter,
  unsetFilters,
  updateFacetCount,
  updateFacetGroups,
} from './auctionItemsActions';
import { formatCurrency } from 'utils/stringUtils';
import { getEnabledCompanyIds } from 'utils/userUtils';
import { isLatestRequestSequence } from 'utils/apiUtils';

interface LocationQuery {
  formats: AuctionItemFormat | AuctionItemFormat[];
}

const transformFacetGroups = (facetGroups: FacetGroupType[], routingLocation: Location<LocationQuery>) =>
  facetGroups
    .map((facetGroup) => {
      if (facetGroup.name === 'format' || facetGroup.name === 'formats') {
        return {
          ...facetGroup,
          facets: facetGroup.facets
            .filter((facet) => {
              /**
               * Filter out facets based on the current path
               */
              switch (routingLocation?.pathname) {
                case Route.BUY_TIMED_AUCTION: {
                  return ![
                    AuctionItemFormat.AUCTION,
                    AuctionItemFormat.AUCTION_PHYSICAL,
                    AuctionItemFormat.TIMED_OFFER,
                  ].includes(facet.name as AuctionItemFormat);
                }

                case Route.PENDING_IN_IF_BID: {
                  return ![AuctionItemFormat.TIMED_OFFER].includes(facet.name as AuctionItemFormat);
                }

                default:
                  return true;
              }
            })
            .map((facet) => {
              /**
               * By default, if `formats` is declared in our route config and `location.query.formats` is `undefined,
               * the `formats` value declared will be set to `true`. We want our default selection to be `All` instead
               * of selecting every individual values.
               */
              return !routingLocation?.query.formats ? { ...facet, selected: false } : facet;
            }),
        };
      }

      return facetGroup;
    })
    .map(
      ({ facets, ...result }) => new FacetGroup({ ...result, facets: List(facets.map((facet) => new Facet(facet))) })
    );

export const auctionItemsReducer = handleActions(
  {
    [auctionItemsListIsLoading().type]: (state) => state.setLoading(),

    [auctionItemsListIsAdding().type]: (state, action) => state.setLoaded().set('isAdding', action.payload),

    [auctionItemsListIsUpdating().type]: (state) => state.setUpdating(),

    [auctionItemsListLoaded().type]: (state, action) => {
      if (!isLatestRequestSequence(action.payload?.requestSequence)) {
        return state;
      }

      const sortParams = {} as any;
      (action.payload?.auctionItemConnection?.sort || []).forEach((s) => {
        if (s.selected) {
          sortParams.sortId = s.name;
          sortParams.ascending = s.ascending;
        }
      });

      return state.setLoaded().merge({
        resultList: List(
          action.payload?.auctionItemConnection?.edges?.map((result) => {
            return new AuctionItem({
              ...result.node,
            });
          })
        ),
        pageInfo: new PageInfo(action.payload?.auctionItemConnection?.pageInfo),
        sort: new Sort(sortParams),
        facetGroups: List(
          transformFacetGroups(action.payload?.auctionItemConnection?.facetGroups || [], action.payload.routingLocation)
        ),
      });
    },

    [auctionItemsListAdded().type]: (state, action) => {
      const additionalVehicles = action.payload?.auctionItemConnection?.edges?.map((result) => {
        return new AuctionItem({
          ...result.node,
        });
      });

      return state.setLoaded().merge({
        resultList: state.resultList.concat<RecordOf<AuctionItemModel>>(additionalVehicles).toList(),
        pageInfo: new PageInfo(action.payload?.auctionItemConnection?.pageInfo),
      });
    },

    [updateFacetCount().type]: (state, action) => {
      if (!state.loadedDate) {
        return state;
      }

      // Manually increases/decreases the provided facet count by 1
      const facetGroups = state.facetGroups.toJS().map((facetGroup) => {
        if (facetGroup.name === 'filterBy') {
          const { payload } = action;
          return {
            ...facetGroup,
            facets: facetGroup.facets?.map((facet) => {
              if (facet && facet?.name === payload.facetName) {
                const count = !payload.increment && facet.count === 0 ? 0 : facet.count + (payload.increment ? 1 : -1);
                return { ...facet, count };
              }
              return facet;
            }),
          };
        }

        return facetGroup;
      });

      return state.set(
        'facetGroups',
        List(
          facetGroups.map((result) => {
            return new FacetGroup({
              ...result,
              facets: List(
                result.facets.map((facet) => {
                  return new Facet({ ...facet });
                })
              ),
            });
          })
        )
      );
    },

    [auctionItemsListClear().type]: () => new AuctionItemsResults(),

    [auctionItemsListUpdate().type]: (state, action) => {
      const { list, context = {} } = action.payload;
      const { auctionItemIds, filterBy, isInList } = context;
      const updatedItems = list?.edges?.map((auctionEdge) => auctionEdge.node) || [];
      let matchingItem;

      if (!updatedItems.length && auctionItemIds) {
        // If we've queried specific auctionItemIds and nothing is returned, they've expired and need to be removed.
        const idsToRemove = auctionItemIds || [];
        const filteredList = state.resultList.filter((item) => !idsToRemove.includes(item?.id));

        return state.merge({
          resultList: filteredList.toList(),
          facetGroups: List(transformFacetGroups(list?.facetGroups || [], action.payload.routingLocation)),
        });
      }

      let resultList = List<RecordOf<AuctionItemModel>>(
        state.resultList.map((auctionItem) => {
          // Update any records with matching ids
          matchingItem = updatedItems.find((updatedItem) => updatedItem.id === auctionItem?.id);
          if (matchingItem) {
            return new AuctionItem(matchingItem);
          }
          return auctionItem;
        })
      );

      if (updatedItems.length && !isInList && !matchingItem) {
        // If in a filtered state, we need to manually push new items into the list
        updatedItems.forEach((auctionItem) => {
          const bidTimeline = auctionItem.bidTimeline;
          const isWinning = bidTimeline ? bidTimeline.winning : false;
          const isLosing = bidTimeline ? bidTimeline.outbid : false;
          const include =
            (filterBy === 'Outbid' && isLosing) || (filterBy === 'Winning' && isWinning) || filterBy === 'Reserve Met';

          if (include) {
            resultList = resultList.push(new AuctionItem(auctionItem));
          }
        });
      }

      return state.merge({
        resultList,
        facetGroups: List(transformFacetGroups(list?.facetGroups || [], action.payload.routingLocation)),
      });
    },

    [auctionItemsListRemoveItem().type]: (state, action) => {
      const auctionItemId = action.payload.id;
      const filteredList = state.resultList.filter((item) => item?.id !== auctionItemId);

      return state.merge({
        resultList: filteredList.toList(),
      });
    },

    [auctionItemsListUpdateItem().type]: (state, action) => {
      if (!state.loadedDate) {
        return state;
      }

      // Find the one item in the list and update it
      const vehicleUpdates = action.payload;
      const vehicleList = state.resultList.toJS();
      const oldVehicle = vehicleList.find((item) => item.id === vehicleUpdates.id);

      if (!oldVehicle) {
        return state;
      }

      const updatedVehicle = Object.assign(oldVehicle, vehicleUpdates);

      vehicleList.map((item) => {
        if (item.id === vehicleUpdates.id) {
          return updatedVehicle;
        }
        return item;
      });

      return state.unsetUpdating().merge({
        resultList: List(
          vehicleList.map((result) => {
            return new AuctionItem({
              ...result,
            });
          })
        ),
      });
    },

    [auctionItemsListSetSubmittingItemIds().type]: (state, action) => {
      return state?.set('submittingItemIds', action?.payload);
    },

    [auctionItemsEndTimesUpdate().type]: (state, action) => {
      const auctionItemEndTimes = List<FacetType>(action.payload);
      return state.unsetUpdating().merge({ auctionItemEndTimes });
    },

    [setFilter().type]: (state, action) => {
      const validFilterTypes = ['mileage', 'mileageTitle', 'year', 'yearTitle', 'vehicleScore', 'vehicleScoreTitle'];

      if (validFilterTypes.includes(action.payload.filter)) {
        return state.set(
          'filters',
          new Filters({
            mileage: state.filters.mileage,
            mileageTitle: state.filters.mileageTitle,
            year: state.filters.year,
            yearTitle: state.filters.yearTitle,
            vehicleScore: state.filters.vehicleScore,
            vehicleScoreTitle: state.filters.vehicleScoreTitle,
            [action.payload.filter]: action.payload.value,
          })
        );
      }

      return state;
    },

    [unsetFilters().type]: (state) => state.set('filters', new Filters()),

    [updateFacetGroups().type]: (state, action) => {
      const facetGroups = action.payload.facetGroups.toJS();

      return state.merge({
        facetGroups: List(
          facetGroups.map((result) => {
            return new FacetGroup({
              ...result,
              facets: List(result.facets.map((facet) => new Facet(facet))),
            });
          })
        ),
      });
    },

    [incrementNewItemsCount().type]: (state) => {
      return state.merge({ newItemsCount: state.newItemsCount + 1 });
    },

    [resetNewItemsCount().type]: (state) => {
      return state.merge({ newItemsCount: 0 });
    },

    [auctionItemDetailsLoaded().type]: (state, action) => {
      const auctionItem = action.payload;
      const { context, requestSequence } = auctionItem;

      const isValidSequence = isLatestRequestSequence(requestSequence);
      const resultListOrig = state.resultList;
      let resultList = resultListOrig;
      let exclude = false;
      let include = false;

      if (context) {
        const { filterBy } = context;

        const bidTimeline = auctionItem.bidTimeline;
        const isWinning = bidTimeline ? bidTimeline.winning : false;
        const isLosing = bidTimeline ? bidTimeline.outbid : false;

        exclude = (filterBy === 'Outbid' && isWinning) || (filterBy === 'Winning' && !isWinning);
        include = (filterBy === 'Outbid' && isLosing) || (filterBy === 'Winning' && isWinning);
      }

      if (exclude) {
        resultList = resultList.filter((item) => item?.id !== auctionItem.id).toList();
      } else {
        const index = resultList.findIndex((item) => item?.id === auctionItem.id);
        if (index !== -1) {
          try {
            const existingItem = resultList.get(index)!.toJS();
            const updatedItem = new AuctionItem(merge({}, existingItem, auctionItem));
            resultList = resultList.set(index, updatedItem);
          } catch (_) {
            // console.info('ImmutableError:', error);
          }
        } else if (include) {
          resultList = resultList.push(new AuctionItem(auctionItem));
        }
      }

      if (!isValidSequence || resultList === resultListOrig) {
        return state;
      }

      return state.merge({ resultList });
    },

    [auctionItemAddNote().type]: (state, action) => {
      const { inventoryItemId, note } = action.payload;
      const { resultList } = state;
      const index = resultList.findIndex((item) => item?.inventoryItem.id === inventoryItemId);
      if (index !== -1) {
        const auctionItem = resultList.get(index)!.toJS();
        auctionItem.inventoryItem?.notes?.push(note);

        return state.merge({ resultList: resultList.set(index, new AuctionItem(auctionItem)) });
      }

      return state;
    },

    [auctionItemsEnded().type]: (state, action) => {
      const endedAuctionItems = action.payload;
      const listItems = state.resultList.toJS();

      const listItemsNext = listItems?.map((auctionItem) => {
        const endedAuctionItem = endedAuctionItems?.find(({ id }) => auctionItem?.id === id);

        // Set ended items as so, and in-addition, set the `bidTimeline.winning
        // field based on PubSub's END event `hbId` message
        return new AuctionItem(
          !endedAuctionItem
            ? auctionItem
            : {
                ...auctionItem,
                _ended: true,
                bidTimeline: auctionItem?.bidTimeline
                  ? {
                      ...auctionItem?.bidTimeline,
                      winning: endedAuctionItem?.isWinning || auctionItem?.bidTimeline?.winning,
                    }
                  : null,
              }
        );
      });

      return state?.set('resultList', List(listItemsNext));
    },

    [removeAuctionItem().type]: (state, action) => {
      const { resultList: resultListPrev, pageInfo: pageInfoPrev } = state;
      const id = action.payload;

      const resultList = resultListPrev.filter((item) => item?.id !== id).toList();
      const pageInfo =
        resultList.count() === resultListPrev.count()
          ? pageInfoPrev
          : pageInfoPrev.merge({
              totalEdges: pageInfoPrev.totalEdges - 1,
              endCursor: `${parseInt(pageInfoPrev.endCursor || '', 10) - 1}`,
            });

      return state.merge({ resultList, pageInfo });
    },

    [savedFiltersLoaded().type]: (state, action) => state.set('savedFilters', action.payload),

    [clearSavedFilters().type]: (state) => state.set('savedFilters', null),

    /**
     * This is a temporary hack to reduce query requests
     * after PubSub dispatches a bid event ('AuctionItemBidEvent').
     *
     * Instead of making the usual GraphQL request to fetch the
     * latest auctionItem info, updated fields are now passed
     * through the PubSub message, and updated accordingly.
     */
    [bidEventItemUpdate().type]: (state, action) => {
      const { resultList } = state;
      const { companies, filterBy, isWinning, isLosing, message, user } = action.payload;
      const {
        bidConsignerIds: bidCompanyIds = [],
        atAmount,
        auctionItemId,
        becameReserveMet,
        endTime,
        format,
        furtherBidIncrement,
        holdback,
        isAssured,
        listPrice,
        nextBidAmount,
        startTime,
        status,
      } = message;

      const index = resultList.findIndex((item) => item?.id === auctionItemId);
      const exclude = (filterBy === 'Outbid' && isWinning) || (filterBy === 'Winning' && !isWinning);

      if (exclude) {
        return state.merge({ resultList: resultList.filter((item) => item?.id !== auctionItemId).toList() });
      }

      if (index !== -1) {
        const auctionItem = resultList.get(index)!.toJS();
        const bidCompany = companies.find((consigner) => bidCompanyIds?.[0] === consigner.id) || {
          id: bidCompanyIds?.[0],
        };

        // TODO: Replace all `consigner` references with `company`
        const bid = {
          amount: { amount: atAmount },
          consigner: bidCompany,
          company: bidCompany,
        } as Bid & { consigner: Company };
        let bidTimeline = { winning: isWinning, outbid: isLosing } as BidTimeline;

        if (auctionItem.bidTimeline) {
          bidTimeline = {
            ...auctionItem.bidTimeline,
            ...bidTimeline,
            list: [bid, ...(auctionItem.bidTimeline.list?.filter(Boolean) || [])],
          };
        } else {
          bidTimeline.list = [bid];
        }

        const listPriceWithHoldback = {} as MonetaryAmount;
        const nextBidAmountWithHoldback = {} as MonetaryAmount;

        const companyIds = getEnabledCompanyIds(user);
        const isUsersVehicle = companyIds?.includes(auctionItem?.inventoryItem?.company?.id);

        if (holdback && holdback > 0 && isUsersVehicle) {
          const listPriceAmount = listPrice?.amount - holdback;
          listPriceWithHoldback.amount = listPriceAmount;
          listPriceWithHoldback.formattedAmountRounded = formatCurrency(listPriceAmount) || '';

          const nextAmount = nextBidAmount?.amount - holdback;
          nextBidAmountWithHoldback.amount = nextAmount;
          nextBidAmountWithHoldback.formattedAmountRounded = formatCurrency(nextAmount) || '';
        }

        nextBidAmount.formattedAmountRounded =
          formatCurrency(nextBidAmount?.amount, auctionItem.nextBidAmount?.currencyCode) || '';

        const nextItem = {
          ...auctionItem,
          bidTimeline,
          timerEnd: endTime,
          format,
          furtherBidIncrement: { ...auctionItem.furtherBidIncrement, ...furtherBidIncrement },
          isAssured,
          listPrice: { ...listPrice, ...listPriceWithHoldback },
          nextBidAmount: { ...auctionItem.nextBidAmount, ...nextBidAmount, ...nextBidAmountWithHoldback },
          reserveMet: becameReserveMet || auctionItem.reserveMet,
          startTime,
          status,
        };

        return state.merge({ resultList: resultList.set(index, new AuctionItem(nextItem)) });
      }

      return state;
    },

    [bidEventFacetsUpdate().type]: (state, action) => {
      // TODO: Replace all `consigner` references with `company`
      const { facetGroups } = state;
      const { companyIds, hasBid, isWinning, message, auctionItemEndTime } = action.payload;
      const { becameReserveMet, bidConsignerIds: bidCompanyIds, lastAutoBid, dateRan } = message;

      if (auctionItemEndTime && new Date(auctionItemEndTime).getTime() !== new Date(dateRan).getTime()) {
        return state;
      }

      const facetIndex = facetGroups.findIndex((item) => item?.name === 'filterBy');
      if (facetIndex === -1) {
        return state;
      }

      const facetGroup = facetGroups.get(facetIndex)!.toJS();
      const getFacet = (name: string): FacetType | undefined => facetGroup.facets.find((item) => item.name === name);
      const winningFacet = getFacet('Winning');
      const outbidFacet = getFacet('Outbid');
      const reserveMetFacet = getFacet('Reserve Met');

      if (winningFacet && outbidFacet) {
        if (isWinning && !lastAutoBid) {
          winningFacet.count += 1; // +1 winning
          if (hasBid) {
            outbidFacet.count = Math.max(outbidFacet.count - 1, 0); // -1 outbid
          }
        } else if (companyIds?.includes(bidCompanyIds[1]) && !lastAutoBid) {
          outbidFacet.count += 1; // +1 outbid
          winningFacet.count = Math.max(winningFacet.count - 1, 0); // -1 winning
        }
      }

      if (reserveMetFacet && becameReserveMet) {
        reserveMetFacet.count += 1; // +1 reserve met
      }

      return state.merge({
        facetGroups: facetGroups.set(
          facetIndex,
          new FacetGroup({
            ...facetGroup,
            facets: List(facetGroup?.facets.map((facet) => new Facet(facet))),
          })
        ),
      });
    },
  },
  new AuctionItemsResults()
);
