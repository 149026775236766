import shareGlyph from 'glyphs/operationIcons/share.svg';
import shareTransparentGlyph from 'glyphs/operationIcons/share-transparent.svg';

import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import ErrorDialog from 'components/ui/shared/dialogs/confirmDialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { ErrorMessages } from 'constants/errors';
import { Spinner } from 'components/ui/loading/loading';
import { UserAction } from 'logging/analytics/events/userActions';
import { getErrors, getGraphBaseURL } from 'utils/apiUtils';
import { processGetShareUrl } from 'store/auctionItemDetails/auctionItemDetailsActions';
import { t } from 'utils/intlUtils';

import style from './share.scss';

interface Props {
  /** The id of the auction item. */
  auctionItemId?: string;
  /** True when the share button is the condensed version. */
  isCondensed?: boolean;
  /** The id of the inventory item. */
  inventoryItemId: string;
  /** The title of the dialog. */
  title: string;
  /** Callback function to track user actions. */
  trackUserAction?: (action: UserAction) => void;
}

interface State {
  /** Validation Errors. */
  errorMessages: ErrorMessages;
  /** True while retrieving the share url. */
  isUpdating: boolean;
  /** True when the dialog is open. */
  isOpen: boolean;
  /** The url to share. */
  shareUrl: string | null;
}

class Share extends BaseClass<Props, State> {
  constructor(props) {
    super(props);

    this.state = { errorMessages: [], isUpdating: false, isOpen: false, shareUrl: null };
  }

  onSubmit = () => {
    const { shareUrl } = this.state;
    const { auctionItemId, inventoryItemId, trackUserAction } = this.props;

    // Track user action
    trackUserAction?.(UserAction.VDP_SHARE_CLICK);

    if (shareUrl) {
      this.setState({ isOpen: true });
    } else {
      this.setState({ isUpdating: true });
      processGetShareUrl({ auctionItemId, inventoryItemId })
        .then((response) => {
          this.setState({
            errorMessages: [],
            isOpen: true,
            shareUrl: `${getGraphBaseURL()}${response}`,
          });
        })
        .catch((error) => {
          const errorMessages = getErrors(error);
          this.setState({ errorMessages });
        })
        .finally(() => this.setState({ isUpdating: false }));
    }
  };

  renderButton = () => {
    const { isCondensed } = this.props;
    const { isUpdating } = this.state;

    return isCondensed ? (
      <OperationButton
        className={style.condensed}
        glyph={shareTransparentGlyph}
        glyphClassName={style.condensedGlyph}
        onClick={this.onSubmit}
        theme="none"
      />
    ) : (
      <OperationButton
        className={style.button}
        glyph={isUpdating ? null : shareGlyph}
        onClick={this.onSubmit}
        theme="black"
      >
        {isUpdating ? <Spinner theme="white" /> : t('share')}
      </OperationButton>
    );
  };

  onCopyButtonClick = () => {
    // Track user action
    this.props.trackUserAction?.(UserAction.VDP_SHARE_COPY_CLICK);
  };

  render() {
    const { errorMessages, isOpen, shareUrl } = this.state;
    const { title } = this.props;

    return (
      <>
        {this.renderButton()}
        <Dialog
          isOpen={isOpen}
          onCopyButtonClick={this.onCopyButtonClick}
          onSubmit={() => this.setState({ isOpen: false })}
          shareUrl={shareUrl}
          title={title}
        />
        <ErrorDialog
          isOpen={!!errorMessages.length}
          onConfirm={() => this.setState({ errorMessages: [] })}
          title={t('error')}
          titleClassName={style.errorTitle}
        >
          {errorMessages.map((err) => err.message)}
        </ErrorDialog>
      </>
    );
  }
}

export default Share;
