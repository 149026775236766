import { Address, Location, User } from 'store/shared/api/graph/interfaces/types';
import { getCurrentLocale } from 'utils/intlUtils';

/**
 * Capitalize each word
 *
 * @example capitalize("clark kent") // "Clark Kent"
 * @param {string} str
 * @returns {string}
 */
export const capitalize = (str) =>
  str
    ?.split(/\s/g)
    ?.map((word) => `${word?.[0]?.toUpperCase()}${word?.slice(1)}`)
    ?.join(' ');

/**
 * Capitalize first character
 *
 * @example: capitalizeFirstChar("hello world!") // "Hello world!"
 * @param str
 * @returns {string}
 */
export const capitalizeFirstChar = (str) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Combines details of an address and formats it into address format:
 *
 * @example formatAddress(location) // "147 W 83rd St, New York, New York"
 * @param {object} location - location object
 * @param {boolean} showRegionCode - will show shorthand 2 letter region code
 * @param {boolean} showZipCode - will show zip code
 * @returns {string}
 */
export const formatAddress = (
  location: Partial<Location | Address>,
  showRegionCode: boolean = false,
  showZipCode: boolean = false
) => {
  const { address1, address2, city, region, regionCode, zipCode } = location;
  return joinStrings([
    address1,
    address2,
    city,
    showRegionCode ? regionCode : region,
    showZipCode ? zipCode : undefined,
  ]);
};

/**
 * Format string to replace substring with a newValue:
 *
 * @example substringReplace("+1 416-321-1111") // "416-321-1111"
 * @example substringReplace("data:image/png;base64,iVBO...") // "iVBO..."
 * @param {string} str
 * @param {string} substring
 * @param {string} newValue
 * @returns {string}
 */
export const substringReplace = (str, substring, newValue) => {
  return str && str.includes(substring) ? str.replace(substring, newValue) : str;
};

/**
 * Replace camel cased string to normal string:
 *
 * @example decamelize('timedAuction') // 'timed auction'
 * @example decamelize('runList') // 'run list'
 * @param str {string}
 * @returns {string}
 */
export const decamelize = (str) => {
  return str.replace(/[A-Z]/g, (m) => ` ${m.toLowerCase()}`)?.trim();
};

/**
 * Reformat camel cased string to snake case format
 *
 * @example camelCaseToSnakeCase('timedAuction'); // 'timed_auction'
 * @example camelCaseToSnakeCase('runList'); // 'run_list'
 * @param input
 */
export const camelCaseToSnakeCase = (input: string | undefined): string | undefined => {
  return input?.replace(/[A-Z]/g, (char) => `_${char?.toLowerCase()}`);
};

/**
 * Reformat kebab-cased string to camel-case format
 *
 * @example kebabCaseToCamelCase('my-block'); // 'myBlock'
 * @param input
 */
export const kebabCaseToCamelCase = (input: string | undefined): string | undefined => {
  return input?.replace(/-./g, (segment) => segment[1].toUpperCase());
};

/**
 * Reformat kebab-cased string to snake-case format
 *
 * @example kebabCaseToCamelCase('my-block'); // 'my_block'
 * @param input
 */
export const kebabCaseToSnakeCase = (input: string): string => {
  return input?.replace(/-/g, '_');
};

/**
 * Reformat snake-case string to kebab-cased format
 *
 * @example snakeCaseToKebabCase('my_block'); // 'my-block'
 * @param input
 */
export const snakeCaseToKebabCase = (input: string | undefined): string | undefined => {
  return input?.replace(/_/g, '-');
};

/**
 * Reformat to snake-case string
 *
 * @example convertToSnakeCase('foo_bar'); // 'foo-bar'
 * @example convertToSnakeCase('foo bar'); // 'foo-bar'
 * @param str
 */
export const convertToSnakeCase = (str: string | undefined): string | undefined => {
  return str?.split(' ').join('-')?.split('_').join('-')?.toLowerCase();
};

/**
 * Nullify an empty string:
 *
 * @example nullifyEmptyString("") // null
 * @param {string} str
 * @returns {null|string}
 */
export const nullifyEmptyString = (str) => {
  if (String(str)?.length) {
    return str;
  }
  return null;
};

/**
 * Coverts an enum to a readable capitalized string:
 *
 * @example convertEnumToString('DELIVERED') // 'Delivered'
 * @example convertEnumToString('PICKED_UP') // 'Picked Up'
 * @param {string} str
 * @param {bool} capAll
 * @returns {string}
 */
export const convertEnumToString = (str, capAll = true) => {
  if (!str) {
    return str;
  }
  const capFunc = capAll ? capitalize : capitalizeFirstChar;
  return capFunc(str.split('_').join(' ').toLowerCase());
};

/**
 * Converts readable string to enum:
 *
 * @example convertStringToEnum("Pending Delivery") // "PENDING_DELIVERY"
 * @param {string} str
 * @returns {string}
 */
export const convertStringToEnum = (str) => {
  if (!str) {
    return str;
  }
  return str.split(' ').join('_').toUpperCase();
};

/**
 * Converts a string to a boolean
 *
 * @example convertStringToBoolean("true") //  true
 * @example convertStringToBoolean("false") //  false
 * @example convertStringToBoolean("text") //  undefined
 * @param value
 */
export const convertStringToBoolean = (value: string | undefined): boolean | undefined => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return undefined;
};

/**
 * Truncates a string to the max-length supplied, and adds an ellipsis if desired.
 *
 * @example truncate("testString", 3) // "tes..."
 * @param str {string}
 * @param maxLength {number}
 * @param hasEllipsis {boolean}
 * @returns {string|*}
 */
export const truncate = (str, maxLength = 25, hasEllipsis = true) => {
  if (str && str.length > maxLength) {
    return [str.substr(0, maxLength).trim(), hasEllipsis && '...'].join('');
  }
  return str;
};

/**
 * Formats rating:
 *
 * @example formatRating(2.5) // "2.5/5"
 * @param rating {number}
 * @returns {string}
 */
export const formatRating = (rating) => {
  return rating > 0 ? `${rating}/5` : 'N/A';
};

/**
 * Formats booleans:
 *
 * @example formatBoolean(true) // "True"
 * @param boolean {boolean}
 * @returns {string}
 */
export const formatBoolean = (boolean) => {
  return capitalize(String(boolean));
};

/**
 * Formats numbers
 *
 * @example formatNumber(1000000); // returns '1,000,000'
 * @param {string|number} value
 * @returns {string} Formatted number
 */
export const formatNumber = (value) => {
  if (!value) {
    return '0';
  }
  if (isNaN(value)) {
    return `${value}`;
  }

  return new Intl.NumberFormat(getCurrentLocale())?.format(value);
};

/**
 * Formats integers
 *
 * TODO: re-evaluate the need for this function
 *
 * @example formatNumberToInteger('1000.50') // '1,000'
 * @param {string|number} value
 * @returns {string} Number with comma
 */
export const formatNumberToInteger = (value: string | number | undefined = undefined) =>
  value !== undefined && !isNaN(Number(value)) ? formatNumber(value).split('.')[0] : null;

/**
 * Formats currency
 *
 * @example formatCurrency('1000.50') // returns '$1,000.50' (logged-in user's locale is `en-CA`)
 * @example formatCurrency('1000.50', 'en-US') // returns 'US$1,000.50' (logged-in user's locale is `en-CA`)
 * @example formatCurrency('1000.50', 'en-US') // returns '1 000,50 $ US' (logged-in user's locale is `fr-CA`)
 * @example formatCurrency('1000.50', 'en-CA') // returns 'CAD$1,000.50' (logged-in user's locale is `en-US`)
 * @param {string|number} value
 * @param {string} currency
 * @returns {string} The number as a formatted string for the current language
 */
export const formatCurrency = (value, currency = 'CAD') => {
  if (!value && value !== 0) {
    return null;
  }

  const formatter = new Intl.NumberFormat(getCurrentLocale(), {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
};

/**
 * Joins together an array of strings and filters out any dead strings
 *
 * @param {string[]} strings an array of strings to combine
 * @param {string} separator - string of the separator characters
 * @returns {string} the resulting string item
 */
export const joinStrings = (strings: any[] = [], separator: string = ', '): string =>
  strings
    .flat()
    .filter((s) => s)
    .join(separator);

/**
 * Test url
 *
 * @param {string} text
 * @returns {boolean}
 */
export const isURL = (text) =>
  /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi.test(text);

/**
 * Test email
 *
 * @param {string} text
 * @returns {boolean}
 */
export const isEmail = (text) => /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/gi.test(text);

/**
 * Taken from https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
 *
 * @param {string} text
 * @returns {boolean}
 */
export const isPhoneNumber = (text) => /^\+?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/gi.test(text);

/**
 * Formats trip distance
 *
 * @param {number} distance
 * @param {string} [unit]
 * @returns {string}
 */
export const formatTripDistance = (distance, unit = 'km') =>
  distance ? `${Math.round(distance).toLocaleString()} ${unit} trip` : '';

/**
 * Format year, make, model, trim
 *
 */
export const formatYMMT = (inventoryItem) => {
  if (!inventoryItem) {
    return '';
  }

  return joinStrings(
    [inventoryItem.year, inventoryItem.make, inventoryItem.model, inventoryItem.subModel, inventoryItem.trim],
    ' '
  );
};

/**
 * Formats year into 2 digits:
 *
 * @example formatYear('2008') // "'08"
 * @param {string|number} year
 * @returns {string}
 */
export const formatYear = (year) => {
  if (!year) {
    return null;
  }

  return `'${year.toString().slice(-2)}`;
};

/**
 * Displays location
 *
 * @example formatLocation(location) // "New York, NY"
 * @param {object} location
 * @returns {string}
 */
export const formatLocation = (location: Location | null) => {
  const loc: string[] = [];

  if (location && location.city && location.regionCode) {
    loc.push(location.city);
    loc.push(location.regionCode);
  }

  return loc.join(', ');
};

/**
 * Displays location with zip code
 *
 * @example formatLocationWithZip(location) // "Toronto ON M5V 2Z2"
 * @param {object} location
 * @param {string} separator - string of the separator characters
 * @returns {string}
 */
export const formatLocationWithZip = (location, separator = ' ') =>
  joinStrings([location?.city, location?.region, location?.zipCode], separator);

/**
 * Displays full location
 *
 * @example formatFullLocation(location) // "700 Avenue Gilles Villeneuve, Berthierville QC, Canada"
 * @param {object} location
 * @returns {string}
 */
export const formatFullLocation = (location: Partial<Location | Address> | undefined) => {
  if (location) {
    const { address1, address2, city, country, regionCode, zipCode } = location;
    return joinStrings([address1, address2, city, regionCode, zipCode, country]);
  }
  return '';
};

/**
 * Formats a phone number
 *
 * @example formatPhoneNumber(4165551234) // "416-555-1234"
 * @example formatPhoneNumber(14165551234) // "+1 416-555-1234"
 * @param {string} number
 * @returns {string|null}
 */
export const formatPhoneNumber = (number: number | string | undefined) => {
  const formattedPhoneNumber = `${number}`.replace(/\D/g, '');
  const phoneNumberMatch = formattedPhoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (phoneNumberMatch) {
    const intlCode = phoneNumberMatch[1] ? '+1 ' : '';
    return [intlCode, '', phoneNumberMatch[2], '-', phoneNumberMatch[3], '-', phoneNumberMatch[4]].join('');
  }

  return undefined;
};

/**
 * Formats a timezone name by adding additional spaces around slashes and replacing underscores with spaces.
 *
 * @example formatTimeZoneName('America/Toronto') // "America / Toronto"
 * @example formatTimeZoneName('America/North_Dakota/New_Salem') // "America / North Dakota / New Salem"
 * @param name
 * @returns {*}
 */
export const formatTimeZoneName = (name) => name.replace(/\//g, ' / ').replace(/_/g, ' ');

/**
 * Displays a user's first and last name.

 * @example formatUserName(user) // "Joel Ray"
 * @param user
 * @returns {string}
 */
export const formatUserName = (user: Partial<Pick<User, 'firstName' | 'lastName'>> | undefined | null) => {
  if (!user) {
    return undefined;
  }

  const userNames = [user?.firstName, user?.lastName].filter(Boolean);
  return userNames?.length ? userNames?.join(' ') : undefined;
};

/**
 * Checks if string includes searchQuery.
 *
 * @example isStringInSearchQuery('foobar', 'foo') // true
 * @example isStringInSearchQuery('foo', 'bar') // false
 * @param {string} string
 * @param {string} searchQuery
 * @returns {boolean}
 */
export const isStringInSearchQuery = (string, searchQuery) =>
  String(string).toLowerCase().includes(String(searchQuery).trim().toLowerCase());

/**
 * Replace last n characters with given value
 *
 * @example replaceLastNChars('123456', '*', 2); // '1234**'
 * @example replaceLastNChars('abcdef', '*', 3); // 'abc***'
 * @param {string} string the original string
 * @param {string} replace the replace value for the last n chars
 * @param {number} n the number of last chars that will be replaced
 * @returns {string} the formatted string where the last n chars are replaced by the
 * given value
 */
export const replaceLastNChars = (string: string, replace: string, n: number): string => {
  if (n > 0) {
    return string.slice(0, -n) + replace.repeat(Math.min(string.length, n));
  }
  return string;
};
