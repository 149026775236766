import { ChangeEventHandler, useCallback } from 'react';

import myBlockGlyph from 'glyphs/my-block.svg';

import AuthService from 'store/shared/services/authService';
import Checkbox from 'forms/shared/checkbox';
import Logger from 'logging/Logger';
import MyBlockBuyerStats from 'components/sections/myBlock/stats/myBlockBuyerStats';
import MyBlockSellerStats from 'components/sections/myBlock/stats/myBlockSellerStats';
import Sprite from 'components/ui/shared/sprite';
import { MyBlockBuyerResponse, MyBlockSellerResponse } from 'store/shared/api/graph/interfaces/types';
import { MyBlockVariant } from 'components/sections/myBlock/myBlock';
import { PrimaryTitle, TertiaryTitleLight } from 'layouts/list/listItemLayout';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { UserAction } from 'logging/analytics/events/userActions';
import { paramsToQueryString } from 'utils/urlUtils';
import { t } from 'utils/intlUtils';
import { useMyBlockPrintContent } from 'contexts/myBlockPrintContentContext';

import style from './myBlockStats.scss';

interface LocationQuery {
  totalIncludeIfBid: boolean;
}

interface Props extends RouterProps<LocationQuery> {
  /** The stat amounts. */
  stats: MyBlockBuyerResponse | MyBlockSellerResponse | null;
  /** The variation of my block view */
  variant: MyBlockVariant;
}

const MyBlockStats = ({ location, router, stats, variant }: Props) => {
  const isIfBidsIncluded = Boolean(location.query.totalIncludeIfBid);
  const myBlockPrintContent = useMyBlockPrintContent();

  /**
   * Determines whether or not IN_IF_BID items should be included in stat totals
   */
  const onCheckboxChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const totalIncludeIfBidChecked = !!e?.target?.checked;
      const { totalIncludeIfBid, ...params } = location.query;

      const paramsNext = paramsToQueryString({
        ...params,
        ...(totalIncludeIfBidChecked && { totalIncludeIfBid: totalIncludeIfBidChecked }),
      });

      if (totalIncludeIfBidChecked) {
        Logger.trackUserAction(UserAction.INCLUDE_IF_BIDS, {
          is_auction_staff: !!AuthService.getOwnedAuctionIds()?.length,
        });
      }

      router?.push(`${location?.pathname}?${paramsNext}`);
    },
    [location, router]
  );

  return (
    <div ref={myBlockPrintContent?.setStatsRef} className={style.container}>
      <div className={style.iconContainer}>
        <div className={style.icon}>
          <Sprite glyph={myBlockGlyph} />
        </div>
      </div>

      <div className={style.info}>
        <PrimaryTitle className={style.title}>{t('my_block')}</PrimaryTitle>
        <TertiaryTitleLight className={style.subTitle}>{t('my_block_info_message')}</TertiaryTitleLight>
      </div>
      <div className={style.stats}>
        <div className={style.statsInner}>
          {variant === MyBlockVariant.BUYER && <MyBlockBuyerStats stats={stats as MyBlockBuyerResponse} />}
          {variant === MyBlockVariant.SELLER && <MyBlockSellerStats stats={stats as MyBlockSellerResponse} />}
          <div className={style.checkboxContainer}>
            <Checkbox
              className={style.checkbox}
              dataTestId="include-if-bid"
              id="includeInIfBid"
              labelClassName={style.checkboxLabel}
              onChange={onCheckboxChange}
              setChecked={isIfBidsIncluded}
              text={t('include_if_bid')}
              theme="check"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MyBlockStats);
