import classnames from 'classnames';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Button from 'components/ui/shared/button';
import DialogContent from 'layouts/inventoryItemLayouts/addModify/dialogContentLayouts';
import InputSwitch from 'forms/shared/inputSwitch';
import InputText from 'forms/shared/inputText';
import SelectCompanyInputGroup from 'forms/shared/selectCompanyInputGroup';
import SelectLocations from 'forms/shared/selectLocations';
import User from 'constants/user';
import { AddModifyVehicleProps } from 'store/inventoryItem/addModify/addModifyModels';
import { ErrorMessages } from 'constants/errors';
import { FeatureFlag } from 'constants/featureFlags';
import { SelectCompanyOption } from 'forms/shared/selectCompany';
import { Spinner } from 'components/ui/loading/loading';
import { getEnabledCompanyIds, getEnabledCompanyRelationships, isAuctionStaff } from 'utils/userUtils';
import { hasFeatureFlag } from 'utils/featureFlagUtils';
import { t } from 'utils/intlUtils';
import { usePrevious } from 'hooks/usePrevious';

import style from './vinOverlay.scss';

interface Props {
  /** Callback function to change country code. */
  changeCountryCode: (countryCode: string) => void;
  /** Callback function to clear error messages. */
  clearError: () => void;
  /** Error Messages. */
  errorMessages: ErrorMessages;
  /** Callback function to submit create inventory item. */
  handleSubmitCreateInventoryItem: (obj?: AddModifyVehicleProps, persist?: boolean) => void;
  /** Whether is loading or not. */
  isLoading: boolean;
  /** Callback function to set error messages. */
  setError: (errorMessages: ErrorMessages) => void;
  /** Callback function to set vehicle information. */
  setVehicle: (option: AddModifyVehicleProps) => void;
  /** Callback function to submit vin overlay. */
  submitVinOverlay: (option: AddModifyVehicleProps) => void;
  /** Current user. */
  user: User;
  /** Current vehicle. */
  vehicle: AddModifyVehicleProps;
}

const VinOverlay = ({
  changeCountryCode,
  clearError,
  errorMessages,
  handleSubmitCreateInventoryItem,
  isLoading,
  setError,
  setVehicle,
  submitVinOverlay,
  user,
  vehicle,
}: Props) => {
  const { consignerId, vin, stockNumber } = vehicle;

  const [auctionLocationId, setAuctionLocationId] = useState<string>();
  const [hasVin, setHasVin] = useState<boolean>(false);
  const [isRecreational, setIsRecreational] = useState<boolean>(false);
  const [isMinimalCR, setIsMinimalCR] = useState<boolean>(false);
  const [validateVin, setValidateVin] = useState<boolean>(false);
  const modalScrollRef = useRef<HTMLDivElement | null>(null);
  const prevErrorMessages: ErrorMessages | undefined = usePrevious(errorMessages);

  const hasError = !!errorMessages.length;
  const isNextButtonLoading = validateVin && isLoading;

  const hasErrorByValue = (name: string) => !!errorMessages.find((err) => err.name === name);

  /**
   * Scroll to top when error message length changed
   */
  useEffect(() => {
    if (!prevErrorMessages?.length && errorMessages?.length && modalScrollRef.current) {
      modalScrollRef.current.scrollTop = 0;
    }
  }, [errorMessages?.length, modalScrollRef, prevErrorMessages?.length]);

  /**
   * Memoized companies dropdown
   */
  const companiesDropdownData = useMemo(() => {
    return getEnabledCompanyRelationships(user)?.map((userCompanyRelationship) => ({
      value: userCompanyRelationship?.company.id,
      label: userCompanyRelationship?.company.name,
    }));
  }, [user]);

  /**
   * Submit form
   */
  const handleSubmitForm = useCallback(
    (validate: boolean) => {
      const obj = {
        ...vehicle,
        auctionLocationId,
        decodeVin: validate,
        switchPage: true,
        validateVin: validate,
      };
      const { vin: newVin } = obj;

      setValidateVin(validate);
      if (validate && (!newVin || newVin.trim().length < 16)) {
        setError([{ name: 'vin', message: t('invalid_vin_length') }]);
      } else if (validate) {
        submitVinOverlay(obj);
      } else {
        handleSubmitCreateInventoryItem(obj, false);
      }
    },
    [auctionLocationId, handleSubmitCreateInventoryItem, setError, submitVinOverlay, vehicle]
  );

  /**
   * Change consigner
   */
  const onConsignerChange = useCallback(
    (selectedCompany: SelectCompanyOption) => {
      const company = getEnabledCompanyRelationships(user)?.find(
        (userCompanyRelationship) => userCompanyRelationship?.company?.id === selectedCompany?.value
      )?.company;

      changeCountryCode(company?.primaryLocation?.countryCode || 'CA');
      setVehicle({ consignerId: selectedCompany?.value });
      if (hasError) {
        clearError();
      }
    },
    [changeCountryCode, clearError, hasError, setVehicle, user]
  );

  /**
   * Callback when recreational toggle changes
   */
  const onRecreationalChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e?.currentTarget?.checked;
      if (isChecked) {
        // If recreational is checked, then Minimal CR should be unchecked
        setVehicle({ bodyType: 'Recreational', minimalCR: false });
        setIsMinimalCR(false);
      } else {
        setVehicle({ bodyType: null });
      }
      setIsRecreational(isChecked);
    },
    [setIsRecreational, setVehicle]
  );

  /**
   * Callback when minimal toggle changes
   */
  const onMinimalCrChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e?.currentTarget?.checked;
      if (isChecked) {
        // If minimal CR is checked, then Recreational should be unchecked
        setVehicle({ bodyType: null });
        setIsRecreational(false);
      }
      setVehicle({ minimalCR: isChecked });
      setIsMinimalCR(isChecked);
    },
    [setIsRecreational, setVehicle]
  );

  return (
    <>
      <DialogContent ref={modalScrollRef} className={classnames(style.modalContainer, style.modalCustom)} hasScroll>
        {hasError && (
          <ul className={style.errorMessageContainer}>
            {errorMessages.map((errorItem) => (
              <li key={errorItem.name}>{errorItem.message}</li>
            ))}
          </ul>
        )}
        <div className={classnames(style.vinOverlayForm, hasError && style.marginCustom)}>
          <p className={style.instruction}>{t('enter_vin_instruction')}</p>
          <p className={style.instruction}>{t('alter_info_instruction')}</p>
          <div className={style.line} />
          <div className={style.inputContainer}>
            <div className={style.companies}>
              <SelectCompanyInputGroup
                hasError={hasErrorByValue('consignerId')}
                onChange={onConsignerChange}
                placeholder={t('select')}
                value={companiesDropdownData?.find((option) => option.value === consignerId)}
              />
            </div>

            <div className={style.companies}>
              <label className={style.label}>{t('select_auction_location')}</label>
              <SelectLocations
                companyId={consignerId ?? getEnabledCompanyIds(user)?.[0]}
                connectionVariables={{ mode: 'COMPOUNDS_ONLY' }}
                isDisabled={!consignerId}
                onLocationChange={(location) => setAuctionLocationId(location?.id)}
                showCompoundSelectionOption={false}
              />
            </div>

            <label className={style.label}>{t('enter_vin')}</label>
            <InputText
              dataTestId="enterVin"
              defaultValue={vin || undefined}
              onBlur={(e) => setVehicle({ vin: e?.target.value })}
              onChange={(value) => {
                if (hasVin !== !!value) {
                  setHasVin(!!value);
                }
                if (hasError) {
                  clearError();
                }
              }}
              theme={hasErrorByValue('vin') ? 'error' : undefined}
            />
            {isAuctionStaff(user) && (
              <div className={classnames(style.switchContainer)} data-testid="recreational-vehicle-toggle">
                <label className={style.label}>{t('capture_recreational_vehicle')}</label>
                <InputSwitch
                  className={style.inputSwitch}
                  dataTestId="recreational-vehicle-input"
                  id="recreational-vehicle-input"
                  onChange={onRecreationalChange}
                  value={isRecreational}
                />
              </div>
            )}
            {hasFeatureFlag(FeatureFlag.MINIMAL_CR) && isAuctionStaff(user) && (
              <div className={classnames(style.switchContainer)} data-testid="minimal-cr-toggle">
                <label className={style.label}>{t('capture_minimal_cr')}</label>
                <InputSwitch
                  className={style.inputSwitch}
                  dataTestId="minimal-cr-input"
                  id="minimal-cr-input"
                  onChange={onMinimalCrChange}
                  value={isMinimalCR}
                />
              </div>
            )}
            <label className={style.label}>{t('enter_stock_number')}</label>
            <InputText
              dataTestId="enterStockNumber"
              defaultValue={stockNumber || undefined}
              onBlur={(e) => setVehicle({ stockNumber: e?.target.value })}
              onChange={() => hasError && clearError()}
              theme={hasErrorByValue('stockNumber') ? 'error' : undefined}
            />
          </div>
        </div>
      </DialogContent>
      <div className={style.footer}>
        {!isRecreational && (
          <Button className={style.link} onClick={() => handleSubmitForm(false)} theme="none">
            {hasVin ? t('continue_with_vin_without_decoding') : t('enter_vin_later')}
          </Button>
        )}
        <Button
          className={style.button}
          dataTestId="next-button"
          disabled={isNextButtonLoading}
          onClick={() => handleSubmitForm(!isRecreational)}
          theme="green"
        >
          {isNextButtonLoading ? <Spinner spinnerStyleClassName={style.spinnerStyle} /> : t('next')}
        </Button>
      </div>
    </>
  );
};

export default VinOverlay;
