import classnames from 'classnames';
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import LinkButton from 'components/ui/shared/buttons/linkButton';
import { AnchorTabProps } from 'components/sections/inventoryItem/details/anchorTabs';
import { NAVIGATION_CONTAINER_ID } from 'containers/navigation/navigationContainer';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { getUrlParams, paramsToQueryString } from 'utils/urlUtils';
import { usePrevious } from 'hooks/usePrevious';

import style from './anchorTabs.scss';

export const SCROLLABLE_CONTENT_ID = 'scrollable-content';
export const STICKIED_TOP_CONTENT_ID = 'stickied-top-content';
const ANCHOR_TABS_ID = 'anchor-tabs';

interface Props extends RouterProps {
  /** CSS Styling to overwrite default active tab style. */
  activeTabClass?: string;

  /** CSS Styling to overwrite default anchor style. */
  anchorClass?: string;

  /** CSS Styling to overwrite default tab container style. */
  className?: string;

  /** The index of the selected tab. */
  defaultSelected?: number;

  /** Whether the anchor tabs are sticky to top or not. */
  isSticky?: boolean;

  /** Function invoked when a tab has been clicked. */
  onChange?: (value: number, event?: MouseEvent<HTMLButtonElement>) => void;

  /** CSS Styling to overwrite default tab style. */
  tabClass?: string;

  /** Anchor tabs list. */
  tabs: AnchorTabProps[];

  /** CSS Styling to overwrite default tabs style. */
  tabsClass?: string;

  /** CSS Styling to overwrite default tabs inner container style. */
  tabsInnerContainerClass?: string;
}

const AnchorTabs = ({
  activeTabClass,
  anchorClass,
  className,
  defaultSelected,
  isSticky,
  location,
  onChange,
  tabClass,
  tabs,
  tabsClass,
  tabsInnerContainerClass,
}: Props) => {
  const [selected, setSelected] = useState<number | undefined>(defaultSelected);
  const tabsRef = useRef<HTMLUListElement>(null);

  /**
   * Filter out tabs that are not visible
   */
  const filteredTabs = useMemo(() => tabs.filter((tab) => tab.isVisible), [tabs]);

  /**
   * Base url
   */
  const baseUrl = useMemo(() => {
    return `${location.pathname}?${paramsToQueryString(getUrlParams())}`;
  }, [location]);
  const basePrev: string | undefined = usePrevious(baseUrl);

  /**
   * Reset selected when base url changed
   */
  useEffect(() => {
    if (baseUrl !== basePrev) {
      setSelected(defaultSelected);
    }
  }, [basePrev, baseUrl, defaultSelected]);

  /**
   * Handle tab click event
   // */
  const handleClick = useCallback(
    (index: number, id: string, event: MouseEvent<HTMLButtonElement>) => {
      onChange?.(index, event);
      setSelected(index);

      if (isSticky) {
        const scrollableContent = document.getElementById(SCROLLABLE_CONTENT_ID);
        const nextSection = document.getElementById(id);
        const nextSectionYPos = nextSection?.getBoundingClientRect()?.top ?? 0;
        const navHeight = document.getElementById(NAVIGATION_CONTAINER_ID)?.getBoundingClientRect()?.height ?? 0;
        const anchorTabsHeight = document.getElementById(ANCHOR_TABS_ID)?.getBoundingClientRect()?.height ?? 0;
        const stickiedTopContent =
          document.getElementById(STICKIED_TOP_CONTENT_ID)?.getBoundingClientRect()?.height ?? 0;

        // Scroll to selected element, minus the stickied element(s) heights
        scrollableContent!.scrollTop =
          nextSectionYPos + scrollableContent!.scrollTop - anchorTabsHeight - stickiedTopContent - navHeight;
      }

      // Scroll into the clicked tab
      const tab = tabsRef.current?.children?.[index];
      if (tab && tabsRef.current) {
        const gradientWidth = 24;
        const tabRect = tab.getBoundingClientRect();
        const tabsRect = tabsRef.current.getBoundingClientRect();
        if (tabRect.left < tabsRect.left + gradientWidth) {
          // If clicked tab's left end is not in the view, scroll to left to show the tab
          tabsRef.current.scrollLeft -= tabsRect.left - tabRect.left + gradientWidth;
        } else if (tabRect.right > tabsRect.right - gradientWidth) {
          // If clicked tab's right end is not in the view, scroll to right to show the tab
          tabsRef.current.scrollLeft += tabRect.right - tabsRect.right + gradientWidth;
        }
      }
    },
    [isSticky, onChange]
  );

  return (
    <div className={classnames(style.tabsContainer, isSticky && style.isSticky, className)} id={ANCHOR_TABS_ID}>
      <div className={classnames(style.tabsInnerContainer, tabsInnerContainerClass)}>
        <ul ref={tabsRef} className={classnames(style.tabs, tabsClass)}>
          {filteredTabs.map((tab, index) => (
            <div
              key={tab.id}
              className={classnames(style.tab, tabClass, selected === index && (activeTabClass || style.active))}
              data-testid={tab.id}
            >
              <LinkButton
                className={classnames(style.anchor, anchorClass)}
                onClick={(e) => handleClick(index, tab.id, e)}
              >
                {tab.component || tab.label}
              </LinkButton>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default withRouter(AnchorTabs);
