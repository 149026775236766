import classnames from 'classnames';
import { useCallback } from 'react';

import archiveGlyph from 'glyphs/archive.svg';
import printGlyph from 'glyphs/print.svg';
import starGlyph from 'glyphs/star.svg';

import IconButton from 'components/ui/shared/buttons/iconButton';
import SetHoldback from 'components/sections/auctionItem/operations/setHoldback/setHoldback';
import Sprite from 'components/ui/shared/sprite';
import User from 'constants/user';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { UserAction } from 'logging/analytics/events/userActions';
import { getGraphBaseURL } from 'utils/apiUtils';
import { getMileage } from 'utils/numberUtils';
import { getMileageUnit } from 'utils/userUtils';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './header.scss';

interface Props {
  /** Auction item details. */
  details: AuctionItemDetailsProps;

  /** Whether is company details hidden or not. */
  isCompanyDetailsHidden: boolean;

  /** Callback function to toggle watch. */
  toggleWatch: () => void;

  /** Current user. */
  user: User;
}

const AuctionItemDetailsHeader = ({ user, details, toggleWatch, isCompanyDetailsHidden }: Props) => {
  const {
    archived,
    displayRunNumber,
    inventoryItem: { location, make, model, trim, year },
    isMyItem,
    sellerTier,
  } = details;
  const { distanceAway } = location ?? {};
  const mileageUnit = getMileageUnit(user)?.toLowerCase();
  const distanceAwayFormatted = getMileage(distanceAway, mileageUnit);

  const consignerSubtitle = sellerTier ? t(`consigner_tier_${sellerTier?.toLowerCase()}`) : null;
  const consignerTitle = isMyItem ? t('yours') : t('x_distance_away', [`${distanceAwayFormatted} ${mileageUnit}`]);
  const subtitle = [model, trim].join(' ');
  const title = [year, make].join(' ');

  const onPrintClick = useCallback(() => {
    trackUserActionWithAuctionItemAttributes(UserAction.VDP_PRINT_CLICK, details);
    window.open(`${getGraphBaseURL()}${details.inventoryItem.printableUrl}?auctionItemId=${details.id}`);
  }, [details]);

  return (
    <header className={style.header}>
      <IconButton
        className={classnames(style.star, !!details.watchers?.isWatched && style.isWatching)}
        dataTestId="watch-button"
        glyph={starGlyph}
        onClick={toggleWatch}
        title={t('watch')}
      />
      <div className={style.headerDetails}>
        <div className={style.details}>
          <h3 className={classnames(style.title, 'ellipsis')} title={title}>
            {archived && <Sprite className={style.archived} glyph={archiveGlyph} />}
            {!!displayRunNumber && (
              <span className={style.runNumber} data-testid="run-number">
                {displayRunNumber}&nbsp;
              </span>
            )}
            {title}
          </h3>
          {subtitle && (
            <div className={classnames(style.subtitle, 'ellipsis')} title={subtitle}>
              {subtitle}
            </div>
          )}
        </div>
        {!isCompanyDetailsHidden && (
          <div className={style.consignerDetails} data-testid="company-details">
            {consignerTitle && <h3 className={classnames(style.title, 'ellipsis')}>{consignerTitle}</h3>}
            {consignerSubtitle && (
              <div className={classnames(style.subtitle, 'ellipsis')} data-testid="company-subtitle">
                {consignerSubtitle}
              </div>
            )}
          </div>
        )}
        <div className={style.rightButtonGroup}>
          <SetHoldback auctionItem={details} />
          <IconButton glyph={printGlyph} onClick={onPrintClick} />
        </div>
      </div>
    </header>
  );
};

export default AuctionItemDetailsHeader;
