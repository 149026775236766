import classnames from 'classnames';

import { SaleLight } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './saleLights.scss';

interface Props {
  /** CSS styling to override default container style */
  className?: string;
  /** The collection of sale lights to be displayed on the list item. */
  saleLights: SaleLight[] | undefined;
  /** CSS styling to override default sale light style */
  saleLightClassName?: string;
}

const SaleLights = ({ className, saleLights, saleLightClassName }: Props) => {
  if (!saleLights?.length) {
    return null;
  }

  return (
    <div className={classnames(style.container, className)} data-testid="sale-lights">
      {saleLights.map((saleLight: SaleLight) => (
        <div
          key={`sale-light-${saleLight}`}
          className={classnames(style.saleLight, saleLightClassName, style[saleLight.toLowerCase()])}
          data-testid={`sale-light-${saleLight.toLowerCase()}`}
        >
          {t(`${saleLight.toLowerCase()}_abbreviation`)}
        </div>
      ))}
    </div>
  );
};

export default SaleLights;
