export const pageInfo = `
  pageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
    totalEdges
  }
`;

export const facetGroups = `
  facetGroups {
    allowMultiple
    facets {
      count
      localizedName
      name
      selected
    }
    name
  }
`;
