import { List } from 'immutable';

import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import Record from 'store/shared/models/record';
import { Company as CompanyType, CompanyCreditLog } from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

export type CompanyVehicleAuctionStatusAmounts = {
  /** Number of vehicles in awaiting checkout. */
  awaitingCheckout: number;

  /** Number of cancelled vehicles. */
  cancelled: number;

  /** Number of vehicles in if bid. */
  inIfBid: number;

  /** Number of live lanes vehicles. */
  liveLanes?: number;

  /** Number of no sale vehicles. */
  noSale: number;

  /** Number of parked vehicles. */
  parked: number;

  /** Number of pending delivery vehicles. */
  pendingDelivery: number;

  /** Number of purchased vehicles. */
  purchased: number;

  /** Number of sold vehicles. */
  sold: number;

  /** Number of timed auction vehicles. */
  timedAuctions: number;

  /** Number of upcoming vehicles. */
  upcoming: number;
};

interface CompanyDetailsModel {
  /** Company credits logs. */
  creditsLog: List<CompanyCreditLog> | null;
  /** Company details. */
  details: RecordOf<CompanyType>;
  /** Company vehicle auction amounts by status. */
  vehicles: CompanyVehicleAuctionStatusAmounts;
}

export type CompanyDetailsProps = ServerRecordOf<CompanyDetailsModel>;
export type CompanyDetailsPropsJs = ReturnType<ServerRecordOf<CompanyDetailsModel>['toJS']>;

export const InitialState = ServerRecord<CompanyDetailsModel>({
  creditsLog: null,
  details: null,
  vehicles: {
    liveLanes: 0,
    timedAuctions: 0,
    upcoming: 0,
    parked: 0,
    inIfBid: 0,
    pendingDelivery: 0,
    awaitingCheckout: 0,
    purchased: 0,
    sold: 0,
    noSale: 0,
    cancelled: 0,
  },
});

export const Company = Record<CompanyType>({
  id: null,
  status: null,
  created: null,
  updated: null,
  auctionRelationships: null,

  // Notes
  notes: [],

  // Company Info
  checkReleasePreference: null,
  creditsBuy: null,
  creditsLoyalty: null,
  creditsSell: null,
  description: null,
  holdback: null,
  integrations: null,
  invoiceSignature: null,
  legalName: null,
  name: null,
  numericId: null,
  parentCompany: null,
  primaryLocation: null,
  restrictCarfaxCanadaOrdering: null,
  rolePermissionsEnabled: null,
  source: null,
  stats: null,
  subType: null,
  // @ts-ignore `tier` does not exist in CompanyType
  tier: null,
  titleReleasePreference: null,
  type: null,
  visibleRegions: [],

  // Tax
  auctionAccessInfo: null,
  americanLegalInfo: null,
  canadianLegalInfo: null,

  // Legacy Billing Info
  dealerNumber: null,
  rin: null,
  taxNumber: null,
  pad: null,
  buyFee: null,
  buyProcessingFee: null,
  buyTransportCompound: null,
  buyTransportCompoundFee: null,
  buyTransportCompoundEnabled: null,
  buyPSIEnabled: null,
  buyPSIFee: null,
  buyLienCheck: null,
  defaultSellerNotes: null,
  dataCaptureFee: null,
  sellListingFee: null,
  sellFee: null,
  sellProcessingFee: null,
  sellTransportCompound: null,
  sellTransportCompoundFee: null,
  sellTransportCompoundEnabled: null,
  sellTransportCompoundPaidBy: null,
  sellPSIEnabled: null,
  sellPSIPaidBy: null,
  sellPSIFee: null,
  sellLienCheck: null,
});

export const CompanyUserRelationship = Record({
  user: null,
  status: null,
  department: null,
  roles: [],
});
