import { createAction } from 'redux-actions';

import { search } from './searchApi';

export const searchIsUpdating = createAction('SEARCH_SET_UPDATING');
export const searchLoaded = createAction('SEARCH_RESULTS_LOADED');
export const clearSearch = createAction('SEARCH_CLEAR_RESULTS');

export interface SearchOptions {
  text: string;
  isAuctionStaff?: boolean;
}

export const processSearch = ({ text, isAuctionStaff }: SearchOptions, dispatch) => {
  dispatch(clearSearch());
  if (text === '') {
    return Promise.resolve();
  }
  dispatch(searchIsUpdating(true));
  return search(text, isAuctionStaff).then((response) => dispatch(searchLoaded(response?.data.data)));
};
