import Record from 'store/shared/models/record';

interface Props {
  /* True if a 429 status error is ever returned from the backend */
  isError429Active: boolean;
  /* True if network is up */
  isNetworkUp: boolean;
  /* True if pub/sub services should be active */
  isPubSubActive: boolean;
  /* True if timed out */
  isTimedOut: boolean;
  /* System time offset */
  timeOffset: number;
}

export const System = Record<Props>({
  isError429Active: false,
  isNetworkUp: true,
  isPubSubActive: false,
  isTimedOut: false,
  timeOffset: 0,
});
