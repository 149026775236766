import classnames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import InputGroup from 'forms/shared/inputGroup';
import NotificationBanner from 'components/ui/shared/notifications/notificationBanner';
import RadioButtons, { RadioButtonLabel } from 'forms/shared/radioButtons';
import { AuctionLaneType, AuctionTimeSlotLane } from 'store/shared/api/graph/interfaces/types';
import { LanesByType } from 'components/sections/auctionItem/operations/submitToAuction/constants/submitToAuction';
import { SelectOption } from 'utils/interfaces/SelectOption';
import { Spinner } from 'components/ui/loading/loading';
import { auctionLaneTypeTranslationMap } from 'constants/enums/auctionLaneType';
import { getSelectOptionsForEnum } from 'utils/formUtils';
import { t } from 'utils/intlUtils';

import formStyle from './submitForm.scss';
import style from './auctionLaneInput.scss';

interface SelectRunNumberOption extends SelectOption<string> {
  /** If true, this run number options is intended */
  isIntended: boolean;
  /** If true, this run number options is reserved */
  isReserved: boolean;
}

interface Props {
  /** Currently selected auction lane type. */
  auctionLaneType: AuctionLaneType | null;
  /** Currently selected auction time slot id. */
  auctionTimeSlotId?: string;
  /** Currently selected auction time slot lane id. */
  auctionTimeSlotLaneId: string | null;
  /** Current auction time slot lanes by type */
  auctionTimeSlotLanesByType: LanesByType | null;
  /** Intended auction time slot lane */
  intendedAuctionTimeSlotLane: Pick<AuctionTimeSlotLane, 'auctionTimeSlotId' | 'id' | 'name' | 'type'> | null;
  /** Intended run number. */
  intendedRunNumber: number | null;
  /** True if loading necessary data to render the component. */
  isLoading: boolean;
  /** User input run number. */
  runNumber: number | null;
  /** Callback function to set selected auction lane type. */
  setAuctionLaneType: (auctionLaneType: AuctionLaneType) => void;
  /** Callback function to set selected auction time slot lane id. */
  setAuctionTimeSlotLaneId: (auctionTimeSlotLaneId: string | null) => void;
  /** Callback function to set run number. */
  setRunNumber: (runNumber: number | null) => void;
}

const AuctionLaneInput = ({
  auctionLaneType,
  auctionTimeSlotId,
  auctionTimeSlotLaneId,
  auctionTimeSlotLanesByType,
  intendedAuctionTimeSlotLane,
  intendedRunNumber,
  isLoading,
  runNumber,
  setAuctionLaneType,
  setAuctionTimeSlotLaneId,
  setRunNumber,
}: Props) => {
  const refSetAuctionLaneType = useRef(setAuctionLaneType);
  const refSetAuctionTimeSlotLaneId = useRef(setAuctionTimeSlotLaneId);
  const refSetRunNumber = useRef(setRunNumber);
  const [runNumberOptions, setRunNumberOptions] = useState<SelectOption<string>[]>([]);
  const [showCustomRunNumberInput, setShowCustomRunNumberInput] = useState<boolean>(false);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);

  const hasOnlyOneLaneType = auctionTimeSlotLanesByType && Object.keys(auctionTimeSlotLanesByType).length === 1;
  const isIntendedAuctionTimeSlot = auctionTimeSlotId === intendedAuctionTimeSlotLane?.auctionTimeSlotId;
  const isIntendedAuctionTimeSlotLane = auctionTimeSlotLaneId === intendedAuctionTimeSlotLane?.id;
  const isIntendedAuctionTimeSlotLaneType = auctionLaneType === intendedAuctionTimeSlotLane?.type;
  const isIntendedRunNumber = runNumber === intendedRunNumber;
  const otherLabel = t('other');

  /**
   * Get reserved auction time slot lane, return auction time slot lane if there isn't an intended auction time slot
   * and there is a reserved run number available
   */
  const reservedAuctionTimeSlotLane = useMemo(() => {
    if ((isIntendedAuctionTimeSlot && isIntendedAuctionTimeSlotLane) || !auctionTimeSlotLanesByType) {
      return undefined;
    }

    return Object.entries(auctionTimeSlotLanesByType)
      .reduce((acc, [, lanes]) => acc.concat(lanes.filter(Boolean)), [] as AuctionTimeSlotLane[])
      .find((lane) => lane.openRunNumbers.some(({ reservedForSeller }) => reservedForSeller));
  }, [auctionTimeSlotLanesByType, isIntendedAuctionTimeSlot, isIntendedAuctionTimeSlotLane]);

  /**
   * Set intended/reserved auction time slot values
   */
  useEffect(() => {
    // Physical/Digital only time slots are pre-selected, thus we can skip lane type check
    if (auctionTimeSlotLaneId || (!hasOnlyOneLaneType && auctionLaneType)) {
      return;
    }

    if (isIntendedAuctionTimeSlot && intendedAuctionTimeSlotLane) {
      refSetAuctionLaneType.current(intendedAuctionTimeSlotLane.type);
      refSetAuctionTimeSlotLaneId.current(intendedAuctionTimeSlotLane.id);
      refSetRunNumber.current(intendedRunNumber);
      return;
    }

    if (reservedAuctionTimeSlotLane) {
      refSetAuctionLaneType.current(reservedAuctionTimeSlotLane.type);
      refSetAuctionTimeSlotLaneId.current(reservedAuctionTimeSlotLane.id);
    }
  }, [
    auctionLaneType,
    auctionTimeSlotLaneId,
    hasOnlyOneLaneType,
    intendedAuctionTimeSlotLane,
    intendedRunNumber,
    isIntendedAuctionTimeSlot,
    isIntendedAuctionTimeSlotLaneType,
    reservedAuctionTimeSlotLane,
  ]);

  /**
   * Reset run number and retrieve available run numbers when the selected lane changes
   */
  useEffect(() => {
    if (!auctionTimeSlotLaneId || auctionLaneType !== AuctionLaneType.PHYSICAL) {
      return;
    }

    const otherOption: SelectRunNumberOption = {
      isIntended: false,
      isReserved: false,
      label: otherLabel,
      value: otherLabel,
    };

    const auctionTimeSlotLane = auctionTimeSlotLanesByType?.PHYSICAL?.find(({ id }) => id === auctionTimeSlotLaneId);

    if (!auctionTimeSlotLane) {
      setRunNumberOptions([otherOption]);
      return;
    }

    const options =
      auctionTimeSlotLane.openRunNumbers?.map<SelectRunNumberOption>((openRunNumbers) => {
        const numberAsString = openRunNumbers.runNumber.toString();

        return {
          isIntended: false,
          isReserved: openRunNumbers.reservedForSeller,
          label: numberAsString,
          value: numberAsString,
        };
      }) || [];

    // Add intended run number to the option list
    if (isIntendedAuctionTimeSlot && isIntendedAuctionTimeSlotLane && intendedRunNumber) {
      const intendedRunNumberAsString = intendedRunNumber.toString();

      options.push({
        isIntended: true,
        isReserved: false,
        label: intendedRunNumberAsString,
        value: intendedRunNumberAsString,
      });

      // Sort the options in sequence
      options.sort((a, b) => Number(a.value) - Number(b.value));
    }

    // Add other option
    options.push(otherOption);

    // Set new list of run numbers
    setRunNumberOptions(options);

    // Set intended run number if is both intended timeslot and timeslot lane matches
    if (isIntendedAuctionTimeSlot && isIntendedAuctionTimeSlotLane) {
      refSetRunNumber.current(intendedRunNumber);
      return;
    }

    // Set the first reserved run number
    const reservedRunNumbers = options.filter((number) => number.isReserved);
    if (reservedRunNumbers.length) {
      refSetRunNumber.current(Number(reservedRunNumbers[0].value));
      return;
    }

    refSetRunNumber.current(null);
  }, [
    auctionLaneType,
    auctionTimeSlotLaneId,
    auctionTimeSlotLanesByType?.PHYSICAL,
    intendedRunNumber,
    isIntendedAuctionTimeSlot,
    isIntendedAuctionTimeSlotLane,
    otherLabel,
  ]);

  /**
   * Show disclaimer text if current selected lane and run number does not match the intended lane and run number
   */
  useEffect(() => {
    const shouldShowDisclaimer =
      !isLoading &&
      isIntendedAuctionTimeSlot &&
      isIntendedAuctionTimeSlotLaneType &&
      (!isIntendedAuctionTimeSlotLane || !isIntendedRunNumber);

    setShowDisclaimer(shouldShowDisclaimer);
  }, [
    isIntendedAuctionTimeSlot,
    isIntendedAuctionTimeSlotLane,
    isIntendedAuctionTimeSlotLaneType,
    isIntendedRunNumber,
    isLoading,
  ]);

  /**
   * Memoized lane type labels
   */
  const auctionLaneTypeLabels = useMemo(
    () =>
      getSelectOptionsForEnum(AuctionLaneType, auctionLaneTypeTranslationMap).map<RadioButtonLabel<AuctionLaneType>>(
        (option) => ({
          title: option.label,
          value: option.value,
        })
      ),
    []
  );

  /**
   * Memoized lane type options
   */
  const laneTypeOptions = useMemo(() => {
    const laneTypes = Object.keys(auctionTimeSlotLanesByType || {});

    return auctionLaneTypeLabels
      .filter((option) => laneTypes.includes(option.value))
      .map(({ title, value }) => {
        if (
          value === AuctionLaneType.DIGITAL &&
          value === intendedAuctionTimeSlotLane?.type &&
          auctionTimeSlotId === intendedAuctionTimeSlotLane?.auctionTimeSlotId
        ) {
          return {
            title: t('auction_lane_type_digital_x', [`${intendedAuctionTimeSlotLane?.name || ''}${intendedRunNumber}`]),
            value,
          };
        }

        return { title, value };
      });
  }, [
    auctionLaneTypeLabels,
    auctionTimeSlotId,
    auctionTimeSlotLanesByType,
    intendedAuctionTimeSlotLane,
    intendedRunNumber,
  ]);

  /**
   * Memoized physical lane options
   */
  const physicalLanesOptions = useMemo(
    () =>
      auctionTimeSlotLanesByType?.[AuctionLaneType.PHYSICAL]?.map<SelectOption>((lane) => ({
        label: t('lane_x', [lane.name]),
        value: lane.id,
      })),
    [auctionTimeSlotLanesByType]
  );

  /**
   * Set the auction lane type on select changes
   */
  const onRadioSelectChange = useCallback(
    (e) => {
      const selectedLaneType: AuctionLaneType = e.target.value;
      refSetAuctionLaneType.current(selectedLaneType);

      if (isIntendedAuctionTimeSlot && intendedAuctionTimeSlotLane?.type === selectedLaneType) {
        refSetRunNumber.current(intendedRunNumber);
        refSetAuctionTimeSlotLaneId.current(intendedAuctionTimeSlotLane.id);
      } else if (reservedAuctionTimeSlotLane && reservedAuctionTimeSlotLane.type === selectedLaneType) {
        refSetAuctionLaneType.current(reservedAuctionTimeSlotLane.type);
        refSetAuctionTimeSlotLaneId.current(reservedAuctionTimeSlotLane.id);
      } else {
        refSetAuctionTimeSlotLaneId.current(null);
        refSetRunNumber.current(null);
      }
    },
    [intendedAuctionTimeSlotLane, intendedRunNumber, isIntendedAuctionTimeSlot, reservedAuctionTimeSlotLane]
  );

  /**
   * Set auction lane on select changes.
   */
  const onLaneSelectChange = useCallback(
    ({ value }: SelectOption<string>) => refSetAuctionTimeSlotLaneId.current(value || null),
    []
  );

  /**
   * Set run number on option changes.
   */
  const onRunNumberOptionChange = useCallback(
    ({ value }: SelectOption<string>) => {
      if (value === otherLabel) {
        setShowCustomRunNumberInput(true);
      } else {
        setShowCustomRunNumberInput(false);
        refSetRunNumber.current(Number(value));
      }
    },
    [otherLabel]
  );

  /**
   * Formats the run number option label, add `reserved` or `intended` suffix to selected run numbers
   */
  const handleFormatOptionLabel = useCallback(({ isReserved, isIntended, label }: SelectRunNumberOption) => {
    if (isReserved) {
      return t('x_reserved', [label]);
    }

    if (isIntended) {
      return t('x_intended', [label]);
    }

    return label;
  }, []);

  if (isLoading) {
    return <Spinner className={style.loading} />;
  }

  return (
    <div className={style.container}>
      {laneTypeOptions.length > 1 && (
        <>
          <p className={classnames(formStyle.subTitle, formStyle.headerText)}>{t('lane_type')}</p>
          <RadioButtons
            className={formStyle.radioButtons}
            id="laneType"
            labels={laneTypeOptions}
            onChange={onRadioSelectChange}
            selectedOption={auctionLaneType || undefined}
          />
        </>
      )}
      {auctionLaneType === AuctionLaneType.PHYSICAL && (
        <>
          <p className={classnames(formStyle.subTitle, formStyle.headerText)}>{t('lane_run_number')}</p>
          <div className={style.laneRunNumberInputs}>
            {showDisclaimer && (
              <NotificationBanner
                className={style.disclaimer}
                primaryGlyphType="info"
                showSecondaryGlyph={false}
                theme="blue"
              >
                {t('auction_lane_type_physical_disclaimer')}
              </NotificationBanner>
            )}
            <InputGroup
              className={style.laneRunNumberInput}
              groupType="select"
              hasMargin={false}
              name="lane"
              onChange={onLaneSelectChange}
              options={physicalLanesOptions}
              placeholder={t('select_lane')}
              themeOverrides={{ height: '46px' }}
              value={{ id: auctionTimeSlotLaneId }}
            />
            <InputGroup
              className={style.laneRunNumberInput}
              dataTestId="run-number-options"
              formatOptionLabel={handleFormatOptionLabel}
              groupType="select"
              hasMargin={false}
              isDisabled={!auctionTimeSlotLaneId || !runNumberOptions.length}
              name="runNumberOptions"
              onChange={onRunNumberOptionChange}
              options={runNumberOptions}
              placeholder={t('run_number')}
              themeOverrides={{ height: '46px' }}
              value={{ id: showCustomRunNumberInput ? otherLabel : runNumber?.toString() }}
            />
            {showCustomRunNumberInput && (
              <InputGroup
                className={style.laneRunNumberInput}
                dataTestId="run-number-input"
                defaultValue={runNumber}
                hasMargin={false}
                name="runNumber"
                onChange={refSetRunNumber.current}
                placeholder={t('run_number')}
                type="number"
              />
            )}
            <InputGroup dataTestId="run-number-hidden" defaultValue={runNumber} name="runNumber" type="hidden" />
          </div>
        </>
      )}
    </div>
  );
};

export default AuctionLaneInput;
