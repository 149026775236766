import ApiRequest from 'store/shared/api/apiRequest';
import { pageInfo, facetGroups } from 'store/shared/api/graph/schema/connection';

export const getArbitrationsList = ({
  first = null,
  after = null,
  last = null,
  before = null,
  keyword = null,
  filterBy = null,
  arbitrationStatus = null,
  arbitrationSubStatus = null,
  arbitrationTransactionType = null,
  arbitrationOnTime = null,
  arbitrationCancelled = null,
  arbitrationTags = null,
  arbitrationResponsibleParty = null,
  buyer = null,
  seller = null,
  arbiter = null,
  compensationToBuyerGTE = null,
  compensationToBuyerLTE = null,
  compensationPaidByEBlockGTE = null,
  compensationPaidByEBlockLTE = null,
  compensationPaidByEBlockType = null,
  sort = null,
}) => {
  return ApiRequest({
    data: {
      query: `
        query(
          $first: Int
          $after: String
          $last: Int
          $before: String
          $keyword: String
          $filterBy: [String]
          $arbitrationStatus: [AuctionItemArbitrationStatus]
          $arbitrationSubStatus: [AuctionItemArbitrationSubStatus]
          $arbitrationTransactionType: [TransactionType]
          $arbitrationOnTime: Boolean
          $arbitrationCancelled: Boolean
          $arbitrationTags: [String]
          $arbitrationResponsibleParty: [ArbitrationResponsibleParty]
          $buyer: [String]
          $seller: [String]
          $arbiter: [String]
          $compensationToBuyerGTE: Int
          $compensationToBuyerLTE: Int
          $compensationPaidByEBlockGTE: Int
          $compensationPaidByEBlockLTE: Int
          $compensationPaidByEBlockType: [ArbitrationCompensationType]
          $sort: [Sort]
        ) {
          auctionItemArbitrationConnection(
            first: $first
            after: $after
            last: $last
            before: $before
            keyword: $keyword
            filterBy: $filterBy
            arbitrationStatus: $arbitrationStatus
            arbitrationSubStatus: $arbitrationSubStatus
            arbitrationTransactionType: $arbitrationTransactionType
            arbitrationOnTime: $arbitrationOnTime
            arbitrationCancelled: $arbitrationCancelled
            arbitrationTags: $arbitrationTags
            arbitrationResponsibleParty: $arbitrationResponsibleParty
            buyer: $buyer
            seller: $seller
            arbiter: $arbiter
            compensationToBuyerGTE: $compensationToBuyerGTE
            compensationToBuyerLTE: $compensationToBuyerLTE
            compensationPaidByEBlockGTE: $compensationPaidByEBlockGTE
            compensationPaidByEBlockLTE: $compensationPaidByEBlockLTE
            compensationPaidByEBlockType: $compensationPaidByEBlockType
            sort: $sort
          ) {
            ${pageInfo}
            edges {
              node {
                id
                status
                format
                arbitrationRequest {
                  status
                  created
                  updated
                  content
                  onTime
                  cancelled
                  company {
                    id
                    name
                  }
                  compensationToBuyer {
                    formattedAmount
                  }
                }
                inventoryItem {
                  ... on Vehicle {
                    id
                    year
                    make
                    model
                    trim
                    photos (first: 1) {
                      ...thumbnailListFrag
                    }
                    company {
                      id
                      name
                    }
                  }
                }
                transactionDetails {
                  buyerCompany {
                    id
                    name
                  }
                }
              }
            }
            ${facetGroups}
          }
        }
        fragment thumbnailListFrag on Photo {
          main: url (width: 800, height: 600)
          thumb: url (width: 110, height: 110)
        }
      `,
      variables: {
        first,
        after,
        last,
        before,
        keyword,
        filterBy,
        arbitrationStatus,
        arbitrationSubStatus,
        arbitrationTransactionType,
        arbitrationOnTime: arbitrationOnTime !== null ? arbitrationOnTime === 'true' : null,
        arbitrationCancelled: arbitrationCancelled !== null ? arbitrationCancelled === 'true' : null,
        arbitrationTags,
        arbitrationResponsibleParty,
        buyer,
        seller,
        arbiter,
        compensationToBuyerGTE,
        compensationToBuyerLTE,
        compensationPaidByEBlockGTE,
        compensationPaidByEBlockLTE,
        compensationPaidByEBlockType,
        sort,
      },
    },
  });
};
