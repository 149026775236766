import AuctionItem from 'constants/auctionItem';
import Record from 'store/shared/models/record';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { ArbitrationMetaData as ArbitrationMetaDataType } from 'store/shared/api/graph/interfaces/types';
import { RecordOf } from 'store/shared/models/interfaces';

export type ArbitrationType = Pick<
  AuctionItem,
  'id' | 'status' | 'format' | 'arbitrationRequest' | 'inventoryItem' | 'transactionDetails'
>;

interface ArbitrationDetailsModel {
  /** Arbitration details. */
  details: RecordOf<ArbitrationType>;

  /** Is saving Arbitration details. */
  isSaving: boolean;

  /** Arbitration meta data. */
  metadata: RecordOf<ArbitrationMetaDataType>;

  /** Selected quote id. */
  selectedQuoteId: string;
}

export type ArbitrationDetailsProps = ServerRecordOf<ArbitrationDetailsModel>;
export type ArbitrationDetailsPropsJs = ReturnType<ServerRecordOf<ArbitrationDetailsModel>['toJS']>;

export const ArbitrationMetaData = Record<ArbitrationMetaDataType>({
  arbitrationTags: [],
});

export const InitialState = ServerRecord<ArbitrationDetailsModel>({
  details: null,
  isSaving: false,
  selectedQuoteId: null,
  metadata: new ArbitrationMetaData(),
});

export const Arbitration = Record<ArbitrationType>({
  id: null,
  status: null,
  format: null,
  arbitrationRequest: null,
  inventoryItem: null,
  transactionDetails: null,
});
