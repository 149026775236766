import { PropsWithChildren, RefObject, createContext, useCallback, useContext, useMemo, useRef } from 'react';

export interface MyBlockPrintContentContextState {
  /** Ref to the my block item list */
  itemListRef: RefObject<HTMLDivElement>;
  /** Callback to set the item list ref */
  setItemListRef: (ref: HTMLDivElement) => void;
  /** Callback to set the stats ref */
  setStatsRef: (ref: HTMLDivElement) => void;
  /** Ref to the my block stats */
  statsRef: RefObject<HTMLDivElement>;
}

/**
 * The my block print content context.
 * Provides the `MyBlockPrintContentContextState` to all consumers of `useMyBlockPrintContent()`.
 */
export const MyBlockPrintContentContext = createContext<MyBlockPrintContentContextState | undefined>(undefined);

export const MyBlockPrintContentProvider = ({ children }: PropsWithChildren) => {
  const itemListRef = useRef<HTMLDivElement | null>(null);
  const statsRef = useRef<HTMLDivElement | null>(null);

  /**
   * Callback to set the item list ref
   */
  const setItemListRef = useCallback((ref: HTMLDivElement) => {
    itemListRef.current = ref;
  }, []);

  /**
   * Callback to set the stats ref
   */
  const setStatsRef = useCallback((ref: HTMLDivElement) => {
    statsRef.current = ref;
  }, []);

  /**
   * Memoize the full context value
   */
  const contextValue: MyBlockPrintContentContextState = useMemo(
    () => ({
      itemListRef,
      setItemListRef,
      setStatsRef,
      statsRef,
    }),
    [setItemListRef, setStatsRef]
  );

  return <MyBlockPrintContentContext.Provider value={contextValue}>{children}</MyBlockPrintContentContext.Provider>;
};

/**
 * A hook for getting a reference to the `MyBlockPrintContentContextState` data.
 */
export const useMyBlockPrintContent = (): MyBlockPrintContentContextState | undefined => {
  const context = useContext(MyBlockPrintContentContext);
  if (context === undefined) {
    throw new Error('"useMyBlockPrintContent()" must be used within the "MyBlockPrintContentProvider"');
  }
  return context;
};
