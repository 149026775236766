/**
 * A list of active flags
 * When referencing flag names in the code, the flag names should be included in this enum.
 */
export enum FeatureFlag {
  CAPTURE_CONFIRMATION = 'capture-confirmation',
  CHANGE_BUYER = 'change-buyer',
  MINIMAL_CR = 'minimal-cr',
  MY_BLOCK_BUYER_VIEW = 'my-block-buyer-view',
  NUMERIC_COMPANY_ID = 'numeric-company-id',
  SIMULCAST = 'simulcast',
  TIMED_OFFER = 'timed-offer-auction',
}
