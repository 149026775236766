import ApiRequest from 'store/shared/api/apiRequest';
import { TransportEntityConnection } from 'store/shared/api/graph/interfaces/types';
import { facetGroups } from 'store/shared/api/graph/schema/connection';
import { locationFragment } from 'store/shared/api/graph/fragments/common';

export const transportRequest = `
  id
  status
  inventoryItem {
    ...on Vehicle {
      id
      year
      make
      model
      subModel
      trim
      company {
        id
        name
      }
      location {
        ...location
      }
      vin
      photos (first: 1) {
        ...thumbnailListFrag
      }
    }
  }
  pickupLocation {
    ...location
  }
  destinationLocation {
    ...location
  }
  declined
  distance
  created
  updated
  type
`;

export const getTransportJobsList = (
  {
    auctionTitle = null,
    first = null,
    after = null,
    last = null,
    before = null,
    keyword = null,
    status = null,
    filterBy = null,
    carrierId = null,
    carrier = null,
    createdBy = null,
    sellerId = null,
    seller = null,
    buyerId = null,
    buyer = null,
    pickupCountry = null,
    pickupRegion = null,
    pickupCity = null,
    pickupLocation = null,
    destinationCountry = null,
    destinationRegion = null,
    destinationCity = null,
    destinationLocation = null,
    quoteCount = null,
    estimatedPickupDateGTE = null,
    estimatedPickupDateLTE = null,
    estimatedDeliveryDateGTE = null,
    estimatedDeliveryDateLTE = null,
    deliveredDateGTE = null,
    deliveredDateLTE = null,
    sort = null,
  },
  isPaginatedListWithFacets = true
) =>
  ApiRequest<{ transportConnection: TransportEntityConnection }>({
    data: {
      query: `
      query(
        $auctionTitle: [String]
        $first: Int
        $after: String
        $last: Int
        $before: String
        $keyword: String
        $status: [TransportRequestStatus]
        $filterBy: String
        $carrierId: [String]
        $carrier: [String]
        $createdBy: [String]
        $sellerId: [String]
        $seller: [String]
        $buyerId: [String]
        $buyer: [String]
        $pickupCountry: [String]
        $pickupRegion: [String]
        $pickupCity: [String]
        $pickupLocation: [String]
        $destinationCountry: [String]
        $destinationRegion: [String]
        $destinationCity: [String]
        $destinationLocation: [String]
        $quoteCount: [String]
        $estimatedPickupDateGTE: Date
        $estimatedPickupDateLTE: Date
        $estimatedDeliveryDateGTE: Date
        $estimatedDeliveryDateLTE: Date
        $deliveredDateGTE: Date
        $deliveredDateLTE: Date
        $sort: [Sort]
      ) {
        transportConnection(
          auctionTitle: $auctionTitle
          first: $first
          after: $after
          last: $last
          before: $before
          keyword: $keyword
          status: $status
          filterBy: $filterBy
          carrierId: $carrierId
          carrier: $carrier
          createdBy: $createdBy
          sellerId: $sellerId
          seller: $seller
          buyerId: $buyerId
          buyer: $buyer
          pickupCountry: $pickupCountry
          pickupRegion: $pickupRegion
          pickupCity: $pickupCity
          pickupLocation: $pickupLocation
          destinationCountry: $destinationCountry
          destinationRegion: $destinationRegion
          destinationCity: $destinationCity
          destinationLocation: $destinationLocation
          quoteCount: $quoteCount
          estimatedPickupDateGTE: $estimatedPickupDateGTE
          estimatedPickupDateLTE: $estimatedPickupDateLTE
          estimatedDeliveryDateGTE: $estimatedDeliveryDateGTE
          estimatedDeliveryDateLTE: $estimatedDeliveryDateLTE
          deliveredDateGTE: $deliveredDateGTE
          deliveredDateLTE: $deliveredDateLTE
          sort: $sort
        ) {
          ${
            isPaginatedListWithFacets
              ? `
           pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
            totalEdges
          }`
              : ''
          }
          edges {
            node {
              __typename
              ...on TransportRequest {
                ${transportRequest}
              }
              ...on TransportJob {
                id
                transportJobDeclined: declined  # GraphQL requires this to be an alias
                transportJobStatus: status # GraphQL requires this to be an alias
                jobNumber
                activeRequestCount
                quotesCount
                quotesCompleteCount
                acceptedQuote {
                  carrier {
                    name
                  }
                  shipmentMode
                }
                transportRequests {
                  ${transportRequest}
                }
                isCancellable
                vins
                pickupLocations
                destinationLocations
                createdBy {
                  id
                  firstName
                  lastName
                }
                syncedWithSuperDispatch
                created
                updated
              }
            }
          }
          ${isPaginatedListWithFacets ? facetGroups : ''}
        }
      }
      ${locationFragment}
      fragment thumbnailListFrag on Photo {
        main: url (width: 800, height: 600)
        thumb: url (width: 110, height: 110)
      }
    `,
      variables: {
        auctionTitle,
        first,
        after,
        last,
        before,
        keyword,
        status: status ? [status] : null, // TODO: Will need to be changed once backend bug fixed
        filterBy,
        carrierId,
        carrier,
        createdBy,
        sellerId,
        seller,
        buyerId,
        buyer,
        pickupCountry,
        pickupRegion,
        pickupCity,
        pickupLocation,
        destinationCountry,
        destinationRegion,
        destinationCity,
        destinationLocation,
        quoteCount,
        estimatedPickupDateGTE,
        estimatedPickupDateLTE,
        estimatedDeliveryDateGTE,
        estimatedDeliveryDateLTE,
        deliveredDateGTE,
        deliveredDateLTE,
        sort,
      },
    },
  });

export const getFacets = ({
  first = null,
  after = null,
  last = null,
  before = null,
  keyword = null,
  status = null,
  filterBy = null,
  carrierId = null,
  carrier = null,
  createdBy = null,
  sellerId = null,
  seller = null,
  buyerId = null,
  buyer = null,
  pickupCountry = null,
  pickupRegion = null,
  pickupCity = null,
  pickupLocation = null,
  destinationCountry = null,
  destinationRegion = null,
  destinationCity = null,
  destinationLocation = null,
  quoteCount = null,
  estimatedPickupDateGTE = null,
  estimatedPickupDateLTE = null,
  estimatedDeliveryDateGTE = null,
  estimatedDeliveryDateLTE = null,
  deliveredDateGTE = null,
  deliveredDateLTE = null,
  sort = null,
}) =>
  ApiRequest<{ transportConnection: TransportEntityConnection }>({
    data: {
      query: `
      query(
        $first: Int
        $after: String
        $last: Int
        $before: String
        $keyword: String
        $status: [TransportRequestStatus]
        $filterBy: String
        $carrierId: [String]
        $carrier: [String]
        $createdBy: [String]
        $sellerId: [String]
        $seller: [String]
        $buyerId: [String]
        $buyer: [String]
        $pickupCountry: [String]
        $pickupRegion: [String]
        $pickupCity: [String]
        $pickupLocation: [String]
        $destinationCountry: [String]
        $destinationRegion: [String]
        $destinationCity: [String]
        $destinationLocation: [String]
        $quoteCount: [String]
        $estimatedPickupDateGTE: Date
        $estimatedPickupDateLTE: Date
        $estimatedDeliveryDateGTE: Date
        $estimatedDeliveryDateLTE: Date
        $deliveredDateGTE: Date
        $deliveredDateLTE: Date
        $sort: [Sort]
      ) {
        transportConnection(
          first: $first
          after: $after
          last: $last
          before: $before
          keyword: $keyword
          status: $status
          filterBy: $filterBy
          carrierId: $carrierId
          carrier: $carrier
          createdBy: $createdBy
          sellerId: $sellerId
          seller: $seller
          buyerId: $buyerId
          buyer: $buyer
          pickupCountry: $pickupCountry
          pickupRegion: $pickupRegion
          pickupCity: $pickupCity
          pickupLocation: $pickupLocation
          destinationCountry: $destinationCountry
          destinationRegion: $destinationRegion
          destinationCity: $destinationCity
          destinationLocation: $destinationLocation
          quoteCount: $quoteCount
          estimatedPickupDateGTE: $estimatedPickupDateGTE
          estimatedPickupDateLTE: $estimatedPickupDateLTE
          estimatedDeliveryDateGTE: $estimatedDeliveryDateGTE
          estimatedDeliveryDateLTE: $estimatedDeliveryDateLTE
          deliveredDateGTE: $deliveredDateGTE
          deliveredDateLTE: $deliveredDateLTE
          sort: $sort
        ) {
          ${facetGroups}
        }
      }
    `,
      variables: {
        first,
        after,
        last,
        before,
        keyword,
        status: status ? [status] : null, // TODO: Will need to be changed once backend bug fixed
        filterBy,
        carrierId,
        carrier,
        createdBy,
        sellerId,
        seller,
        buyerId,
        buyer,
        pickupCountry,
        pickupRegion,
        pickupCity,
        pickupLocation,
        destinationCountry,
        destinationRegion,
        destinationCity,
        destinationLocation,
        quoteCount,
        estimatedPickupDateGTE,
        estimatedPickupDateLTE,
        estimatedDeliveryDateGTE,
        estimatedDeliveryDateLTE,
        deliveredDateGTE,
        deliveredDateLTE,
        sort,
      },
    },
  });
