import classnames from 'classnames';
import { ReactElement, useMemo } from 'react';

import infoGlyph from 'glyphs/info.svg';

import Sprite, { Glyph } from 'components/ui/shared/sprite';
import Tooltip, { TooltipDescription, TooltipTitle } from 'components/ui/shared/tooltips/tooltip';

import style from './infoTooltip.scss';

interface Props {
  /** CSS styling to overwrite default style. */
  className?: string;
  /** The description of the tooltip to display. */
  description: ReactElement | string;
  /** The glyph rendered with a Sprite */
  glyph?: Glyph;
  /** CSS styling to overwrite default glyph style. */
  glyphClassName?: string;
  /** Space in pixels between the tooltip element and anchor element (arrow not included in calculation) */
  offset?: number;
  /** Position of the tooltip relative to the trigger. */
  position?: 'left' | 'top' | 'right' | 'bottom';
  /** The title of the tooltip */
  title?: string;
}

const InfoTooltip = ({
  className,
  description,
  glyph = infoGlyph,
  glyphClassName,
  offset,
  position = 'top',
  title,
}: Props) => {
  const InfoBubble = useMemo(
    () => (
      <div className={className}>
        <Sprite className={classnames(style.glyph, glyphClassName)} glyph={glyph} />
      </div>
    ),
    [className, glyph, glyphClassName]
  );

  return (
    <Tooltip offset={offset} position={position} trigger={InfoBubble}>
      {title && <TooltipTitle>{title}</TooltipTitle>}
      <TooltipDescription className={style.tooltipText}>{description}</TooltipDescription>
    </Tooltip>
  );
};

export default InfoTooltip;
