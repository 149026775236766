import { useCallback, useMemo } from 'react';
import classnames from 'classnames';

import editNoteGlyph from 'glyphs/edit-note.svg';
import printGlyph from 'glyphs/print.svg';

import AuctionItem from 'constants/auctionItem';
import Button from 'components/ui/shared/button';
import Share from 'components/sections/auctionItem/operations/share/share';
import Sprite from 'components/ui/shared/sprite';
import WatchButton from 'components/ui/lists/listItem/watchButton';
import { UserAction } from 'logging/analytics/events/userActions';
import { getGraphBaseURL } from 'utils/apiUtils';
import { joinStrings } from 'utils/stringUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';

import style from './detailsHeaderActionButtons.scss';

export interface Props {
  /** The auction item. */
  auctionItem: AuctionItem;
  /** Function invoked when notes button clicked. */
  toggleNotes: () => void;
}

const DetailsHeaderActionButtons = ({ auctionItem, toggleNotes }: Props) => {
  const renderWatchButton = useMemo(() => {
    const isWatched = auctionItem.watchers?.isWatched;
    return (
      <WatchButton
        auctionItemId={auctionItem?.id}
        className={classnames(style.button, style.watch, { [style.watched]: isWatched })}
        glyphClassName={classnames(style.sprite, { [style.watched]: isWatched })}
        hideLabel
        isWatched={!!isWatched}
      />
    );
  }, [auctionItem?.id, auctionItem.watchers?.isWatched]);

  const renderNotesButton = useMemo(() => {
    const numOfNotes = Number(auctionItem.inventoryItem.notes?.length);
    const hasNotes = numOfNotes > 0;
    return (
      <Button
        className={classnames(style.button, { [style.notesAvailable]: hasNotes })}
        dataTestId="notes-button"
        onClick={toggleNotes}
        theme="none"
      >
        <Sprite
          className={classnames(style.sprite, style.notes, { [style.hasNotes]: hasNotes })}
          glyph={editNoteGlyph}
        />
        {hasNotes && (
          <span className={style.notesCount} data-testid="notes-count-badge">
            {numOfNotes}
          </span>
        )}
      </Button>
    );
  }, [auctionItem.inventoryItem.notes?.length, toggleNotes]);

  const renderShareButton = useMemo(() => {
    const { year, make, model, trim } = auctionItem.inventoryItem;
    const title = joinStrings([year, make, model, trim], ' ');

    return (
      <Share auctionItemId={auctionItem.id} inventoryItemId={auctionItem.inventoryItem.id} isCondensed title={title} />
    );
  }, [auctionItem.id, auctionItem.inventoryItem]);

  const onPrintClick = useCallback(() => {
    trackUserActionWithAuctionItemAttributes(UserAction.VDP_PRINT_CLICK, auctionItem);
    window.open(`${getGraphBaseURL()}${auctionItem.inventoryItem.printableUrl}`);
  }, [auctionItem]);

  return (
    <div className={style.buttonContainer}>
      {renderWatchButton}
      {renderNotesButton}
      {renderShareButton}
      <Button className={style.button} onClick={onPrintClick} theme="none">
        <Sprite className={classnames(style.sprite, style.print)} glyph={printGlyph} />
      </Button>
    </div>
  );
};

export default DetailsHeaderActionButtons;
