import classnames from 'classnames';
import { ReactEventHandler, ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';

import ArchiveGlyph from 'glyphs/archive.svg';
import BuyerShieldGlyph from 'glyphs/buyerShield.svg';
import VehicleGlyph from 'glyphs/default-vehicle.svg';

import AuctionItem from 'constants/auctionItem';
import Button, { ButtonTheme } from 'components/ui/shared/button';
import BuyNow, { BuyNowVariant } from 'components/sections/auctionItem/operations/buyNow/buyNow';
import MakeOffer, { MakeOfferVariant } from 'components/sections/auctionItem/operations/makeOffer/makeOffer';
import Odometer from 'components/ui/animation/odometer';
import RemoveAutobid from 'components/sections/auctionItem/operations/removeAutobid/removeAutobid';
import SaleLights from 'components/ui/lists/listItem/saleLights';
import ScoreBadge from 'components/ui/shared/scores/scoreBadge';
import SetAutobid from 'components/sections/auctionItem/operations/setAutobid/setAutobid';
import Sprite from 'components/ui/shared/sprite';
import WatchButton from 'components/ui/lists/listItem/watchButton';
import { AppState } from 'store/configureStore';
import {
  AsIsBadge,
  AssuredBadge,
  AutoBidBadge,
  Badges,
  BuyerShieldBadge,
  HoldbackActiveBadge,
  NoteBadge,
  UnreservedBadge,
  VerifiedBadge,
  WarningBadge,
  WatchedBadge,
} from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { AuctionItemStatus } from 'store/shared/api/graph/interfaces/types';
import { PrimaryTitle } from 'layouts/list/listItemLayout';
import { VehicleScore } from 'utils/inventoryItemUtils';
import { isAuctionStaff } from 'utils/userUtils';
import { isUpcomingManual } from 'utils/auctionItemUtils';
import { replaceLastNChars } from 'utils/stringUtils';
import { t } from 'utils/intlUtils';

import style from './body.scss';

/**
 * Badge options associated with the auction item.
 * True values will render the corresponding badge.
 */
interface BadgeSet {
  archived?: boolean;
  asIs?: boolean;
  buyerShield?: boolean;
  hasAutobids?: boolean;
  hasDeclarations?: boolean;
  hasNotes?: boolean;
  hasScore?: boolean;
  isAssured?: boolean;
  isHoldbackActive?: boolean;
  isReserveMet?: boolean;
  isVerified?: boolean;
  isWatched?: boolean;
}

interface Props {
  /** The current bid amount of the auction item. */
  amount?: string;
  /** True to apply animation style. */
  animateAmount?: boolean;
  /** The auction id */
  auctionId?: string;
  /** The auction item. */
  auctionItem?: AuctionItem;
  /** Available badges for the auction item. */
  badges: BadgeSet;
  /** The badges className to overwrite default styles */
  badgesClassName?: string;
  /** The content of the button. Sprite, svg animations, button label, etc can be rendered. */
  buttonContent?: ReactNode;
  /** True when the button is disabled. */
  buttonDisabled?: boolean;
  /** True to disable amount change animation. */
  disableAnimateAmount?: boolean;
  /** True when fill style should be applied to button. */
  fillButton?: boolean;
  /** True when highlight style should be applied to title. (Default: false) */
  highlightTitle?: boolean;
  /** The mileage of the auction item. */
  mileage?: string;
  /** Function invoked when button is clicked. */
  onButtonClick?: () => void;
  /** Function invoked when error occurs rendering thumbnail image. */
  onThumbnailError: ReactEventHandler<HTMLImageElement>;
  /** True will display bid amount. (Default: true) */
  shouldDisplayBidAmount?: boolean;
  /** The subtitle of the list item. */
  subtitle: string;
  /** The theme style to be applied. */
  theme?: ButtonTheme;
  /** The image url to render. */
  thumbnail?: string;
  /** The text to be displayed on the thumbnail. */
  thumbnailText?: string;
  /** The title of the list item. */
  title: string;
  /** Prefix component of the title. */
  titlePrefix?: ReactNode;
  /** The vehicle score */
  vehicleScore?: VehicleScore;
  /** The vin of the auction item. */
  vin?: string;
}

const Body = ({
  amount = '',
  animateAmount,
  auctionId,
  auctionItem,
  badges,
  badgesClassName,
  buttonContent,
  buttonDisabled,
  disableAnimateAmount,
  fillButton,
  highlightTitle = false,
  mileage,
  onButtonClick,
  onThumbnailError,
  shouldDisplayBidAmount = true,
  subtitle,
  theme = 'green-outline',
  thumbnail,
  thumbnailText,
  title,
  titlePrefix,
  vehicleScore,
  vin,
}: Props) => {
  const user = useSelector((state: AppState) => state.app.user);
  const isStaffUser = useMemo(
    () => isAuctionStaff(user, auctionId || auctionItem?.auction?.id),
    [auctionId, auctionItem?.auction?.id, user]
  );
  const formattedVin = useMemo(() => (isStaffUser ? vin : replaceLastNChars(vin ?? '', '*', 6)), [isStaffUser, vin]);

  /**
   * Renders the associated US or CA score badge.
   * */
  const renderScoreBadge = useMemo(() => {
    if (!vehicleScore) {
      return null;
    }

    return <ScoreBadge {...vehicleScore} />;
  }, [vehicleScore]);

  /**
   * Renders the autobid button.
   * */
  const renderAutobid = useMemo(() => {
    if (auctionItem) {
      const { isMyItem, autoBids } = auctionItem;
      const hasAutobid = autoBids?.myAutoBid;

      if ((!hasAutobid && !isMyItem) || isStaffUser) {
        return <SetAutobid auctionItem={auctionItem} className={style.autobidButton} isListView />;
      }
      if (hasAutobid && !isMyItem) {
        return <RemoveAutobid auctionItem={auctionItem} className={style.autobidButton} isListView />;
      }
    }
    return null;
  }, [auctionItem, isStaffUser]);

  /**
   * Renders the watch button.
   * */
  const renderWatch = useMemo(() => {
    if (!auctionItem) {
      return null;
    }

    return (
      <WatchButton
        auctionItemId={auctionItem.id}
        className={style.watchButton}
        isListView
        isWatched={!!auctionItem.watchers?.isWatched}
      />
    );
  }, [auctionItem]);

  /**
   * Renders the auction lights for physical items in the run list.
   */
  const renderAuctionLights = useMemo(
    () =>
      auctionItem && isUpcomingManual(auctionItem) ? (
        <SaleLights saleLights={auctionItem?.saleLights?.filter(Boolean)} />
      ) : null,
    [auctionItem]
  );

  /**
   * Renders buy now button.
   */
  const renderBuyNowButton = useMemo(
    () => <BuyNow auctionItem={auctionItem} variant={BuyNowVariant.ITEM_LIST} />,
    [auctionItem]
  );

  /**
   * Renders make offer button.
   */
  const renderMakeOfferButton = useMemo(
    () => <MakeOffer auctionItem={auctionItem} variant={MakeOfferVariant.ITEM_LIST} />,
    [auctionItem]
  );

  return (
    <div className={style.container}>
      <div
        className={classnames(style.body, {
          [style[theme]]: true,
        })}
      >
        <div className={style.thumbnail}>
          {thumbnail ? (
            <img alt={t('vehicle')} onError={onThumbnailError} src={thumbnail} />
          ) : (
            <Sprite className={style.noThumbnail} glyph={VehicleGlyph} />
          )}
          <div className={classnames({ [style.gradientBg]: thumbnail })}>{thumbnailText}</div>
          {badges.archived && <Sprite className={style.archived} glyph={ArchiveGlyph} />}
        </div>
        <div className={style.details}>
          <div className={classnames(style.titleSection, { [style.shouldDisplayBidAmount]: shouldDisplayBidAmount })}>
            <div>
              <PrimaryTitle
                className={classnames(style.title, { [style.highlighted]: highlightTitle })}
                title={[titlePrefix, title].join('')}
              >
                {titlePrefix}
                {title}
              </PrimaryTitle>
              <h2 title={subtitle}>{subtitle}</h2>
            </div>
            {shouldDisplayBidAmount && (
              <div className={style.bid}>
                {renderAuctionLights}
                {disableAnimateAmount ? (
                  <div className={style.amount}>{amount}</div>
                ) : (
                  <Odometer className={classnames(style.amount, { [style.animate]: animateAmount })}>{amount}</Odometer>
                )}
              </div>
            )}
          </div>
          {mileage && (
            <span className={style.mileage} data-testid="mileage">
              {mileage}
            </span>
          )}
          <Badges className={classnames(style.badges, badgesClassName)}>
            {!!badges.hasDeclarations && <WarningBadge />}
            {!!badges.isAssured && <AssuredBadge />}
            {!!badges.buyerShield && <BuyerShieldBadge glyph={BuyerShieldGlyph} />}
            {!!badges.isVerified && <VerifiedBadge />}
            {!!badges.hasAutobids && <AutoBidBadge />}
            {!!badges.isWatched && <WatchedBadge />}
            {!!badges.hasNotes && <NoteBadge />}
            {!!badges.isHoldbackActive && <HoldbackActiveBadge />}
            {!!badges.asIs && <AsIsBadge />}
            {!!badges.hasScore && renderScoreBadge}
            {!!badges.isReserveMet && <UnreservedBadge />}
          </Badges>
          {formattedVin && (
            <div className={style.vinContainer}>
              <div className={style.vin} data-testid="vin">
                {formattedVin}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={classnames(style[theme], style.buttons)}>
        {renderBuyNowButton}
        {renderMakeOfferButton}
        {buttonContent && !onButtonClick && (
          <div className={classnames(style.pseudoButton, { [style.fill]: fillButton })}>{buttonContent}</div>
        )}
        {buttonContent && onButtonClick && (
          <Button
            className={style.button}
            disabled={buttonDisabled}
            onClick={(event) => {
              event.preventDefault();
              onButtonClick();
            }}
            theme={fillButton ? theme : 'green'}
          >
            {buttonContent}
          </Button>
        )}
        {auctionItem?.status === AuctionItemStatus.UPCOMING && (
          <>
            {renderAutobid}
            {renderWatch}
          </>
        )}
      </div>
    </div>
  );
};

export default Body;
