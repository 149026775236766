import transferGlyph from 'glyphs/operationIcons/transfer.svg';

import AuctionItem from 'constants/auctionItem';
import BaseClass from 'components/ui/shared/base';
import Dialog from './dialog';
import OperationButton from 'components/sections/inventoryItem/operations/operationButton';
import { MutationauctionItemTransferSaleArgs } from 'store/shared/api/graph/interfaces/types';
import { ErrorMessages } from 'constants/errors';
import { RouterProps, withRouter } from 'constants/reactRouter';
import { UserAction } from 'logging/analytics/events/userActions';
import { getVehiclePath } from 'components/sections/admin/adminHelpers';
import { t } from 'utils/intlUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';
import { transferSale } from 'store/auctionItemDetails/auctionItemDetailsApi';

interface Props extends RouterProps {
  /** The auction item to transfer sale. */
  auctionItem: AuctionItem;
}

interface State {
  /** Error messages. */
  errorMessages: ErrorMessages | undefined;
  /** Whether the dialog is open or not. */
  isOpen: boolean;
  /** Whether is progressing or not. */
  progress: boolean;
}

class TransferSale extends BaseClass<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { isOpen: false, errorMessages: undefined, progress: false };
  }

  onSubmit = (shouldSubmit: boolean, options: MutationauctionItemTransferSaleArgs) => {
    if (shouldSubmit) {
      const { auctionItem, router } = this.props;

      this.setState({ progress: true });
      trackUserActionWithAuctionItemAttributes(UserAction.VDP_TRANSFER_SALE_CONFIRM_CLICK, auctionItem);

      transferSale(options)
        .then((response) => {
          const updatedAuctionItem = response?.data?.data?.auctionItemTransferSale;
          const vehiclePath = getVehiclePath(updatedAuctionItem);
          this.setState({ errorMessages: null, isOpen: false, progress: false });
          router.push(vehiclePath);
        })
        .catch(this.onApiError)
        .finally(() => this.setState({ progress: false }));
    } else {
      this.setState({ errorMessages: null, isOpen: false });
    }
  };

  render() {
    const { auctionItem } = this.props;

    return (
      <>
        <OperationButton
          glyph={transferGlyph}
          onClick={() => {
            this.setState({ isOpen: true });
            trackUserActionWithAuctionItemAttributes(UserAction.VDP_TRANSFER_SALE_CLICK, auctionItem);
          }}
          theme="blue"
        >
          {t('transfer_sale')}
        </OperationButton>
        <Dialog auctionItem={auctionItem} onSubmit={this.onSubmit} {...this.state} />
      </>
    );
  }
}

export default withRouter(TransferSale);
