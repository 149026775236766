import { useCallback, useEffect, useMemo, useState } from 'react';

import BuyerShieldGlyph from 'glyphs/buyerShield.svg';
import ImagesGlyph from 'glyphs/images.svg';
import LiveLanesGlyph from 'glyphs/live-lanes.svg';
import VehicleGlyph from 'glyphs/default-vehicle.svg';

import AuctionBuyerSellerChat from 'components/sections/auctionChat/auctionBuyerSellerChat';
import AuctionItem from 'constants/auctionItem';
import AuctionVideoStream from 'components/sections/liveLanes/auctionVideoStream/auctionVideoStream';
import Button from 'components/ui/shared/button';
import DetailsHeaderActionButtons from 'components/sections/auctionItem/details/detailsHeaderActionButtons';
import Events from 'components/sections/auctionItem/details/events/events';
import EventsStatsHeader from 'components/sections/auctionItem/details/events/eventsStatsHeader';
import InventoryItemNotes from 'components/sections/inventoryItem/details/inventoryItemNotes';
import SaleLights, { EditType } from 'components/ui/shared/saleLights/saleLights';
import ScoreBadge from 'components/ui/shared/scores/scoreBadge';
import Sprite from 'components/ui/shared/sprite';
import ToggleVerticalPanelSize from 'components/ui/panels/toggleVerticalPanelSize';
import User from 'constants/user';
import {
  AssuredBadge,
  AutoBidBadge,
  Badges,
  BuyerShieldBadge,
  HoldbackActiveBadge,
  UnreservedBadge,
  VerifiedBadge,
  WarningBadge,
  WatchedBadge,
} from 'components/sections/inventoryItem/details/inventoryItemBadges';
import { LiveAuctionItem } from 'store/shared/api/graph/interfaces/types';
import { LooseObject } from 'constants/objects';
import { PrimaryTitle } from 'layouts/list/listItemLayout';
import { UserAction } from 'logging/analytics/events/userActions';
import { VideoControlPosition } from 'components/ui/media/video/videoStream';
import { getEnabledCompanyIds, isAuctionStaff } from 'utils/userUtils';
import { getVehicleScoreByInventoryItem } from 'utils/inventoryItemUtils';
import { trackUserActionWithAuctionItemAttributes } from 'utils/analyticsUtils';
import { useLiveLanesVideoStream } from 'contexts/liveLanesVideoStreamContext';

import style from './physicalDetailsHeader.scss';

interface Props {
  /** The current auction item displayed. */
  auctionItem: AuctionItem;
  /** A list of badges that can be displayed. */
  badges: {
    archived?: boolean;
    buyerShield?: boolean;
    hasAutobids?: boolean;
    hasDeclarations?: boolean;
    hasNotes?: boolean;
    hasScore?: boolean;
    isAssured?: boolean;
    isHoldbackActive?: boolean;
    isReserveMet?: boolean;
    isVerified?: boolean;
    isWatched?: boolean;
  };
  /** Callback function to handle feature clicked event. */
  handleFeatureClicked: (featureType: string, inventoryItemDetails?: LooseObject) => void;
  /** The live auction item that is updated from events that is currently under auction in the lane. */
  liveItem?: LiveAuctionItem;
  /** An url of the vehicle image. */
  photoUrl?: string;
  /** The subtitle to be displayed. */
  subtitle: string;
  /** The title to be displayed. */
  title: string;
  /** The title prefix to be displayed. */
  titlePrefix?: string;
  /** The current user. */
  user: User;
}

const PhysicalDetailsHeader = ({
  auctionItem,
  badges,
  handleFeatureClicked,
  liveItem,
  photoUrl,
  subtitle,
  title,
  titlePrefix,
  user,
}: Props) => {
  const [isImageLoaded, setImageLoaded] = useState<boolean>(false);
  const [isNotesVisible, setNotesVisible] = useState<boolean>(false);
  const isSeller = getEnabledCompanyIds(user)?.includes(auctionItem?.inventoryItem?.company?.id);
  const videoStreamState = useLiveLanesVideoStream();

  /**
   * When image onLoad sets image loaded property.
   */
  useEffect(() => {
    setImageLoaded(false);
    if (photoUrl) {
      const img = new Image();
      img.src = photoUrl;
      img.onload = () => setImageLoaded(true);
    }
  }, [photoUrl]);

  /**
   * Memoized photo collection for photo slide out
   */
  const photoCollection = useMemo(() => {
    if (auctionItem?.inventoryItem) {
      const {
        damagePhotos = [],
        interiorPhotos = [],
        photos: exteriorPhotos = [],
        undercarriagePhotos = [],
      } = auctionItem.inventoryItem;
      const formattedDamagePhotos = damagePhotos.map((photo) => ({ ...photo, isDamagePhoto: true }));
      return [...formattedDamagePhotos, ...exteriorPhotos, ...undercarriagePhotos, ...interiorPhotos];
    }
    return [];
  }, [auctionItem?.inventoryItem]);

  /**
   * Open the photo slide out
   */
  const openPhotoSlideOut = useCallback(() => {
    const activePhotoIndex = photoCollection.findIndex(
      (photo) => photoUrl === photo.main || photoUrl === photo.expanded
    );

    handleFeatureClicked('PHOTOS', {
      photos: photoCollection,
      scrollIndex: activePhotoIndex,
    });
  }, [handleFeatureClicked, photoCollection, photoUrl]);

  /**
   * Render a score badge if a vehicle score exists.
   */
  const renderScoreBadge = useMemo(() => {
    if (auctionItem?.inventoryItem) {
      const inventoryItem = auctionItem.inventoryItem;
      const vehicleScore = getVehicleScoreByInventoryItem(inventoryItem);

      return <ScoreBadge {...vehicleScore} />;
    }
    return null;
  }, [auctionItem]);

  return (
    <div className={style.headerContainer} data-testid="physical-header">
      <div className={style.vehicleDetails}>
        <div className={style.top}>
          {(isAuctionStaff(user, auctionItem?.auction?.id) || auctionItem.saleLights?.length) && (
            <SaleLights
              activeSaleLights={auctionItem.saleLights?.filter(Boolean) || undefined}
              auctionItemId={auctionItem.id}
              editType={EditType.NONE}
            />
          )}
          <Sprite className={style.liveLanesLogo} glyph={LiveLanesGlyph} />
        </div>
        <div className={style.details}>
          <div className={style.innerDetails}>
            <div className={style.info}>
              <PrimaryTitle className={style.title} title={[titlePrefix, title].join('')}>
                {titlePrefix}
                {title}
              </PrimaryTitle>
              <h2 title={subtitle}>{subtitle}</h2>
              <div className={style.mileage}>{auctionItem?.inventoryItem?.mileage?.formattedAmount}</div>
            </div>
            <div className={style.actions}>
              <DetailsHeaderActionButtons
                auctionItem={auctionItem}
                toggleNotes={() => {
                  setNotesVisible(!isNotesVisible);
                  if (!isNotesVisible) {
                    trackUserActionWithAuctionItemAttributes(UserAction.INTERNAL_NOTES_CLICK, auctionItem);
                  }
                }}
              />
            </div>
          </div>
          <Badges className={style.badges}>
            {!!badges.hasDeclarations && <WarningBadge />}
            {!!badges.isAssured && <AssuredBadge />}
            {!!badges.buyerShield && <BuyerShieldBadge glyph={BuyerShieldGlyph} />}
            {!!badges.isVerified && <VerifiedBadge />}
            {!!badges.hasAutobids && <AutoBidBadge />}
            {!!badges.isWatched && <WatchedBadge />}
            {!!badges.isHoldbackActive && <HoldbackActiveBadge />}
            {!!badges.hasScore && renderScoreBadge}
            {!!badges.isReserveMet && <UnreservedBadge />}
          </Badges>
        </div>
        <div className={style.photoContainer}>
          <Sprite className={style.defaultVehicleIcon} glyph={VehicleGlyph} />
          {!!isImageLoaded && <div className={style.photo} style={{ backgroundImage: `url(${photoUrl})` }} />}
          <AuctionVideoStream
            auctionTimeSlotId={auctionItem?.auctionTimeSlot?.id ?? ''}
            auctionTimeSlotLaneId={auctionItem?.auctionTimeSlotLane?.id ?? ''}
            className={style.streamVideo}
            controlPosition={VideoControlPosition.BOTTOM}
            onToggleFullScreenOverride={videoStreamState?.toggleFullScreenOverride}
            shouldUpdateFocusedLane
            videoStreamConfig={auctionItem?.auction?.videoStreamConfig}
          />
          {photoCollection.length && (
            <Button className={style.photosButton} dataTestId="photos-button" onClick={openPhotoSlideOut} theme="none">
              <Sprite className={style.sprite} glyph={ImagesGlyph} />+{photoCollection.length}
            </Button>
          )}
          {auctionItem?.inventoryItem?.notes && isNotesVisible && (
            <InventoryItemNotes
              inventoryItemId={auctionItem.inventoryItem.id}
              isAuctionItem
              notes={auctionItem.inventoryItem.notes}
              onClose={() => setNotesVisible(false)}
            />
          )}
        </div>
      </div>
      <ToggleVerticalPanelSize>
        <div className={style.events}>
          <EventsStatsHeader
            isSeller={isAuctionStaff(user) || !!isSeller}
            reserve={auctionItem?.reserve || undefined}
            reserveMet={auctionItem?.reserveMet}
            topOffer={auctionItem?.topOffer || undefined}
          />
          <Events
            auctionItemId={auctionItem.id}
            auctionTimeSlotLaneId={auctionItem.auctionTimeSlotLane?.id}
            shouldDisplayAllEvents
            user={user}
          />
        </div>
        <AuctionBuyerSellerChat
          auctionItem={auctionItem}
          auctionTimeSlotLaneId={auctionItem?.auctionTimeSlotLane?.id || undefined}
          currentBidAmount={liveItem?.atAmount || undefined}
        />
      </ToggleVerticalPanelSize>
    </div>
  );
};

export default PhysicalDetailsHeader;
