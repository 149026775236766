import classnames from 'classnames';
import { HTMLAttributes, MouseEventHandler, ReactNode } from 'react';

import externalGlyph from 'glyphs/external-link.svg';

import Button from 'components/ui/shared/button';
import Link from 'components/ui/shared/link';
import Sprite from 'components/ui/shared/sprite';
import { Chevron } from 'components/ui/shared/chevrons';

import style from './linkRow.scss';

interface LinkRowsProps extends HTMLAttributes<HTMLDivElement> {}

export const LinkRows = ({ children, ...props }: LinkRowsProps) => <div {...props}>{children}</div>;

interface LinkRowProps {
  /** CSS style to override default style. */
  className?: string;
  /** Test id */
  dataTestId?: string;
  /** Whether the link row is disabled or not. */
  disabled?: boolean;
  /** The label of the link row. */
  label: string | ReactNode;
  /** Overwrite default link icon  */
  linkIcon?: ReactNode;
  /** Whether the url is external or not. */
  isExternalUrl?: boolean;
  /** Callback function to trigger on click. */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  /** The url to navigate to. */
  url?: string;
}

const LinkRow = ({
  className,
  dataTestId,
  label,
  linkIcon = <Chevron className={style.chevron} />,
  isExternalUrl = false,
  url = '',
  ...props
}: LinkRowProps) => {
  if (url) {
    return isExternalUrl ? (
      <a
        className={classnames(style.linkRow, className)}
        data-testid={'row-link' || dataTestId}
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        {...props}
      >
        {label}
        <Sprite className={style.external} glyph={externalGlyph} />
      </a>
    ) : (
      <Link className={classnames(style.linkRow, className)} to={url} {...props}>
        {label}
        {linkIcon}
      </Link>
    );
  }

  return (
    <Button
      className={classnames(style.linkRow, className)}
      dataTestId={dataTestId || `${label}-button`}
      theme="none"
      {...props}
    >
      <div>{label}</div>

      {!props?.disabled && linkIcon}
    </Button>
  );
};

interface LinkRowLightProps extends LinkRowProps {}

export const LinkRowLight = ({ className, ...props }: LinkRowLightProps) => (
  <LinkRow {...props} className={classnames(style.linkRowLight, className)} />
);

export default LinkRow;
