import { useMemo } from 'react';

import Loading from 'components/ui/loading/loading';
import NoResults from 'components/ui/noResults/noResults';
import SlideOut from 'components/ui/slideOuts/slideOut';
import User from 'constants/user';
import UserImage from 'components/ui/shared/images/userImage';
import { ListItem, ListItemBody, SecondaryTitle, TertiaryTitle } from 'layouts/list/listItemLayout';
import { Route } from 'store/routing/routes';
import { formatUserName } from 'utils/stringUtils';
import { hasSystemPermission } from 'utils/userUtils';
import { t } from 'utils/intlUtils';

import style from './viewedBySlideOut.scss';

interface Props {
  /** True when the slide out is loading. */
  isLoading?: boolean;
  /** True when the slide out is open. */
  isOpen?: boolean;
  /** Function invoked on slide out close. */
  onClose?: () => void;
  /** The current user details. */
  user: User;
  /** The users that have viewed the auction item. */
  viewers: User[];
}

const ViewedBySlideOut = ({ isLoading, isOpen, onClose, user, viewers }: Props) => {
  /**
   * Check if the current user has permission to view users.
   */
  const hasPermission = useMemo(() => hasSystemPermission(user, ['SYSTEM_VIEW_USERS']), [user]);

  /**
   * Render list of viewers.
   */
  const renderList = useMemo(() => {
    if (!viewers.length) {
      return <NoResults />;
    }

    return viewers.map((viewer) => (
      <ListItem
        key={`viewed-by-${viewer.id}`}
        className={style.listItem}
        isLink={hasPermission}
        to={`${Route.ADMIN_USERS}?id=${viewer.id}`}
      >
        <ListItemBody className={style.body}>
          <UserImage containerClassName={style.img} imageUrl={viewer?.photoUrl || undefined} />
          <div className={style.details}>
            <SecondaryTitle>{formatUserName(viewer)}</SecondaryTitle>
            {viewer.companyRelationships?.list[0]?.company?.name && (
              <TertiaryTitle>{viewer.companyRelationships?.list[0]?.company?.name}</TertiaryTitle>
            )}
          </div>
        </ListItemBody>
      </ListItem>
    ));
  }, [hasPermission, viewers]);

  return (
    <SlideOut contentInnerClassName={style.content} isOpen={isOpen} onClose={onClose} title={t('viewed_by')}>
      {renderList}
      <Loading hasFullWidth isLoading={isLoading} isModalTransparent />
    </SlideOut>
  );
};

export default ViewedBySlideOut;
