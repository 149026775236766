import classnames from 'classnames';
import { MouseEventHandler } from 'react';

import AuctionLocationRow from 'components/sections/auctionItem/details/status/statusRows/auctionLocationRow';
import AuditLogRow from 'components/sections/auctionItem/details/status/statusRows/auditLogRow';
import BaseClass from 'components/ui/shared/base';
import BidsRow from 'components/sections/auctionItem/details/status/statusRows/bidsRow';
import BuyNowPriceRow from 'components/sections/auctionItem/details/status/statusRows/buyNowPriceRow';
import IfBidsRow from 'components/sections/auctionItem/details/status/statusRows/ifBidsRow';
import RanBeforeRow from 'components/sections/auctionItem/details/status/statusRows/ranBeforeRow';
import ReservePriceRow from 'components/sections/auctionItem/details/status/statusRows/reservePriceRow';
import StaffUserRows from 'components/sections/auctionItem/details/status/statusRows/staffUserRows/staffUserRows';
import User from 'constants/user';
import WorkflowProcessRows from 'components/sections/auctionItem/details/status/statusRows/workflowProcessRows';
import { AuctionItemDetailsProps } from 'store/auctionItemDetails/auctionItemDetailsModels';
import { Row, Rows } from 'components/sections/auctionItem/details/status/statusRows/statusRows';
import { formatCalendarDate, formatCalendarTime } from 'utils/dateUtils';
import { getAuctionNameByDetails } from 'utils/formatting/auctionItemFormatUtils';
import { getEnabledCompanyIds, isAuctionStaff } from 'utils/userUtils';
import { getGraphBaseURL } from 'utils/apiUtils';
import { isAuctionFormat } from 'utils/auctionItemUtils';
import { t } from 'utils/intlUtils';

import style from './sold.scss';

interface Props {
  /** The auction item details. */
  details: AuctionItemDetailsProps;
  /** Click handler for show audit log. */
  onShowAuditLog: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show auto-bids. */
  onShowAutobids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show bids. */
  onShowBids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show buyer. */
  onShowBuyer: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show if bids. */
  onShowIfBids: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show ran before. */
  onShowRanBefore: MouseEventHandler<HTMLButtonElement>;
  /** Click handler for show seller. */
  onShowSeller: MouseEventHandler<HTMLButtonElement>;
  /** The current user. */
  user: User;
}

class Sold extends BaseClass<Props> {
  render() {
    const {
      user,
      details,
      onShowAutobids,
      onShowBuyer,
      onShowIfBids,
      onShowSeller,
      onShowRanBefore,
      onShowBids,
      onShowAuditLog,
    } = this.props;

    if (!details.transactionDetails) {
      return null;
    }

    const { guaranteedAuctionPrice, inventoryItem, isMyItem, transactionDetails } = details;
    const { buyBillOfSale, sellBillOfSale } = transactionDetails;

    const auctionName = getAuctionNameByDetails(details);
    const dateRan = formatCalendarTime(details.ranOn, 'MMMM DD, YYYY [at] h:mm A');
    const estimatedDelivery = details.deliveryDate && formatCalendarDate(details.deliveryDate, false);
    const transactionDate = formatCalendarDate(details.transactionDetails.created, false);
    const isAuction = isAuctionFormat(details.format);
    const isStaffUser = isAuctionStaff(user, details?.auction?.id);

    return (
      <div className={classnames(style.sold)} data-testid="sold-rows">
        <WorkflowProcessRows details={details} user={user} />
        <Rows>
          {!isMyItem && (
            <Row mergeNext name={t('purchased_from')} onClick={onShowSeller}>
              {inventoryItem.company.name}
            </Row>
          )}
          {!isMyItem && isStaffUser && (
            <Row dataTestId="status-row-sold-to" mergeNext name={t('sold_to')} onClick={onShowBuyer}>
              {transactionDetails.buyerCompany?.name || details?.buyer?.company?.name}
            </Row>
          )}
          {isMyItem && (
            <Row dataTestId="status-row-sold-to" mergeNext name={t('sold_to')} onClick={onShowBuyer}>
              {transactionDetails.buyerCompany?.name}
            </Row>
          )}
          <Row
            dataTestId="status-row-transaction"
            mergeNext
            name={t('transaction')}
          >{`#${transactionDetails.transactionNumber}`}</Row>
          <Row mergeNext={!!details?.order?.buyerPickupLocation} name={isMyItem ? t('date_sold') : t('date_purchased')}>
            {transactionDate}
          </Row>

          {details?.order?.buyerPickupLocation?.name && (
            <Row dataTestId="status-row-pickup-location" name={t('pickup_location')}>
              {details?.order?.buyerPickupLocation?.name}
            </Row>
          )}
          {details?.order?.paymentType &&
            (isStaffUser ||
              (details?.buyer?.company?.id && getEnabledCompanyIds(user)?.includes(details?.buyer?.company?.id))) && (
              <Row mergeNext name={t('payment_method')}>
                {details?.order?.paymentType?.name || '-'}
              </Row>
            )}

          <Row mergeNext name={t(isMyItem ? 'vehicle_sell_price' : 'vehicle_purchase_price')}>
            {transactionDetails.purchasePrice.formattedAmount}
          </Row>

          {(isStaffUser ||
            (details?.buyer?.company?.id && getEnabledCompanyIds(user)?.includes(details?.buyer?.company?.id))) &&
            details?.order?.lineItems
              ?.filter(
                (auctionItemOrderLineItem) => auctionItemOrderLineItem?.optional && auctionItemOrderLineItem?.selected
              )
              ?.map((auctionItemOrderLineItem) => (
                <Row
                  key={auctionItemOrderLineItem?.id}
                  mergeNext
                  name={
                    auctionItemOrderLineItem?.auctionService?.serviceMetadata?.feeName ??
                    auctionItemOrderLineItem?.inventoryService?.serviceMetadata?.feeName
                  }
                >
                  {auctionItemOrderLineItem?.amount?.formattedAmount || '-'}
                </Row>
              ))}

          <Row
            className={style.total}
            dataTestId="status-row-total-price"
            description={details?.order?.comment || undefined}
            name={t('total')}
          >
            {isMyItem ? transactionDetails.sellTotal?.formattedAmount : transactionDetails.buyTotal?.formattedAmount}
          </Row>

          {isStaffUser && (
            <>
              <Row
                mergeNext
                name={t('buyer_invoice')}
                onClick={() => window.open(`${getGraphBaseURL()}${buyBillOfSale}`)}
              />
              <Row name={t('seller_invoice')} onClick={() => window.open(`${getGraphBaseURL()}${sellBillOfSale}`)} />
            </>
          )}

          <IfBidsRow details={details} onClick={onShowIfBids} />
          <BidsRow details={details} onClick={isAuction ? onShowBids : undefined} />
          <StaffUserRows auctionItem={details} onShowAutobids={onShowAutobids} />
          <Row name={t('auction')}>{auctionName}</Row>
          {guaranteedAuctionPrice && (
            <Row dataTestId="status-row-gap" name={t('guaranteed_auction_price')}>
              {guaranteedAuctionPrice?.amount === -1 ? '-' : guaranteedAuctionPrice?.formattedAmount}
            </Row>
          )}
          <AuctionLocationRow inventoryItem={details?.inventoryItem} />
          <Row name={t('date_ran')}>{dateRan}</Row>
          {estimatedDelivery && <Row name={t('est_vehicle_delivery')}>{estimatedDelivery}</Row>}
          <ReservePriceRow auctionItem={details} user={user} />
          <BuyNowPriceRow auctionItem={details} user={user} />
          <RanBeforeRow details={details} onClick={onShowRanBefore} />
          <AuditLogRow auctionId={details?.auction?.id} onClick={onShowAuditLog} user={user} />
        </Rows>
      </div>
    );
  }
}

export default Sold;
