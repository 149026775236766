import { List } from 'immutable';

import AuctionItemType from 'constants/auctionItem';
import ServerRecord, { ServerRecordOf } from 'store/shared/models/serverRecord';
import { QueryAuctionSubmissionList } from './auctionSubmissionApi';

interface AuctionItemModel {
  /** The auction item currently being auctioned */
  auctionItem: AuctionItemType | null;

  /** A list of auction */
  list: List<QueryAuctionSubmissionList['edges'][number]> | null;
}

export type AuctionItemProps = ServerRecordOf<AuctionItemModel>;
export type AuctionItemPropsJs = ReturnType<AuctionItemProps['toJS']>;

export type AuctionSubmissionList = AuctionItemPropsJs['list'];
export type AuctionSubmissionItem = NonNullable<AuctionSubmissionList>[number];
export type AuctionDetails = AuctionSubmissionItem['node'];

/**
 * Initial state of model
 */
export const AuctionSubmissionInitialState = ServerRecord<AuctionItemModel>({
  list: null,
  auctionItem: null,
});
