import classnames from 'classnames';
import { ReactElement, Children, cloneElement, memo, useId } from 'react';

import AssuredGlyph from 'glyphs/assured-gradient.svg';
import AutogradeGlyph from 'glyphs/autograde.svg';
import BuyerShieldGlyph from 'glyphs/buyerShield-border.svg';
import EditGlyph from 'glyphs/edit.svg';
import RefreshGlyph from 'glyphs/refresh.svg';
import ReserveGlyph from 'glyphs/check.svg';
import StarGlyph from 'glyphs/star.svg';
import VerifiedNewGlyph from 'glyphs/verified-new.svg';
import WarningGlyph from 'glyphs/warning.svg';

import BadgeText from 'components/ui/badges/badgeText';
import BadgeTooltip from 'components/sections/inventoryItem/details/badgeTooltip';
import Sprite, { Glyph } from 'components/ui/shared/sprite';
import { rangeCA, ScoreRange } from 'components/ui/shared/scores/scoreRanges';
import { t } from 'utils/intlUtils';
import { useScoreRangeValue } from 'components/ui/shared/scores/useScoreRangeValue';

import style from './inventoryItemBadges.scss';

interface BadgesProps {
  /** The component to render. */
  children?: (ReactElement<BadgeProps> | null | false)[];
  /** The className to overwrite default styles */
  className?: string;
  /** True to show tooltip for all badges */
  showTooltips?: boolean;
}

interface BadgeProps {
  /** The className to overwrite default styles */
  className?: string;
  /** True to show tooltip */
  showTooltip?: boolean;
}

interface BadgeWithGlyphOverwriteProps extends BadgeProps {
  /** Used to overwrite existing glyph (if there are multiple variants) */
  glyph?: Glyph;
  /** The className to overwrite glyph default styles */
  glyphClassName?: string;
}

interface AsIsBadgeProps extends BadgeProps {
  /** Theme for the badge */
  theme?: 'red' | 'white';
}

interface ScoreBadgeProps extends BadgeProps {
  /** The condition score */
  score: number | undefined;
}

interface EBlockScoreBadgeProps extends ScoreBadgeProps {
  /** Score range to return the proper badge color */
  range?: ScoreRange;
  /** True, show `Score` label */
  showLabel?: boolean;
}

interface TextBadgeProps extends BadgeProps {
  /** The text label displayed next to the badge. */
  text?: string;
  /** Optional tooltip description to overwrite the default description. */
  tooltipDescription?: string;
}

interface ReserveMetProps extends TextBadgeProps {
  /** The className to overwrite default styles */
  textClassName?: string;
  /** Theme for the badge, defaults to green. */
  theme?: 'green' | 'gray';
}

interface UnreservedProps extends BadgeProps {}

export const Badges = ({ children, className, showTooltips = true }: BadgesProps) => (
  <div className={classnames(style.badges, className)} data-testid="badges-container">
    {Children.map(children, (child) => {
      if (!child) {
        return null;
      }

      /**
       * Skips injecting props to `HTMLElements`. In case we add non-badge components
       * inside <Badge />
       */
      if (typeof child.type !== 'object') {
        return child;
      }

      return cloneElement(child, {
        showTooltip: child.props.showTooltip ?? showTooltips,
      });
    })}
  </div>
);

export const AsIsBadge = memo(({ className, showTooltip, theme = 'red' }: AsIsBadgeProps) => {
  const id = useId();

  return (
    <>
      <div className={classnames(style.asIs, style[theme], className)} data-testid="as-is-badge" data-tooltip-id={id}>
        {t('as_is')}
      </div>
      <BadgeTooltip
        anchorId={id}
        description={t('as_is_icon_description')}
        portalIdPrefix="as-is-tooltip"
        showTooltip={showTooltip}
        title={t('as_is')}
      />
    </>
  );
});

export const AssuredBadge = memo(({ className, glyph, glyphClassName, showTooltip }: BadgeWithGlyphOverwriteProps) => {
  const id = useId();

  return (
    <>
      <div
        className={classnames(style.badge, style.verified, className)}
        data-testid="assured-badge"
        data-tooltip-id={id}
      >
        <Sprite className={glyphClassName} glyph={glyph || AssuredGlyph} />
      </div>
      <BadgeTooltip
        anchorId={id}
        description={t('assurance_icon_description')}
        portalIdPrefix="assurance-tooltip"
        showTooltip={showTooltip}
        title={t('assurance')}
      />
    </>
  );
});

export const AutoBidBadge = memo(({ className, text, showTooltip, tooltipDescription }: TextBadgeProps) => {
  const id = useId();

  return (
    <>
      <div className={style.labelBadgeContainer} data-tooltip-id={id}>
        <div className={classnames(style.badge, style.autobid, className)} data-testid="autobid-badge">
          A
        </div>
        {text && <BadgeText className={classnames(style.labelBadge, className)} color="blue" text={text} />}
      </div>
      <BadgeTooltip
        anchorId={id}
        description={tooltipDescription ?? t('autobid_icon_description')}
        portalIdPrefix="autobid-tooltip"
        showTooltip={showTooltip}
        title={t('autobid')}
      />
    </>
  );
});

export const AutogradeScoreBadge = memo(({ className, score, showTooltip }: ScoreBadgeProps) => {
  const id = useId();

  return (
    <>
      <div
        className={classnames(style.badge, style.autogradeScore, className)}
        data-testid="autograde-score-badge"
        id={id}
      >
        <Sprite className={style.autogradeGlyph} glyph={AutogradeGlyph} />
        <div>{score !== undefined && score >= 0 ? score : '-'}</div>
      </div>
      <BadgeTooltip
        anchorId={id}
        description={t('autograde_vehicle_score_description')}
        portalIdPrefix="autograde-vehicle-score-tooltip"
        showTooltip={showTooltip}
        title={t('autograde_vehicle_score')}
      />
    </>
  );
});

export const EBlockScoreBadge = memo(
  ({ className, range = rangeCA, score, showLabel, showTooltip }: EBlockScoreBadgeProps) => {
    const id = useId();
    const { rangeItem } = useScoreRangeValue({ range, score });

    return (
      <>
        <div
          className={classnames(style.badge, style.eblockScore, { [style.noScore]: !rangeItem }, className)}
          data-testid="eblock-score-badge"
          id={id}
          style={{ backgroundColor: rangeItem?.color }}
        >
          {showLabel && `${t('score')} `}
          {score || '-'}
        </div>
        <BadgeTooltip
          anchorId={id}
          description={t('eblock_vehicle_score_description')}
          portalIdPrefix="eblock-vehicle-score-tooltip"
          showTooltip={showTooltip}
          title={t('eblock_vehicle_score')}
        />
      </>
    );
  }
);

export const BuyerShieldBadge = memo(
  ({ className, glyph, glyphClassName, showTooltip }: BadgeWithGlyphOverwriteProps) => {
    const id = useId();

    return (
      <>
        <div className={classnames(style.badge, className)} data-testid="buyer-shield-badge" data-tooltip-id={id}>
          <Sprite className={glyphClassName} glyph={glyph || BuyerShieldGlyph} />
        </div>
        <BadgeTooltip
          anchorId={id}
          description={t('buyer_shield_icon_description')}
          portalIdPrefix="buyer-shield-us-only-tooltip"
          showTooltip={showTooltip}
          title={t('buyer_shield_us_only')}
        />
      </>
    );
  }
);

export const NoteBadge = memo(({ className, glyph, glyphClassName, showTooltip }: BadgeWithGlyphOverwriteProps) => {
  const id = useId();

  return (
    <>
      <div className={classnames(style.badge, style.editable, className)} data-testid="note-badge" data-tooltip-id={id}>
        <Sprite className={classnames(style.notes, glyphClassName)} glyph={glyph || EditGlyph} />
      </div>
      <BadgeTooltip
        anchorId={id}
        description={t('note_icon_description')}
        portalIdPrefix="note-tooltip"
        showTooltip={showTooltip}
        title={t('note')}
      />
    </>
  );
});

export const HoldbackActiveBadge = memo(({ className, showTooltip }: BadgeProps) => (
  <div className={classnames(style.badge, style.holdbackActive, className)} data-testid="holdback-active-badge">
    H
  </div>
));

export const ReranBadge = memo(({ className, showTooltip, text, tooltipDescription }: TextBadgeProps) => {
  const id = useId();

  return (
    <>
      <div className={style.labelBadgeContainer} data-tooltip-id={id}>
        <div className={classnames(style.badge, style.reran, className)} data-testid="reran-badge">
          <Sprite glyph={RefreshGlyph} />
        </div>
        {text && <BadgeText className={classnames(style.labelBadge, className)} color="red" text={text} />}
      </div>
      <BadgeTooltip
        anchorId={id}
        description={tooltipDescription}
        portalIdPrefix="reran-tooltip"
        showTooltip={showTooltip}
        title={t('ran_before')}
      />
    </>
  );
});

export const ReserveMetBadge = memo(
  ({
    className,
    glyph,
    glyphClassName,
    showTooltip,
    text,
    textClassName,
    theme = 'green',
    tooltipDescription,
  }: ReserveMetProps & Pick<BadgeWithGlyphOverwriteProps, 'glyph' | 'glyphClassName'>) => {
    const id = useId();

    return (
      <>
        <div className={style.labelBadgeContainer} data-tooltip-id={id}>
          <div
            className={classnames(style.badge, style.reserveMet, style[theme], className)}
            data-testid="reserve-met-badge"
          >
            <Sprite className={classnames(style.reserveGlyph, glyphClassName)} glyph={glyph || ReserveGlyph} />
          </div>
          {text && (
            <BadgeText
              className={classnames(style.labelBadge, className)}
              color={theme}
              text={text}
              textClassName={textClassName}
            />
          )}
        </div>
        <BadgeTooltip
          anchorId={id}
          description={tooltipDescription ?? t('reserve_met_icon_description')}
          portalIdPrefix="reserve-met-tooltip"
          showTooltip={showTooltip}
          title={t('reserve_met')}
        />
      </>
    );
  }
);

export const ReserveMetTextBadge = memo(
  ({
    showTooltip = false,
    textClassName,
    ...props
  }: ReserveMetProps & Pick<BadgeWithGlyphOverwriteProps, 'glyph' | 'glyphClassName'>) => (
    <ReserveMetBadge
      showTooltip={showTooltip}
      text={t('reserve_met')}
      textClassName={classnames(style.reserveMetText, textClassName)}
      {...props}
    />
  )
);

export const UnreservedBadge = memo(({ className, showTooltip }: UnreservedProps) => {
  const id = useId();

  return (
    <>
      <div
        className={classnames(style.badge, style.unreserved, className)}
        data-testid="unreserved-badge"
        data-tooltip-id={id}
      >
        {t('unreserved')}
      </div>
      <BadgeTooltip
        anchorId={id}
        description={t('unreserved_icon_description')}
        portalIdPrefix="unreserved-tooltip"
        showTooltip={showTooltip}
        title={t('unreserved')}
      />
    </>
  );
});

export const VerifiedBadge = memo(({ className, glyph, glyphClassName, showTooltip }: BadgeWithGlyphOverwriteProps) => {
  const id = useId();

  return (
    <>
      <div
        className={classnames(style.badge, style.verified, className)}
        data-testid="verified-badge"
        data-tooltip-id={id}
      >
        <Sprite className={glyphClassName} glyph={glyph || VerifiedNewGlyph} />
      </div>
      <BadgeTooltip
        anchorId={id}
        description={t('verified_icon_description')}
        portalIdPrefix="verified-tooltip"
        showTooltip={showTooltip}
        title={t('verified')}
      />
    </>
  );
});

export const WarningBadge = memo(({ className, glyph, glyphClassName, showTooltip }: BadgeWithGlyphOverwriteProps) => {
  const id = useId();

  return (
    <>
      <div
        className={classnames(style.badge, style.warning, className)}
        data-testid="warning-badge"
        data-tooltip-id={id}
      >
        <Sprite className={glyphClassName} glyph={glyph || WarningGlyph} />
      </div>
      <BadgeTooltip
        anchorId={id}
        description={t('declaration_icon_description')}
        portalIdPrefix="declaration-tooltip"
        showTooltip={showTooltip}
        title={t('declaration')}
      />
    </>
  );
});

export const WatchedBadge = memo(
  ({
    className,
    glyph,
    showTooltip,
    text,
    tooltipDescription,
  }: TextBadgeProps & Pick<BadgeWithGlyphOverwriteProps, 'glyph'>) => {
    const id = useId();

    return (
      <>
        <div className={style.labelBadgeContainer} data-tooltip-id={id}>
          <div className={classnames(style.badge, style.watched, className)} data-testid="watched-badge">
            <Sprite glyph={glyph || StarGlyph} />
          </div>
          {text && <BadgeText className={classnames(style.labelBadge, className)} color="yellow" text={text} />}
        </div>
        <BadgeTooltip
          anchorId={id}
          description={tooltipDescription ?? t('watchlist_icon_description')}
          portalIdPrefix="watchlist-tooltip"
          showTooltip={showTooltip}
          title={t('watchlist')}
        />
      </>
    );
  }
);
