import { ComponentProps, FC, PropsWithChildren, ReactElement } from 'react';

type Component = FC<PropsWithChildren>;

/**
 * Compose components together
 * @example:
 * ```
 *   const ComposedComponent = composeComponents([C1, C2, C3]);
 *   render(<ComposedComponent><div>Example</div></ComposedComponent>);
 *  // Will render:
 *   <C1>
 *     <C2>
 *       <C3>
 *         <div>Example</div>
 *       </C3>
 *     </C2>
 *   </C1>
 * ```
 *
 * @param components
 */
export const composeComponents = (components: Component[]): Component => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }: ComponentProps<Component>): ReactElement => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};
