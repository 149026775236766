import { handleActions } from 'redux-actions';

import { getField } from 'utils/objectUtils';
import { InitialState, Carrier } from './transportJobDetailsModels';
import { TransportRequest, TransportJob } from '../list/transportJobsModels';
import {
  isLoaded,
  isUpdating,
  isNotUpdating,
  preloadTransportJobItem,
  updateTransportJobItem,
  updateCarriers,
  setSavingState,
  setSelectedQuoteId,
  clearTransportRequestsList,
  updateTransportRequestsList,
  setTransportJobData,
} from './transportJobDetailsActions';

export const transportJobDetailsReducer = handleActions(
  {
    [isUpdating().type]: (state) => state.setUpdating(),

    [isNotUpdating().type]: (state) => state.unsetUpdating(),

    [isLoaded().type]: (state, action) => {
      const { transportRequest, transportJob } = action.payload;
      const isTransportJob = getField(transportJob, '__typename') === 'TransportJob';
      const transportEntity = isTransportJob ? new TransportJob(transportJob) : new TransportRequest(transportRequest);

      // Split up TransportJobs and TransportEntities
      return state.setLoaded().set('details', transportEntity);
    },

    [preloadTransportJobItem().type]: (state, action) => {
      if (action.payload && action.payload.id) {
        const isTransportJob = action.payload.__typename === 'TransportJob';
        const transportEntity = isTransportJob
          ? new TransportJob(action.payload)
          : new TransportRequest(action.payload);

        return state.setLoaded().set('details', transportEntity);
      }
      return state;
    },

    [updateCarriers().type]: (state, action) => {
      return state.set(
        'carriers',
        action.payload.map((item) => new Carrier(item.node))
      );
    },

    [updateTransportJobItem().type]: (state, action) => {
      const details = state.details?.toJS();
      const detailsNext = { ...details, ...action.payload };
      const isTransportJob = action.payload.__typename === 'TransportJob';
      const transportEntity = isTransportJob ? new TransportJob(detailsNext) : new TransportRequest(detailsNext);

      return state.setLoaded().set('details', transportEntity);
    },

    [setSavingState().type]: (state, action) => state.setLoaded().set('isSaving', action.payload),

    [setSelectedQuoteId().type]: (state, action) => state.setLoaded().set('selectedQuoteId', action.payload),

    [clearTransportRequestsList().type]: (state) => state.set('transportRequests', []),

    [updateTransportRequestsList().type]: (state, action) => {
      const transportRequests = action.payload.edges.filter((edge) => edge.node.__typename === 'TransportRequest');
      return state.set(
        'transportRequests',
        transportRequests.map((request) => new TransportRequest(request.node))
      );
    },

    [setTransportJobData().type]: (state, action) => {
      const { data, path } = action.payload;
      return state.setIn(['details'].concat(path), data);
    },
  },
  new InitialState()
);
