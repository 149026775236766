import classnames from 'classnames';

import Button, { ButtonTheme } from 'components/ui/shared/button';
import { SaleLight } from 'store/shared/api/graph/interfaces/types';
import { t } from 'utils/intlUtils';

import style from './saleLightFormButton.scss';

type Props = {
  /** True when the button is active. */
  isActive: boolean;
  /** True when the button is disabled. */
  isDisabled?: boolean;
  /** Function invoked on button click. */
  onClick: (saleLight: SaleLight) => void;
  /** The sale light the button represents. */
  saleLight: SaleLight;
};

const SaleLightFormButton = ({ isActive, isDisabled, onClick, saleLight }: Props) => {
  return (
    <Button
      className={classnames(style.button, { [style.deselected]: !isActive })}
      dataTestId={`sale-light-${saleLight.toLowerCase()}`}
      disabled={isDisabled}
      onClick={() => onClick(saleLight)}
      theme={isActive ? (saleLight.toLowerCase() as ButtonTheme) : 'gray-outline'}
    >
      {t(`color_${saleLight.toLowerCase()}`)}
    </Button>
  );
};

export default SaleLightFormButton;
