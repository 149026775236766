import { MouseEvent } from 'react';

import BaseClass from 'components/ui/shared/base';
import Button from 'components/ui/shared/button';
import ConfirmDialog from 'components/ui/shared/dialogs/confirmDialog';
import InputText from 'forms/shared/inputText';
import { t } from 'utils/intlUtils';

import style from './dialog.scss';

interface Props {
  /** True when the dialog is open. */
  isOpen: boolean;
  /** Function invoked when copy button is clicked. */
  onCopyButtonClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  /** Function invoked when dialog is closed. */
  onSubmit: () => void;
  /** The url to share. */
  shareUrl: string | null;
  /** The displayed dialog title. */
  title?: string;
}

class Dialog extends BaseClass<Props> {
  private inputText: HTMLInputElement;

  copyToClipboard = (event) => {
    this.props.onCopyButtonClick?.(event);
    this.inputText.select();
    document.execCommand('copy');
  };

  render() {
    const { isOpen, onSubmit, title, shareUrl } = this.props;

    return (
      <ConfirmDialog className={style.dialog} isOpen={isOpen} onClose={onSubmit} theme="red" title={t('share_vehicle')}>
        <div className={style.title}>{`${t('share')} ${title}`}</div>
        <div className={style.inputContainer}>
          <InputText
            className={style.input}
            defaultValue={shareUrl || undefined}
            onFocus={(e) => e?.target.select()}
            readOnly
            reference={(input: HTMLInputElement) => {
              this.inputText = input;
            }}
          />
          <Button className={style.button} dataTestId="copyButton" onClick={this.copyToClipboard} theme="blue">
            {t('copy')}
          </Button>
        </div>
      </ConfirmDialog>
    );
  }
}

export default Dialog;
