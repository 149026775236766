import ApiRequest from 'store/shared/api/apiRequest';
import { photoFragment } from 'store/shared/api/graph/fragments/common';

export const arbitration = `
  id
  status
  format
  arbitrationRequest {
    status
    created
    updated
    content
    onTime
    cancelled
    responsibleParty
    responsibleComment
    compensationToBuyer {
      formattedAmount
      amount
    }
    compensationPaidBySeller {
      formattedAmount
      amount
    }
    compensationPaidByCarrier {
      formattedAmount
      amount
    }
    compensationPaidByEBlockType
    compensationPaidByEBlock {
      formattedAmount
      amount
    }
    compensationComment
    company {
      id
      name
    }
    compensationToBuyer {
      formattedAmount
    }
    tags(selected: true) {
      id
      name
      selected
    }
  }
  inventoryItem {
    ... on Vehicle {
      id
      created
      year
      make
      model
      trim
      vin
      stockNumber
      mileage {
        formattedAmount
      }
      numberOfDoors
      numberOfPassengers
      bodyType
      exteriorColor
      interiorColor
      transmission
      displacement
      cylinders
      driveTrain
      fuelType
      company {
        id
        name
      }
      photos(type: EXTERIOR) {
        ...photoFrag
      }
      interiorPhotos: photos(type: INTERIOR) {
        ...photoFrag
      }
      damagePhotos: photos(type: DAMAGE) {
        ...photoFrag
        location
      }
    }
  }
  transactionDetails {
    buyerCompany {
      id
      name
    }
  }
`;

export const getArbitrations = ({ auctionItemId = null }) =>
  ApiRequest({
    data: {
      query: `
      query ($auctionItemId: String!) {
        auctionItem(auctionItemId: $auctionItemId) {
          ${arbitration}
        }
      }
      ${photoFragment}
    `,
      variables: {
        auctionItemId,
      },
    },
  });

export const saveArbitration = ({
  auctionItemId,
  status,
  tags,
  onTime,
  cancelled,
  responsibleParty,
  responsibleComment,
  compensationToBuyer,
  compensationPaidBySeller,
  compensationPaidByCarrier,
  compensationPaidByEBlockType,
  compensationPaidByEBlock,
  compensationComment,
}) =>
  ApiRequest({
    data: {
      query: `
      mutation(
        $auctionItemId: String!,
        $status: AuctionItemArbitrationStatus,
        $onTime: Boolean,
        $cancelled: Boolean,
        $tags: [String],
        $responsibleParty: ArbitrationResponsibleParty,
        $responsibleComment: String,
        $compensationToBuyer: Float,
        $compensationPaidBySeller: Float,
        $compensationPaidByCarrier: Float,
        $compensationPaidByEBlockType: ArbitrationCompensationType,
        $compensationPaidByEBlock: Float,
        $compensationComment: String
      ) {
        auctionItemArbitrationUpdate (
          auctionItemId: $auctionItemId,
          status: $status,
          onTime: $onTime,
          cancelled: $cancelled,
          tags: $tags,
          responsibleParty: $responsibleParty,
          responsibleComment: $responsibleComment,
          compensationToBuyer: $compensationToBuyer,
          compensationPaidBySeller: $compensationPaidBySeller,
          compensationPaidByCarrier: $compensationPaidByCarrier,
          compensationPaidByEBlockType: $compensationPaidByEBlockType,
          compensationPaidByEBlock: $compensationPaidByEBlock,
          compensationComment: $compensationComment,
        ) {
          ${arbitration}
        }
      }
      ${photoFragment}
    `,
      variables: {
        auctionItemId,
        status,
        tags,
        onTime,
        cancelled,
        responsibleParty,
        responsibleComment,
        compensationToBuyer,
        compensationPaidBySeller,
        compensationPaidByCarrier,
        compensationPaidByEBlockType,
        compensationPaidByEBlock,
        compensationComment,
      },
    },
  });

export const getArbitrationMetaData = () =>
  ApiRequest({
    data: {
      query: `
    {
      arbitrationMetaData {
        arbitrationTags {
          id
          name
        }
      }
    }
    `,
    },
  });
